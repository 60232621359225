export const showLoadingScreen = () => {
	const loadingElement = document.getElementById("loading-page")
	loadingElement.style.display = "flex"
}

export const hideLoadingScreen = () => {
	const loadingElement = document.getElementById("loading-page")
	loadingElement.style.display = "none"
}

export const stopLoadingAndDisplayError = (error) => {
	const loadingElement = document.getElementById("loading-page")
	const loadingElementText = document.getElementById("loading-page-text")
	loadingElementText.innerHTML = error
	loadingElement.classList.add("failed-loading")
}
