<template>
  <div class="screen" id="account-required">
    <Panel class="account-required-panel" :title="t('actRequiredSignUp')">
      <template #panel-content>
        <p>{{message}}</p>
		<router-link v-bind:to="'/login-signup' + getLocationSearch"><Button :color="'green'" :onclickfn="()=>{ }" :text="t('playSignUpButtonLabel')"></Button></router-link>
      </template>
    </Panel>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Panel from "../reusable-components/Panel"
import Button from "../reusable-components/Button"
import { parse } from "query-string"

// todo i18n
const accountRequiredFeatureMessageMap = {
	profile:
		"actRequiredProfile",
	friends: "actRequiredFriends",
	battlepass: "actRequiredBattlepass",
    vidad: "actRequiredVideoReward"
}

export default {
	name: "AccountRequired",
	components: {
		Panel,
		Button
	},
	computed: {
		...mapGetters("i18n", ["t"]),
		message() {
			const queryParams = parse(window.location.search)
			let { feature } = queryParams

			if (
				feature === undefined ||
				accountRequiredFeatureMessageMap[feature] === undefined
			) {
				feature = "profile"
			}

			return this.t(accountRequiredFeatureMessageMap[feature])
		},
        getLocationSearch() {
            return window.location.search
        }
	}
}
</script>


<style lang="scss" scoped>
#account-required {
	.account-required-panel {
		max-width: 400px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		button {
			margin-top: 20px;
		}
	}
}
</style>

