<template>
  <div class="screen" id="change-password">
    <Panel class="change-password-panel" v-bind:class="{loading: userDataFetching}" :oncloseroute="'/profile'">
      <template #panel-content>
        <LoadyBro v-if="userDataFetching"></LoadyBro>
        <div v-else class="forms">
          <EmbeddedDarkBox class="lighter change-password-form">
            <template #dark-box-content>
              <div class="form-title">Change Password</div>
              <p class="error" v-if="changePassword.error">{{changePassword.error}}</p>
              <div class="input-group">
                <div class="input-label">
                  <label for="change-password-current-password">Current Password</label>
                </div>
                <div class="input">
                  <input
                    v-on:keyup.enter="() => { changePasswordFormSubmit() }"
                    v-model="changePassword.currentPassword"
                    id="change-password-current-password"
                    type="password"
                  />
                </div>
              </div>
              <div class="input-group">
                <div class="input-label">
                  <label for="change-password-password">New Password</label>
                </div>
                <div class="input">
                  <input
                    v-on:keyup.enter="() => { changePasswordFormSubmit() }"
                    v-model="changePassword.newPassword"
                    id="change-password-password"
                    type="password"
                  />
                </div>
              </div>
              <div class="input-group">
                <div class="input-label">
                  <label for="change-password-password-confirmation">Confirm New Password</label>
                </div>
                <div class="input">
                  <input
                    v-on:keyup.enter="() => { changePasswordFormSubmit() }"
                    v-model="changePassword.newPasswordConfirmation"
                    id="change-password-password-confirmation"
                    type="password"
                  />
                </div>
              </div>
              <div class="form-submit-btn">
                <Button :color="'green'" :onclickfn="() => { changePasswordFormSubmit() }" :text="'Change'"></Button>
              </div>
            </template>
          </EmbeddedDarkBox>
        </div>
      </template>
    </Panel>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { some, values } from "lodash"
import Panel from "../reusable-components/Panel"
import EmbeddedDarkBox from "../reusable-components/EmbeddedDarkBox"
import Checkbox from "../reusable-components/Checkbox"
import Button from "../reusable-components/Button"
import LoadyBro from "../reusable-components/LoadyBro"

export default {
	name: "ChangePassword",
	components: {
		Panel,
		EmbeddedDarkBox,
		Checkbox,
		Button,
		LoadyBro
	},
	data() {
		return {
			changePassword: {
				error: false,
				currentPassword: "",
				newPassword: "",
				newPasswordConfirmation: ""
			}
		}
	},
	computed: {
		...mapGetters("user", ["userDataFetching"])
	},
	methods: {
		...mapActions("user", ["submitChangePassword"]),
		changePasswordFormSubmit() {
			this.changePassword.error = false

			let normalizedFormValues = {
				currentPassword: this.changePassword.currentPassword,
				newPassword: this.changePassword.newPassword,
				newPasswordConfirmation: this.changePassword.newPasswordConfirmation
			}

			if (
				some(values(normalizedFormValues), v => {
					return v === undefined || v === ""
				})
			) {
				return (this.changePassword.error = "You need to fill out the entire form.")
			}

			if (normalizedFormValues.newPassword !== normalizedFormValues.newPasswordConfirmation) {
				return (this.changePassword.error = "Your new passwords dont match.")
			}

			if (normalizedFormValues.newPassword === normalizedFormValues.currentPassword || normalizedFormValues.newPasswordConfirmation === normalizedFormValues.currentPassword) {
				return (this.changePassword.error = "Your new password can't be the same as your old one.")
			}

			this.submitChangePassword(normalizedFormValues)
		}
	}
}
</script>


<style lang="scss">
#change-password {
	.change-password-panel {
		position: relative;

		&.loading {
			width: 840px;
			height: 559px;
		}

		.loady-bro {
			position: absolute;
			top: 65%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		.forms {
			display: flex;
		}

		.change-password-form {
			position: relative;
			display: flex;
			flex-direction: column;
			padding: 30px 30px 50px 30px;
			min-width: 380px;

			p.error {
				text-align: center;
				color: red;
			}

			.form-title {
				text-align: center;
				font-size: 32px;
				text-transform: uppercase;
				margin-bottom: 10px;
			}

			.input-group {
				display: flex;
				height: 35px;
				margin-bottom: 10px;

				.input-label {
					align-self: center;
					width: 105px;
					text-align: left;

					label {
						color: #d9d9d9;
					}
				}
				.input {
					margin-left: auto;
					display: flex;

					input {
						align-self: center;
						background-color: #262628;
						border: none;
						height: 20px;
						font-size: 14px;
						color: #fff;
						padding: 0 5px;
					}
				}
			}

			.form-submit-btn {
				position: absolute;
				bottom: -20px;
				left: 50%;
				transform: translateX(-50%);

				button {
					height: auto;
					.button-inner {
						font-size: 24px;
					}
				}
			}
		}
	}
}
</style>

