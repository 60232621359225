<template>
  <div class="panel" v-bind:class="{ 'has-title': title }">
    <div class="panel-inner-for-border"></div>
    <div class="panel-close" v-on:click="() => { if (onclosefn) { onclosefn() } }">
      <router-link :to="oncloseroute">
        <SkinImage
          class="close-icon"
          :skin-data="{ skinCode: 'close_icon' }"
          :skin-image-type="'sprite'"
        ></SkinImage>
      </router-link>
    </div>
    <div v-if="title" class="panel-title">
      <div class="panel-title-inner">{{title}}</div>
    </div>
    <slot name="panel-content"></slot>
  </div>
</template>

<script>
import SkinImage from "./SkinImage"
export default {
	name: "Panel",
	props: {
		title: String,
		onclosefn: Function,
		oncloseroute: {
			type: String,
			default: "/play",
		}
	},
	components: {
		SkinImage
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/colors.scss";

.panel {
	color: white;
	display: flex;
	background-image: url("../assets/tile.png");
	background-repeat: repeat;
	border-radius: 5px;
	border: 10px solid rgba(0, 0, 0, 0);
	border-image: url("../assets/bruh-panel.png");
	border-image-slice: 18%;
	border-image-width: 12px;
	padding: 20px;
	position: relative;
	max-height: 95%;
	max-width: 95%;

	&.has-title {
		padding: 24px 20px 20px 20px;
	}

	.panel-title {
		background-color: $squad-border-color;
		border-radius: 10px;
		position: absolute;
		top: -32px;
		left: 50%;
		transform: translateX(-50%);
		text-align: center;
		border-bottom: 3px solid #000;
		white-space: nowrap;

		.panel-title-inner {
			padding: 5px 30px;
			border-radius: 10px;
			font-size: 24px;
			width: 100%;
			height: 100%;
			border: 3px solid $squad-border-color-bright;
		}
	}

	.panel-close {
		position: absolute;
		cursor: pointer;
		top: -30px;
		right: -30px;

		.close-icon {
			opacity: 1;
			transform: scale(0.8);

			&:hover {
				opacity: 0.8;
			}
		}
	}
}
</style>
