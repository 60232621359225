import logger from "../utils/logger"
import { find } from "lodash"
import { checkIfPartyExistsRequest, findPartyRequest } from "../api/requests"
import { stringify } from "query-string"
import { handleError } from "../utils/error"
import { saveToLocalStorage, removeFromLocalStorage } from "../utils/local-storage"

const send = (socket, payload) => {
	try {
		if (socket) {
			socket.send(JSON.stringify(payload))
		}
	} catch (e) {
		console.error("Error occurred with party socket send", payload, e)
		handleError(e)
	}
}

export const partyModule = () => {
	// logger.debug("Initializing Party store module with initial state...")
	return {
		namespaced: true,
		state: {
			allowRandomsToJoin: false,
			socket: undefined,
			members: {}
		},
		getters: {
			partyId: state => state.partyId,
			thisPlayerIsLeader: (state, _, rootState) => {
				const userId = rootState.user.id

				if (Object.keys(state.members).length === 0) {
					return false
				}

				const leaderMemberId = find(state.members, (member) => {
					return member.isLeader
				}).userId

				return leaderMemberId === userId
			},
			thisPlayerIsReady: (state, _, rootState) => {
				const userId = rootState.user.id

				if (Object.keys(state.members).length === 0) {
					return false
				}

				return state.members[userId].isReady
			},
			memberCount: (state) => {
				return Object.keys(state.members).length
			},
			memberDetails: (state) => {
				return state.members
			},
			partyAllowsRandoms: state => {
				return state.allowRandomsToJoin
			}
		},
		mutations: {
			youreAlreadyInAParty() {
				removeFromLocalStorage("latestPartyId")
				handleError(false, "youreAlreadyInAParty")
				this.router.push(`/play`)
			},
			thatPartyIsFull() {
				removeFromLocalStorage("latestPartyId")
				handleError(false, "thatPartyIsFull")
				this.router.push(`/play`)
			},
			socketCreated: (state, socket) => {
				state.socket = socket
			},
			inParty(state, event) {
				const partyId = event.payload
				state.partyId = partyId
				saveToLocalStorage("latestPartyId", partyId)
				this.router.push(`/play/party/${partyId}`)
			},
			latestPartyDetails: (state, event) => {
				state.allowRandomsToJoin = event.payload.allowRandomsToJoin
				state.members = event.payload.members
			},
			resetParty(state) {
				if (state.socket) {
					state.socket.close()
				}
				state.partyId = undefined
				state.socket = undefined
				state.members = {}
			}
		},
		actions: {
			async checkIfPartyExists({ dispatch }, partyId) {
				try {
					const checkResults = await checkIfPartyExistsRequest(partyId)
					if (checkResults.partyExists) {
						dispatch("connectToPartyService", partyId)
					} else {
						removeFromLocalStorage("latestPartyId")
						handleError(false, "partyNoLongerExists")
						this.router.push("/play")
					}
				} catch (e) {
					console.error("Error occurred when attempting to check if party exists", e)
					handleError(e)
				}
			},
			async connectToPartyService({ commit, state, rootState }, optionalIdOfPartyToJoin) {
				try {
					let onConnectPayload

					if (optionalIdOfPartyToJoin) {
						window.googleanalytics.send('event', 'party', 'join-party')
						onConnectPayload = {
							action: 'joinParty',
							partyId: optionalIdOfPartyToJoin,
							userId: rootState.user.id,
							username: rootState.user.username,
							weaponSkin: rootState.user.preferences.selectedWeaponSkin,
							characterSkin: rootState.user.preferences.selectedCharacterSkin
						}
					} else {
						window.googleanalytics.send('event', 'party', 'create-party')
						onConnectPayload = {
							action: 'createParty',
							userId: rootState.user.id,
							username: rootState.user.username,
							weaponSkin: rootState.user.preferences.selectedWeaponSkin,
							characterSkin: rootState.user.preferences.selectedCharacterSkin
						}
					}

					const socket = new WebSocket(process.env.DIONYSUS_WS_URL)

					commit("socketCreated", socket)

					socket.addEventListener('open', () => {
						send(socket, onConnectPayload)
					})

					socket.addEventListener('close', (e) => {
						console.error("socket closed", state, e)
						commit('resetParty')
						removeFromLocalStorage("latestPartyId")
						this.router.push("/play")
					})

					socket.addEventListener('error', (e) => {
						console.error("socket error", e)
						commit('resetParty')
						this.router.push("/play")
					})

					socket.addEventListener('ping', () => {
						socket.pong()
					})

					socket.addEventListener('message', (e) => {
						const message = JSON.parse(e.data)
						if (message.action === "queue") {
							const { partyId, gameInstanceAddress } = message.payload
							commit("user/userDataFetching")
							const queryParamPayload = {
								identifier_type: rootState.user.userType,
								identifier:
									rootState.user.userType === "registered"
										? rootState.user.authentication.token
										: rootState.user.id,
								game_instance_address: gameInstanceAddress,
								party_id: partyId
							}
							const queryParams = stringify(queryParamPayload, { encode: true })
							const redirectUrl = process.env.GAME_CLIENT_URL + "?" + queryParams
							window.location.replace(redirectUrl)
						} else {
							commit(message.action, message)
						}
					})
				} catch (e) {
					console.error("Error occurred when attempting to join party", e)
					handleError(e)
				}
			},
			async findMeAParty() {
				try {
					window.googleanalytics.send('event', 'party', 'find-and-join-party')
					const findResults = await findPartyRequest()
					if (findResults.foundParty) {
						window.location.replace(`${process.env.GAME_WEBSITE_URL}/play/party/${findResults.partyId}`)
					} else {
						handleError(false, "noPartiesAvailableToJoin")
					}
				} catch (e) {
					console.error("Error occurred when attempting to find a party", e)
					handleError(e)
				}
			},
			squadQueue({ state }) {
				window.googleanalytics.send('event', 'join-game-queue', 'squad', state.members.length)
				send(state.socket, { action: "squadQueue", partyId: state.partyId })
			},
			groupQueue({ state }) {
				send(state.socket, { action: "queue", partyId: state.partyId })
			},
			updateWeaponSkin({ state }, newWeaponSkin) {
				send(state.socket, { action: "newWeaponSkin", newWeaponSkin, partyId: state.partyId })
			},
			updateCharacterSkin({ state }, newCharacterSkin) {
				send(state.socket, { action: "newCharacterSkin", newCharacterSkin, partyId: state.partyId })
			},
			toggleReadiness({ state }) {
				send(state.socket, { action: "toggleReadiness", partyId: state.partyId })
			},
			toggleAllowRandomsToJoin({ state }) {
				window.googleanalytics.send('event', 'party', 'toggle-allow-randoms', state.allowRandomsToJoin)
				send(state.socket, { action: "toggleAllowRandomsToJoin", partyId: state.partyId })
			},
			leaveParty({ commit }) {
				try {
					window.googleanalytics.send('event', 'party', 'leave-party')
					removeFromLocalStorage("latestPartyId")
					commit('resetParty')
					this.router.push("/play")
				} catch (e) {
					console.error("Error occurred when attempting to leave party", e)
					removeFromLocalStorage("latestPartyId")
					commit('resetParty')
					this.router.push("/play")
					handleError(e)
				}
			}
		}
	}
}
