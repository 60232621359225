<template>
  <div class="screen" id="play-landing">
    <div class="select-game-mode">
      <div class="card-button">
        <div
          ref="solo"
          class="card-content solo"
          v-on:click="
            () => {
              soloQueue()
            }
          "
        >
          <div class="solo-inset">
            <div class="solo-transparency">
              <div class="sprite">
                <img src="../assets/solo_char.png" alt="Play Solo" />
              </div>
              <div class="title">{{ t("playSoloButtonLabel") }}</div>
            </div>
          </div>
        </div>
        <div class="shadow solo-shadow"></div>
      </div>

      <router-link to="/play/join-or-create">
        <div class="card-button">
          <div ref="squad" class="card-content squad">
            <div class="squad-inset">
              <div class="squad-transparency">
                <div class="sprite">
                  <img src="../assets/squad_char.png" alt="Play Squad" />
                </div>
                <div class="title">{{ t("playSquadButtonLabel") }}</div>
              </div>
            </div>
          </div>
          <div class="shadow squad-shadow"></div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import soundfile from "../assets/button-hover.webm"

let activeSound = null

export default {
  name: "PlayLanding",
  computed: {
    ...mapGetters("user", ["userDataFetching"]),
    ...mapGetters("i18n", ["t"])
  },
  components: {},
  methods: {
    ...mapActions("user", ["soloQueue"])
  }
}
</script>

<style lang="scss" scoped>
#play-landing {
  @import "../assets/colors.scss";
  @import "../assets/buttons.scss";

  .select-game-mode {
    display: flex;
    justify-content: center;
  }
}
</style>
