<template>
  <div class="screen" id="play-join-or-create">
    <Button :color="'green'" :onclickfn="()=>{ connectToPartyService() }" :text="t('playCreateTeamButtonLabel')"></Button>
	<div class="middle-text">{{ t('playJoinOrCreateOrLabel') }}</div>
    <Button :color="'green'" :onclickfn="()=>{ findMeAParty() }" :text="t('playFindTeamButtonLabel')"></Button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { parse } from "query-string"
import Button from "../reusable-components/Button"

export default {
	name: "PlayJoinOrCreate",
	components: {
		Button
	},
	computed: {
		...mapGetters("i18n", ["t"])
	},
	methods: {
		...mapActions("party", ["connectToPartyService", "findMeAParty"])
	}
}
</script>


<style lang="scss" scoped>
#play-join-or-create {
	height: 100%;
	flex: 1;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: center;
	padding-bottom: 10px;
	.middle-text {
		margin: 8px;
	}
}
</style>

