const storagePrefix = "bruh_v5:"

export const saveToLocalStorage = (k, v) => {
	return localStorage.setItem(storagePrefix + k, v)
}
export const loadFromLocalStorage = k => {
	const valueFoundInLocalStorage = localStorage.getItem(storagePrefix + k)
	return valueFoundInLocalStorage === null
		? undefined
		: valueFoundInLocalStorage
}
export const removeFromLocalStorage = k => {
	return localStorage.removeItem(storagePrefix + k)
}
