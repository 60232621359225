import { get, post, put } from "axios"

export const createAnonymousUserRequest = async username => {
	const apiResults = await post(`${process.env.ATLAS_URL}/bruh/anonymous-user`, {
		username,
		sourceGame: "bruh.io"
	})
	return apiResults.data
}

export const getWorldPopulationRequest = async () => {
	const apiResults = await get(`${process.env.PROMETHEUS_API_URL}/bruh/population`)
	return apiResults.data
}

export const checkIfPartyExistsRequest = async (partyId) => {
	const apiResults = await get(`${process.env.DIONYSUS_API_URL}/bruh/check-party-activity?partyId=${partyId}`)
	return apiResults.data
}

export const getAvailableSkinsRequest = async () => {
	const apiResults = await get(`${process.env.ATLAS_URL}/bruh/available-skins`)
	return apiResults.data
}

export const getXRPValueFromBruhCoinAmountRequest = async (coinAmount) => {
	const apiResults = await get(`${process.env.FORTE_BRUH_PLAYER_EXCHANGE_URL_BASE}estimate/base/${process.env.FORTE_BRUH_GAME_ID}/${coinAmount}`)
	return apiResults.data.xrp
}

export const getExchangeUrl = async authToken => {
	const apiResults = await get(
		`${process.env.ATLAS_URL}/internal/forte-player-exchange/get-account-link-address`,
		{
			headers: { 'auth-token': authToken }
		}
	)
	return apiResults.data
}

export const loginRequest = async loginBody => {
	const apiResults = await post(`${process.env.ATLAS_URL}/bruh/login`, loginBody)
	return apiResults.data
}

export const findMatchRequest = async (mmRegion, mode) => {
	const apiResults = await get(`${process.env.PROMETHEUS_API_URL}/bruh/findmatch?mode=${mode}&preferredMatchmakingRegion=${mmRegion}`)
	return apiResults.data
}

export const heartbeatRequest = async authToken => {
	const apiResults = await put(
		`${process.env.ATLAS_URL}/shared/heartbeat`,
		{},
		{
			headers: { 'auth-token': authToken }
		}
	)
	return apiResults.data
}

export const checkDailyLoginBonusRequest = async authToken => {
	const apiResults = await get(`${process.env.ATLAS_URL}/bruh/check-daily-login-bonus`, {
		headers: {
			'auth-token': authToken
		}
	})
	return apiResults.data
}

export const addVideoAdWatched = async authToken => {
	const apiResults = await post(`${process.env.ATLAS_URL}/bruh/ad-watched`, {}, {
		headers: {
			'auth-token': authToken
		}
	})
	return apiResults.data
}

export const checkAnonymousDailyLoginBonusRequest = async anonymousUserId => {
	const apiResults = await get(`${process.env.ATLAS_URL}/bruh/check-anonymous-daily-login-bonus`, {
		headers: {
			'anonymous-user-id': anonymousUserId
		}
	})
	return apiResults.data
}

export const findPartyRequest = async () => {
	const apiResults = await get(`${process.env.DIONYSUS_API_URL}/bruh/find-party`)
	return apiResults.data
}

export const registerRequest = async registerBody => {
	const apiResults = await post(`${process.env.ATLAS_URL}/bruh/register`, registerBody)
	return apiResults.data
}

export const buySkinWithCoins = async (authToken, skinCode) => {
	const apiResults = await post(
		`${process.env.ATLAS_URL}/bruh/purchase-coin-mtx`,
		{ skinIdentifier: skinCode },
		{ headers: { 'auth-token': authToken } }
	)
	return apiResults.data
}

export const updatePreferencesRequest = async (headerBlob, newPreferences) => {
	const apiResults = await put(`${process.env.ATLAS_URL}/bruh/user-preferences`, newPreferences, {
		headers: headerBlob
	})
	return apiResults.data
}

export const updateUsernameRequest = async (authToken, newUsernameBody) => {
	const apiResults = await put(`${process.env.ATLAS_URL}/shared/change-username`, newUsernameBody, {
		headers: {
			'auth-token': authToken
		}
	})
	return apiResults.data
}

export const getFriendsListRequest = async (authToken) => {
	const apiResults = await get(`${process.env.ATLAS_URL}/shared/friends-list`, {
		headers: {
			'auth-token': authToken
		}
	})
	return apiResults.data
}

export const getGlobalRankingsRequest = async () => {
	const apiResults = await get(`${process.env.ATLAS_URL}/bruh/global-leaderboard`)
	return apiResults.data
}

export const getSeasonalRankingsRequest = async () => {
	const apiResults = await get(`${process.env.ATLAS_URL}/bruh/seasonal-leaderboard`)
	return apiResults.data
}

export const getWeeklyRankingsRequest = async () => {
	const apiResults = await get(`${process.env.ATLAS_URL}/bruh/weekly-leaderboard`)
	return apiResults.data
}


export const searchUsersByUsernameRequest = async (authToken, username) => {
	const apiResults = await post(`${process.env.ATLAS_URL}/shared/search-users-by-username`, { username }, {
		headers: {
			'auth-token': authToken
		}
	})
	return apiResults.data
}

export const addFriendRequest = async (authToken, receiverId) => {
	const apiResults = await post(`${process.env.ATLAS_URL}/shared/add-friend`, { receiverId }, {
		headers: {
			'auth-token': authToken
		}
	})
	return apiResults.data
}

export const acceptFriendRequest = async (authToken, requesterId) => {
	const apiResults = await post(`${process.env.ATLAS_URL}/shared/accept-friend`, { requesterId }, {
		headers: {
			'auth-token': authToken
		}
	})
	return apiResults.data
}
export const cancelOutgoingFriendRequest = async (authToken, receiverId) => {
	const apiResults = await post(`${process.env.ATLAS_URL}/shared/cancel-outgoing-friend-request`, { receiverId }, {
		headers: {
			'auth-token': authToken
		}
	})
	return apiResults.data
}
export const rejectIncomingFriendRequest = async (authToken, requesterId) => {
	const apiResults = await post(`${process.env.ATLAS_URL}/shared/reject-incoming-friend-request`, { requesterId }, {
		headers: {
			'auth-token': authToken
		}
	})
	return apiResults.data
}

export const verifyEmailRequest = async (verificationBody) => {
	const apiResults = await post(`${process.env.ATLAS_URL}/shared/verify-email`, verificationBody)
	return apiResults.data
}

export const unsubscribeEmailRequest = async (unsubscribeBody) => {
	const apiResults = await post(`${process.env.ATLAS_URL}/shared/unsubscribe`, unsubscribeBody)
	return apiResults.data
}


export const resetPasswordRequest = async (resetPasswordBody) => {
	const apiResults = await post(`${process.env.ATLAS_URL}/shared/reset-password`, resetPasswordBody)
	return apiResults.data
}

export const forgotPasswordRequest = async (forgotPasswordBody) => {
	const apiResults = await post(`${process.env.ATLAS_URL}/shared/forgot-password`, forgotPasswordBody)
	return apiResults.data
}

export const updatePasswordRequest = async (authToken, newPasswordBody) => {
	const apiResults = await put(`${process.env.ATLAS_URL}/shared/change-password`, newPasswordBody, {
		headers: {
			'auth-token': authToken
		}
	})
	return apiResults.data
}

export const anonymousUserDetailsRequest = async anonymousUserId => {
	const apiResults = await get(`${process.env.ATLAS_URL}/bruh/anonymous-user`, {
		headers: {
			'anonymous-user-id': anonymousUserId
		}
	})
	return apiResults.data
}

export const meRequest = async authToken => {
	const apiResults = await get(
		`${process.env.ATLAS_URL}/bruh/me?includePreferences=true&includeBattlepass=true&includeMissions=true&includeSkinOwnership=true&includeWallet=true&includeLeaderboardStats=true&includeAuthToken=true`,
		{
			headers: { 'auth-token': authToken }
		}
	)
	return apiResults.data
}

export const getCurrentSeasonBattlepassRequest = async authToken => {
	const apiResults = await get(
		`${process.env.ATLAS_URL}/bruh/current-season-battlepass`,
		{
			headers: { 'auth-token': authToken }
		}
	)
	return apiResults.data
}

export const getUserBattlepassProgressRequest = async authToken => {
	const apiResults = await get(
		`${process.env.ATLAS_URL}/bruh/get-user-battlepass-progress`,
		{
			headers: { 'auth-token': authToken }
		}
	)
	return apiResults.data
}

export const getCurrentSeasonAdRewardsRequest = async () => {
	const apiResults = await get(
		`${process.env.ATLAS_URL}/bruh/current-season-adrewards`)
	return apiResults.data
}

export const getUserAdRewardsProgressRequest = async authToken => {
	const apiResults = await get(
		`${process.env.ATLAS_URL}/bruh/get-user-adrewards-progress`,
		{
			headers: { 'auth-token': authToken }
		}
	)
	return apiResults.data
}

export const getCurrentSeasonRequest = async() => {
	const apiResults = await get(
		`${process.env.ATLAS_URL}/bruh/current-season`
	)
	return apiResults.data
}

export const markUserMissionConfirmedRequest = async (authToken, mission) => {
	const apiResults = await post(
		`${process.env.ATLAS_URL}/bruh/mark-user-mission-confirmed`,
		mission,
		{
			headers: { 'auth-token': authToken }
		}
	)
	return apiResults.data
}
