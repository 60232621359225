<template>
  <div class="tooltip">
    <p>{{text}}</p>

    <div class="caret-wrapper">
      <div class="caret"></div>
    </div>
  </div>
</template>

<script>
export default {
	name: "Tooltip",
	props: ["text"]
}
</script>

<style lang="scss">
.tooltip {
	padding: 15px 20px !important;
	border-image: url("../assets/bruh-dark-panel.png") round;
	border-image-slice: 14 fill;
	border-image-width: 14px;
	// width: 300px;
	position: absolute;
	left: 50%;
	// top: -40px;
	text-align: center;
	transform: translateX(-50%);
	z-index: 100;

	p {
		width: auto;
	}

	.caret-wrapper {
		width: 100%;
		height: 100%;
		position: relative;

		.caret:before {
			content: "";
			position: absolute;
			width: 0;
			height: 0;
			top: 10px;
			left: 50%;
			transform: translateX(-50%);
			border-left: 20px solid transparent;
			border-right: 20px solid transparent;
			border-top: 20px solid #3F3F3F;
		}
	}
}
</style>
