import logger from "../utils/logger"
import { handleError } from "../utils/error"
import { filter, sortBy, map } from "lodash"
import { getFriendsListRequest, addFriendRequest, acceptFriendRequest, cancelOutgoingFriendRequest, rejectIncomingFriendRequest, searchUsersByUsernameRequest } from "../api/requests"
import Vue from "vue"
import Toasted from "vue-toasted"
Vue.use(Toasted)

export const friendsModule = () => {
	// logger.debug("Initializing Friends store module...")
	return {
		namespaced: true,
		state: {
			fetching: false,
			accepted: [],
			pendingIncoming: [],
			pendingOutgoing: [],
			searchResults: []
		},
		getters: {
			searchResults: state => {
				const allOmittedIds = [
					...map(state.accepted, (acceptedFriend) => {
						return acceptedFriend.id
					}),
					...map(state.pendingIncoming, (pendingFriend) => {
						return pendingFriend.id
					}),
					...map(state.pendingOutgoing, (pendingFriend) => {
						return pendingFriend.id
					})
				]

				return state.searchResults.filter((sr) => {
					return !allOmittedIds.includes(sr.id)
				})
			},
			numberOfFriendsOnline: state => { return filter(state.accepted, (f) => { return f.online }).length },
			numberOfFriends: state => state.accepted.length,
			friendsCurrentlyLoading: state => state.fetching,
			acceptedFriends: state => sortBy(state.accepted, "online"),
			pendingIncoming: state => sortBy(state.pendingIncoming, "online"),
			pendingOutgoing: state => sortBy(state.pendingOutgoing, "online"),
			numberOfTotalPending: state => (state.pendingIncoming.length + state.pendingOutgoing.length),
			allPending: state => { return [...state.pendingIncoming, ...state.pendingOutgoing] }
		},
		mutations: {
			removeOutgoingFriendRequest(state, receiverId) {
				state.pendingIncoming = state.pendingOutgoing.filter((r) => { return r.id !== receiverId })
			},
			removeIncomingFriendRequest(state, requesterId) {
				state.pendingIncoming = state.pendingIncoming.filter((r) => { return r.id !== requesterId })
			},
			clearSearchResults(state) {
				state.searchResults = []
			},
			gotFriendsList(state, friendsList) {
				state.accepted = friendsList.accepted
				state.pendingIncoming = friendsList.pendingIncoming
				state.pendingOutgoing = friendsList.pendingOutgoing
			},
			userSearchComplete(state, searchResults) {
				state.searchResults = searchResults
			},
			friendsFetching(state) {
				state.fetching = true
			},
			friendsNoLongerFetching(state) {
				state.fetching = false
			}
		},
		actions: {
			async getFriendsList({ commit, rootState }) {
				try {
					commit("friendsFetching")
					const apiResults = await getFriendsListRequest(rootState.user.authentication.token)
					commit("gotFriendsList", apiResults)
				} catch (e) {
					console.error("Error occurred when attempting to fetch friends list", e)
					handleError(e)
				} finally {
					commit("friendsNoLongerFetching")
				}
			},
			async addFriend({ commit, rootState, dispatch }, userId) {
				try {
					// console.log("running friends action: addFriend")
					commit("clearSearchResults")
					commit("friendsFetching")
					const apiResults = await addFriendRequest(rootState.user.authentication.token, userId)
					// console.log("result of addFriend:", apiResults)
					Vue.toasted.success("Friend request sent!", {
						position: "top-center",
						duration: 3000
					})
					dispatch("getFriendsList")
				} catch (e) {
					console.error("Error occurred when attempting to add a friend", e)
					handleError(e)
				} finally {
					commit("friendsNoLongerFetching")
				}
			},
			async acceptFriend({ commit, rootState, dispatch }, incomingFriendId) {
				try {
					// console.log("running friends action: acceptFriend")
					commit("friendsFetching")
					const apiResults = await acceptFriendRequest(rootState.user.authentication.token, incomingFriendId)
					// console.log("result of accepting incoming friend request:", apiResults)
					Vue.toasted.success("Friend added!", {
						position: "top-center",
						duration: 3000
					})
					dispatch("getFriendsList")
				} catch (e) {
					console.error("Error occurred when attempting to fetch friends list", e)
					handleError(e)
					dispatch("getFriendsList")
				} finally {
					commit("friendsNoLongerFetching")
				}
			},
			async cancelOutgoingFriend({ commit, dispatch, rootState }, outgoingFriendId) {
				try {
					// console.log("running friends action: cancelOutgoingFriend")
					commit("friendsFetching")
					const apiResults = await cancelOutgoingFriendRequest(rootState.user.authentication.token, outgoingFriendId)
					// console.log("result of cancelling outgoing friend request:", apiResults)
					commit("removeOutgoingFriendRequest", outgoingFriendId)
					Vue.toasted.success("Friend request successfully cancelled!", {
						position: "top-center",
						duration: 3000
					})
					dispatch("getFriendsList")
				} catch (e) {
					console.error("Error occurred when attempting to fetch friends list", e)
					handleError(e)
				}
			},
			async rejectIncomingFriend({ commit, dispatch, rootState }, incomingFriendId) {
				try {
					// console.log("running friends action: rejectIncomingFriend")
					commit("friendsFetching")
					const apiResults = await rejectIncomingFriendRequest(rootState.user.authentication.token, incomingFriendId)
					// console.log("result of rejecting incoming friend request:", apiResults)
					commit("removeIncomingFriendRequest", incomingFriendId)
					Vue.toasted.success("Friend request successfully rejected!", {
						position: "top-center",
						duration: 3000
					})
				} catch (e) {
					console.error("Error occurred when attempting to fetch friends list", e)
					handleError(e)
					dispatch("getFriendsList")
				} finally {
					commit("friendsNoLongerFetching")
				}
			},
			async searchUsersByUsername({ commit, rootState }, usernameString) {
				try {
					commit("clearSearchResults")
					commit("friendsFetching")
					const apiResults = await searchUsersByUsernameRequest(rootState.user.authentication.token, usernameString)
					commit("userSearchComplete", apiResults)
				} catch (e) {
					console.error("Error occurred when attempting to search by username", e)
					handleError(e)
				} finally {
					commit("friendsNoLongerFetching")
				}
			}
		}
	}
}
