import logger from "../utils/logger"

export const errorModule = () => {
	// logger.debug("Initializing Error store module...")
	return {
		namespaced: true,
		state: {
			errorModalVisible: false,
			errorCode: ''
		},
		getters: {
			currentErrorCode: (state) => state.errorCode,
			errorModalCurrentlyVisible: (state) => state.errorCode
		},
		actions: {
			setError(state, errorCode) {
				state.errorCode = errorCode
				state.errorModalVisible = true
			},
			toggleErrorModal(state) {
				state.errorModalVisible = !state.errorModalVisible
			}
		}
	}
}
