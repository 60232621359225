<template>
  <div class="screen" id="support">
    <Panel class="support-panel" :title="'Support'" :oncloseroute="'/settings'">
      <template #panel-content>
        <p>
          {{ t('supportIntroText') }}
          <a href="http://discord.gg/bitfoxgames">{{ t('supportDiscordText') }}</a> {{ t('supportReachOutText') }}
          <a href="mailto:support@otherocean.com">support@otherocean.com</a>
        </p>
      </template>
    </Panel>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Panel from "../reusable-components/Panel"

export default {
	name: "Support",
	components: {
		Panel
  },
  computed: {
    ...mapGetters("i18n", ["t"])
  }
}
</script>


<style lang="scss" scoped>
#support {
	.support-panel {
		max-width: 500px;
		ul {
			list-style: none;
		}
	}
}
</style>

