import logger from "../utils/logger"
import { t } from "../utils/i18n"

export const internationalizationModule = preferredLanguage => {
	// logger.debug("Initializing I18N store module with initial browser language:")
	// logger.debug(preferredLanguage)

	window.currentLocale = preferredLanguage

	return {
		namespaced: true,
		state: {
			currentLocale: preferredLanguage
		},
		mutations: {
			explicitlyChangeLocale(state, newLocale) {
				state.currentLocale = newLocale
				window.currentLocale = newLocale
			}
		},
		actions: {
			explicitlyChangeLocale({ commit }, newLocale) {
				commit("explicitlyChangeLocale", newLocale)
			}
		},
		getters: {
			t: () => stringKey => {
				return t(stringKey)
			}
		}
	}
}
