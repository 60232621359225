export const toggleFullScreen = () => {
	if (!document.fullscreenElement) { //normal browsers
		document.documentElement.requestFullscreen()
	} else if (document.webkitRequestFullscreen) { // safari
		document.webkitRequestFullscreen()
	} else if (document.msRequestFullscreen) { // IE11
		document.msRequestFullscreen()
	} else { // EXIT FULL SCREEN 
		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.webkitExitFullscreen) { // safari
			document.webkitExitFullscreen();
		} else if (document.msExitFullscreen) { // IE11
			document.msExitFullscreen();
		}
	}
}
