// Env var config setup & bootstrapping
import dotenv from "dotenv"
dotenv.config()

// Deps
import Vue from "vue"
import App from "./App.vue"
import { createRouter } from "./router"
import logger from "./utils/logger"
import Vuex from "vuex"
import Toasted from "vue-toasted"
import { hideLoadingScreen, stopLoadingAndDisplayError } from "./utils/loading"
import { loadFromLocalStorage, saveToLocalStorage, removeFromLocalStorage } from "./utils/local-storage"
import { chooseRandomName } from "./utils/random-name"
import {
	createAnonymousUserRequest,
	anonymousUserDetailsRequest,
	meRequest
} from "./api/requests"

// Store modules
import { internationalizationModule } from "./store/i18n"
import { partyModule } from "./store/party"
import { userModule } from "./store/user"
import { shopModule } from "./store/shop"
import { battlepassModule } from "./store/battlepass"
import { errorModule } from "./store/error"
import { friendsModule } from "./store/friends"
import { rankingsModule } from "./store/rankings"
import { vidadModule } from "./store/vidad"




// Vue bootstrap crap
Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(Toasted)

Vue.filter("truncate", function (value, amount) {
	if (!value) return ""
	value = value.toString()
	if (value.length <= amount) return value
	return value.substring(0, amount) + '...'
})

const getInitialUserState = async () => {
	const existingAuthToken = loadFromLocalStorage("auth-token")
	const existingAnonymousUserId = loadFromLocalStorage("anonymous-user-id")

	if (existingAuthToken === undefined) {
		// logger.debug(
		// 	"User isn't signed in; check for existing anonymous user identifier... "
		// )

		if (existingAnonymousUserId === undefined) {
			// logger.debug(
			// 	"No anonymous user identifier found, creating and saving a new one..."
			// )

			const randomName = chooseRandomName()

			const createAnonymousUserResults = await createAnonymousUserRequest(
				randomName,
				"bruh.io"
			)

			const { id } = createAnonymousUserResults

			saveToLocalStorage("anonymous-user-id", id)

			return {
				userType: "anonymous",
				...createAnonymousUserResults
			}
		} else {
			// logger.debug(
			// 	"Existing anonymous user identifier found, dispatching anon user info call..."
			// )

			const anonymousUserDetailsResults = await anonymousUserDetailsRequest(
				existingAnonymousUserId
			)

			return {
				userType: "anonymous",
				...anonymousUserDetailsResults
			}
		}
	} else {
		// logger.debug("User is signed in, dispatching login call...")

		const meResults = await meRequest(existingAuthToken)

		const { token } = meResults.authentication

		saveToLocalStorage("auth-token", token)

		return {
			userType: "registered",
			...meResults
		}
	}
}

const init = async () => {
	try {
		const browserLanguage = navigator.language.substring(0, 2)

		const initialUserState = await getInitialUserState()

		let preferredLanguage

		if (initialUserState.preferences.preferredLanguage !== browserLanguage) {
			// console.info("User has explicitly chosen a language on their profile preferences; overriding browser default")
			preferredLanguage = initialUserState.preferences.preferredLanguage
		} else {
			preferredLanguage = browserLanguage
		}

		const ownedSkins =
			initialUserState.userType === "anonymous"
				? []
				: initialUserState.skinOwnership

		const forteDown = initialUserState.wallet.walletSystemDown

		const store = new Vuex.Store({
			modules: {
				i18n: internationalizationModule(preferredLanguage),
				party: partyModule(),
				user: userModule(initialUserState),
				shop: shopModule(ownedSkins, forteDown),
				battlepass: battlepassModule(initialUserState.battlepass),
				error: errorModule(),
				friends: friendsModule(),
				rankings: rankingsModule(),
				vidad: vidadModule(initialUserState.adRewards)
			}
		})

		if (initialUserState.userType === "registered") {
			if (initialUserState.authentication.token) {
				store.dispatch("user/ensureNoProfanity")
				store.dispatch("user/initClientHeartbeat")
				store.dispatch("user/checkDailyLoginBonusRequest")
			}
		} else if (initialUserState.userType === "anonymous") {
			store.dispatch("user/checkAnonymousDailyLoginBonusRequest")
		}

		store.dispatch("user/getWorldPopulation")
		store.dispatch("user/retryPlayMusicUntilAudioAvailable")

		const router = createRouter(store)

		// Give store a router reference to allow us to push the user around based on store changes
		store.router = router


		function adBlockNotDetected() {
			store.dispatch("vidad/isBlockingAds", false)
		}
		function adBlockDetected() {
			store.dispatch("vidad/isBlockingAds", true)
		}

		if (typeof fuckAdBlock !== 'undefined' || typeof FuckAdBlock !== 'undefined') {
			adBlockDetected();
		} else {
			var importFAB = document.createElement('script');
			importFAB.onload = function () {
				fuckAdBlock.onDetected(adBlockDetected)
				fuckAdBlock.onNotDetected(adBlockNotDetected);
			};
			importFAB.onerror = function () {
				adBlockDetected();
			};
			importFAB.integrity = 'sha256-xjwKUY/NgkPjZZBOtOxRYtK20GaqTwUCf7WYCJ1z69w=';
			importFAB.crossOrigin = 'anonymous';
			importFAB.src = 'https://cdnjs.cloudflare.com/ajax/libs/fuckadblock/3.2.1/fuckadblock.min.js';
			document.head.appendChild(importFAB);
		}

		const app = new Vue({
			router: router,
			store,
			render: h => h(App)
		}).$mount("#app")

		// logger.info("Started BRUH.io Website Vue app!", app)

		hideLoadingScreen()

	} catch (e) {
		console.error(e)
		removeFromLocalStorage("auth-token")
		removeFromLocalStorage("anonymous-user-id")
		stopLoadingAndDisplayError(
			`An error occurred loading bruh.io.<br />Sorry about that, we're working on it!<br />Check back shortly or hit us up on <a href="https://discord.gg/bitfoxgames" target="_blank" rel="noopener noreferrer">Discord</a>.`
		)
	}
}

// Another attempt as solving BRUH-479, this time listening for the page
// to show and if it was shown from backbutton and our url involves the store, then force a reload maybe?
window.addEventListener("pageshow", function (event) {
	var historyTraversal = event.persisted || 
	( typeof this.window.performance != "undefined" && 
	this.window.performance.navigation.type === 2 )

	if(historyTraversal)
	{
		if(this.window.location.href.includes("shop"))
			this.window.location.reload()
	}
})

init()
