<template>
  <div class="screen" id="payment-error">
    <Panel class="payment-error-panel" :title="t('error')">
      <template #panel-content>
        <p>
          {{ t('pmtErrorSomethingWrong') }}<br />{{t('pmtErrorReachOut')}}<a href="mailto:support@otherocean.com">support@otherocean.com</a>{{ t('pmtErrorAndLetUs') }}</p>
      </template>
    </Panel>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Panel from "../reusable-components/Panel"

export default {
	name: "PaymentError",
	components: {
		Panel
	},
	computed: {
		...mapGetters("i18n", ["t"])
	}
}
</script>


<style lang="scss" scoped>
#payment-error {
	.payment-error-panel {
		max-width: 500px;
		p {
			margin-top: 20px;
		}
	}
}
</style>

