<template>
  <div class="screen" id="profile">
    <Panel class="profile-panel" :title="t('navProfileButtonText')">
      <template #panel-content>
        <div class="profile-left">
          <div class="user-misc">
            <p>{{ t("profileChangeSkins") }}</p>
            <div class="current-skins">
              <router-link to="/change-skin?skinType=character">
                <EmbeddedDarkBox class="lighter character-skin">
                  <template #dark-box-content>
                    <SkinImage
                      :skin-data="{ skinCode: selectedCharacterSkin }"
                      :skin-image-type="'character'"
                    ></SkinImage>
                  </template>
                </EmbeddedDarkBox>
              </router-link>
              <router-link to="/change-skin?skinType=weapon">
                <EmbeddedDarkBox class="lighter weapon-skin">
                  <template #dark-box-content>
                    <SkinImage
                      :skin-data="{ skinCode: `${selectedWeaponSkin}` }"
                      :skin-image-type="'weapon_set_preview'"
                    ></SkinImage>
                  </template>
                </EmbeddedDarkBox>
              </router-link>
            </div>
            <p>{{ t("profileAccountDetails") }}</p>
            <div class="quick-stats-wrapper account-details">
              <ul class="quick-stats">
                <li>
                  <div class="stat-key">{{ t("profileUsername") }}:</div>
                  {{ username }}
                </li>
                <li>
                  <div class="stat-key">{{ t("profileEmailAddress") }}:</div>
                  {{ emailAddress | truncate(20) }}
                </li>
                <li>
                  <div
                    class="stat-key account-verified"
                    v-bind:class="{
                      verified: accountVerified,
                      'not-verified': !accountVerified,
                    }"
                  >
                    {{
                      accountVerified
                        ? t("profileEmailVerified")
                        : t("profileEmailNotVerified")
                    }}
                  </div>
                </li>
              </ul>
            </div>
            <p>{{ t("profileAccountOptions") }}</p>
            <div class="quick-stats-wrapper account-options">
              <ul class="quick-stats">
                <li>
                  <router-link to="/change-username">
                    <Button
                      :color="'green'"
                      :onclickfn="() => {}"
                      :text="t('profileAccountOptionsChangeUsername')"
                    ></Button>
                  </router-link>
                </li>
                <li>
                  <router-link to="/change-password">
                    <Button
                      :color="'green'"
                      :onclickfn="() => {}"
                      :text="t('profileAccountOptionsPassword')"
                    ></Button>
                  </router-link>
                </li>
                <li>
                  <router-link to="/settings">
                    <Button
                      :color="'green'"
                      :onclickfn="() => {}"
                      :text="t('profileAccountOptionsSettings')"
                    ></Button>
                  </router-link>
                </li>
                <li>
                  <Button
                    :color="'green'"
                    :onclickfn="
                      () => {
                        logout()
                      }
                    "
                    :text="t('logoutButtonLabel')"
                  ></Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="profile-right">
          <!-- <router-link to="/battlepass">
            <EmbeddedDarkBox class="user-battlepass lighter" v-if="hasBattlepass">
              <template #dark-box-content>
                <h2>{{ t('battlepassTitle') }}</h2>
                <div class="stats-wrapper">
                  <div class="stat">
                    <div class="stat-rank">{{level}}</div>
                    {{ t('battlepassLevel') }}
                  </div>
                  <div class="stat">
                    <div class="stat-rank">{{experience}}</div>
                    {{ t('battlepassExperience') }}
                  </div>
                  <div class="stat">
                    <div class="stat-rank">{{timeRemainingString}}</div>
                    {{ t('battlepassTimeRemaining') }}
                    <img src="../assets/clock_icon.png" />
                  </div>
                </div>
              </template>
            </EmbeddedDarkBox>
            <EmbeddedDarkBox class="user-battlepass lighter" v-else>
              <template #dark-box-content>
                <h2>{{ t('battlepassTitle') }}</h2>
                <span class="buy-cta">{{ t('clickBuyBattlepass') }}</span>
              </template>
            </EmbeddedDarkBox>
          </router-link> -->
          <EmbeddedDarkBox class="user-seasonal lighter">
            <template #dark-box-content>
              <h2>{{ t("statsSeason") }} {{currentSeason}}</h2>
              <div class="stats-wrapper">
                <div class="stat">
                  <div class="stat-rank">
                    {{ stats.seasonalLeaderboardRank + 1}}
                  </div>
                  {{ t("profileStatLabelRank") }}
                </div>
                <div class="stat">
                  <div class="stat-rank">{{ stats.seasonalPlays }}</div>
                  {{ t("profileStatLabelPlays") }}
                </div>
                <div class="stat">
                  <div class="stat-rank">{{ stats.seasonalWins }}</div>
                  {{ t("profileStatLabelWins") }}
                </div>
                <div class="stat">
                  <div class="stat-rank">{{ stats.seasonalKills }}</div>
                  {{ t("profileStatLabelKills") }}
                </div>
              </div>
            </template>
          </EmbeddedDarkBox>
          <EmbeddedDarkBox class="user-all-time lighter">
            <template #dark-box-content>
              <h2>{{ t("statsAllTime") }}</h2>
              <div class="stats-wrapper">
                <div class="stat">
                  <div class="stat-rank">{{ stats.globalLeaderboardRank + 1 }}</div>
                  {{ t("profileStatLabelRank") }}
                </div>
                <div class="stat">
                  <div class="stat-rank">{{ stats.plays }}</div>
                  {{ t("profileStatLabelPlays") }}
                </div>
                <div class="stat">
                  <div class="stat-rank">{{ stats.wins }}</div>
                  {{ t("profileStatLabelWins") }}
                </div>
                <div class="stat">
                  <div class="stat-rank">{{ stats.kills }}</div>
                  {{ t("profileStatLabelKills") }}
                </div>
              </div>
            </template>
          </EmbeddedDarkBox>
        </div>
      </template>
    </Panel>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import Panel from "../reusable-components/Panel"
import Button from "../reusable-components/Button"
import SkinImage from "../reusable-components/SkinImage"
import EmbeddedDarkBox from "../reusable-components/EmbeddedDarkBox"
export default {
  name: "Profile",
  components: {
    Panel,
    Button,
    EmbeddedDarkBox,
    SkinImage,
  },
  computed: {
    ...mapGetters("i18n", ["t"]),
    ...mapGetters("user", [
      "userType",
      "username",
      "walletBalance",
      "accountVerified",
      "selectedCharacterSkin",
      "selectedWeaponSkin",
      "emailAddress",
      "stats",
    ]),
    ...mapGetters("battlepass", [
      "hasBattlepass",
      "level",
      "experience",
      "timeRemainingString",
    ]),
    ...mapGetters("rankings", [
      "currentSeason"
    ])
  },
  methods: {
    ...mapActions("user", ["logout"]),
  },
}
</script>

<style lang="scss">
@media only screen and (max-width: 925px) {
  #profile {
    .profile-panel {
      transform: scale(0.75);
    }
  }
}

@media only screen and (max-width: 735px) {
  #profile {
    .profile-panel {
      transform: scale(0.5);
    }
  }
}

#profile {
  .profile-panel {
    display: flex;
    min-width: 750px;
    padding-top: 20px;

    .profile-left {
      // border: 1px solid blue;
      flex: 2;
      display: flex;

      .user-misc {
        display: flex;
        flex-direction: column;

        p {
          font-size: 20px;
          text-transform: uppercase;
          color: #c1c1c1;
          text-align: center;
        }

        .current-skins {
          display: flex;
          flex-direction: row;

          .character-skin,
          .weapon-skin {
            width: 100px;
            height: 100px;
            margin: 5px;

            &:hover {
              opacity: 0.8;
            }
          }

          .character-skin {
            .character-skin-image {
              transform: scale(2);
              position: relative;
              top: -4px;
            }
          }

          .weapon-skin {
            .skin-image-wrapper {
              position: relative;
              top: 8px;
              left: 3px;
              transform: scale(0.75);
            }
          }
        }

        .account-details {
          // padding-top: 10px;
          margin-bottom: 6px;
        }

        .account-options {
          padding-top: 3px;
          .button-inner {
            font-size: 18px;
          }
        }

        .quick-stats-wrapper {
          margin: 0;
          ul {
            text-align: center;
            list-style: none;

            li {
              margin-bottom: 5px;
              font-size: 14px;
              button {
                width: 95%;
              }
              div.stat-key {
                color: #c1c1c1;

                &.verified {
                  color: lighten(#498f4a, 20%);
                }

                &.not-verified {
                  color: darken(red, 10%);
                }
              }
            }
          }
        }
      }
    }

    .profile-right {
      // border: 1px solid red;
      flex: 4;
      padding: 30px 0 15px 3px;

      a {
        text-decoration: none;
      }

      .user-battlepass {
        &:hover {
          opacity: 0.8;
        }

        .buy-cta {
          color: white;
        }
      }

      .user-seasonal,
      .user-all-time,
      .user-battlepass {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        flex-direction: column;

        h2 {
          color: #ffb425;
          text-transform: uppercase;
          margin-bottom: 10px;
        }

        .stats-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .stat {
            vertical-align: top;
            font-size: 14px;
            color: #c1c1c1;
            text-align: center;
            margin: 10px;

            .stat-rank {
              font-size: 24px;
              color: white;
            }
          }
        }
      }
    }
  }
}
</style>

