import logger from "../utils/logger"
import {
	updatePreferencesRequest,
	loginRequest,
	registerRequest,
	findMatchRequest,
	heartbeatRequest,
	checkDailyLoginBonusRequest,
	forgotPasswordRequest,
	updateUsernameRequest,
	updatePasswordRequest,
	getWorldPopulationRequest,
	verifyEmailRequest,
	resetPasswordRequest,
	markUserMissionConfirmedRequest,
	checkAnonymousDailyLoginBonusRequest,
	unsubscribeEmailRequest
} from "../api/requests"
import {
	removeFromLocalStorage,
	saveToLocalStorage
} from "../utils/local-storage"
import { showLoadingScreen } from "../utils/loading"
import { stringify } from "query-string"
import Vue from "vue"
import Toasted from "vue-toasted"
Vue.use(Toasted)
import { handleError } from "../utils/error"
import Velocity from "velocity-animate"
import { some } from "lodash"
import {filter, additionalProfanityCheck } from "../utils/profanityFilter"
import sanitizeInput from "../utils/symbolAndDoubleScrubber"

import musicBruh001Webm from '../assets/BRUH_MUS_001.webm'
import musicBruh001Ogg from '../assets/BRUH_MUS_001.ogg'
import musicBruh001Mp3 from '../assets/BRUH_MUS_001.mp3'
import { Howl, Howler } from 'howler'

Howler.autoUnlock = true

const bgm = new Howl({
	src: [musicBruh001Webm, musicBruh001Ogg, musicBruh001Mp3],
	volume: 0.3,
	loop: true
})


export const userModule = initialUserState => {
	// logger.debug("Initializing User store module with initial state:")
	// logger.debug(JSON.stringify(initialUserState, null, 2))

	if (some(initialUserState.missions, ['is_complete', true])) {
		Vue.toasted.success("Some of your missions are complete!<br />Click on them to claim the reward.", {
			position: "top-center",
			duration: 7500
		})
	}


	return {
		namespaced: true,
		state: {
			userDataFetching: false,
			population: null,
			...initialUserState
		},
		mutations: {
			gotWorldPopulation: (state, worldPopulationResults) => {
				state.population = worldPopulationResults
			},
			newUsername: (state, newName) => {
				state.username = newName
			},
			emailVerified: (state) => {
				state.accountVerified = true
			},
			userDataFetching: state => {
				state.userDataFetching = true
			},
			userDataNoLongerFetching: (state) => {
				state.userDataFetching = false
			},
			updatedMusicVolume:(state, volume) => {
				state.preferences.musicVolume = volume
				bgm.volume(volume / 100 * 0.3) // Times original volume
			},
			updatedSFXVolume:(state, volume) => {
				state.preferences.sfxVolume = volume
			},
			updatedPreferences: (state, latestPreferences) => {
				state.preferences = { ...latestPreferences }

				bgm.stop()
				if (state.preferences.musicEnabled) {
					bgm.play()
				}
			},
			updatedWalletBalance: (state, newBalance) => {
				state.wallet.balance = newBalance
			},
			missionConfirmed: (state, mission) => {
				if (state.missions.indexOf(mission) !== -1) {
					state.missions.splice(state.missions.indexOf(mission), 1)
				}
			},
			updateAdMissions: (state, missions) => {
				if (state.missions) {
					let completedMission = false

					missions.forEach(updatedMission => {
						for (let i = 0; i < state.missions.length; ++i) {
							let oldMission = state.missions[i]

							if (!oldMission.is_complete && updatedMission.mission_id == oldMission.mission_unique_id) {

								oldMission.progress_current = updatedMission.progress_current

								if (updatedMission.is_complete) {
									completedMission = true
									oldMission.is_complete = true
								}

								oldMission.progress_percent = (oldMission.progress_current / oldMission.progress_max) * 100

								i = state.missions.length
							}
						}
					})

					if (completedMission) {
						Vue.toasted.success("Some of your battlepass missions are complete!<br />Click on them to claim the reward.", {
							position: "top-center",
							duration: 7500
						})
					}

				} else {
					console.error("No missions to modify!")
				}
			}
		},
		getters: {
			population: state => { return state.population },
			emailAddress: state => {
				return state.emailAddress
			},
			usersExchangeAccountIsLinked: state => {
				return state.exchangeAccount.linked === true
			},
			userType: state => {
				return state.userType
			},
			accountVerified: state => {
				return state.accountVerified
			},
			userDataFetching: state => {
				return state.userDataFetching
			},
			username: state => {
				return state.username
			},
			walletBalance: state => {
				return parseInt(state.wallet.balance)
			},
			selectedCharacterSkin: state => {
				return state.userType === "anonymous"
					? "man"
					: state.preferences.selectedCharacterSkin
			},
			selectedWeaponSkin: state => {
				return state.userType === "anonymous"
					? "default"
					: state.preferences.selectedWeaponSkin
			},
			stats: state => {
				return state.leaderboardData
			},
			preferences: state => {
				return state.preferences
			},
			dailyMissions: state => {
				if (!state.missions || !state.missions.length) {
					return []
				}
				const dailyMissions = state.missions.filter(m => { return m.mission_timespan === 'daily' })
				return dailyMissions
			},
			weeklyMissions: state => {
				if (!state.missions || !state.missions.length) {
					return []
				}
				const dailyMissions = state.missions.filter(m => { return m.mission_timespan === 'weekly' })
				return dailyMissions
			},
			allMissions: state => {
				return state.missions
			}
		},
		actions: {
			async soloQueue({ commit, state }) {
				try {
					window.googleanalytics.send('event', 'join-game-queue', 'solo')
					commit("userDataFetching")
					const matchfinderResults = await findMatchRequest(state.preferences.preferredMatchmakingRegion, "solo")
					const game_instance_address = matchfinderResults.gameInstanceAddress
					let queryParamPayload = {
						identifier_type: state.userType,
						identifier:
							state.userType === "registered"
								? state.authentication.token
								: state.id,
						game_instance_address
					}
					const queryParams = stringify(queryParamPayload, {
						encode: true
					})
					const redirectUrl = process.env.GAME_CLIENT_URL + "?" + queryParams
					window.location.replace(redirectUrl)
				} catch (e) {
					console.error("Error occurred when attempting to solo queue", e)
					handleError(e)
				} finally {
					commit("userDataNoLongerFetching")
				}
			},
			async initClientHeartbeat({ dispatch, state }) {
				try {
					await heartbeatRequest(state.authentication.token)
					setTimeout(async () => {
						await heartbeatRequest(state.authentication.token)
						dispatch("initClientHeartbeat")
					}, 60000)
				} catch (e) {
					console.error("Error occurred when attempting to run heartbeat", e)
					handleError(e)
				}
			},
			async checkAnonymousDailyLoginBonusRequest({ commit, state }) {
				try {
					const checkAnonymousLoginBonusResults = await checkAnonymousDailyLoginBonusRequest(state.id)

					if (checkAnonymousLoginBonusResults.walletSystemDown) {
						// console.log("commiting forte down")
						commit("shop/forteDown", {}, { root: true })
					} else {
						if (checkAnonymousLoginBonusResults.dailyLoginBonusAcquired) {
							commit("updatedWalletBalance", checkAnonymousLoginBonusResults.newWalletBalance)
							Vue.toasted.success("Nice! Daily login bonus acquired!", {
								position: "top-center",
								duration: 7500
							})
						}
					}
				} catch (e) {
					console.error("Error occurred when attempting to check daily login bonus", e)
					handleError(e)
				}
			},
			async checkDailyLoginBonusRequest({ commit, state }) {
				try {
					const checkLoginBonusResults = await checkDailyLoginBonusRequest(state.authentication.token)

					if (checkLoginBonusResults.walletSystemDown) {
						// console.log("commiting forte down")
						commit("shop/forteDown", {}, { root: true })
					} else {
						if (checkLoginBonusResults.dailyLoginBonusAcquired) {
							commit("updatedWalletBalance", checkLoginBonusResults.newWalletBalance)
							Vue.toasted.success("Nice! Daily login bonus acquired!", {
								position: "top-center",
								duration: 7500
							})
						}
					}
				} catch (e) {
					console.error("Error occurred when attempting to check daily login bonus", e)
					handleError(e)
				}
			},

			async getWorldPopulation({ commit }) {
				try {
					commit("userDataFetching")
					const apiResults = await getWorldPopulationRequest()
					commit("gotWorldPopulation", apiResults)
					commit("userDataNoLongerFetching")
				} catch (e) {
					console.error("Error occurred when attempting to fetch world CCU", e)
					handleError(e)
				} finally {
					commit("userDataNoLongerFetching")
				}
			},
			async logout({ commit }) {
				try {
					showLoadingScreen()
					removeFromLocalStorage("auth-token")
					window.location.href = "/"
				} catch (e) {
					commit("userDataNoLongerFetching")
					console.error("Error occurred when attempting to log out", e)
					handleError(e)
				}
			},
			async submitLogin({ commit, rootState }, loginPayload) {
				try {
					commit("userDataFetching")
					const apiResults = await loginRequest(loginPayload)
					saveToLocalStorage("auth-token", apiResults.authentication.token)
					removeFromLocalStorage("anonymous-user-id")

					const params = window.location.search

					// console.log("rootState", rootState)
					if (rootState.party.partyId) {
						window.location.href = `/play/party/${rootState.party.partyId}` + params
					} else {
						window.location.href = "/profile" + params
					}
				} catch (e) {
					commit("userDataNoLongerFetching")
					console.error("Error occurred when attempting to submit login", e)
					handleError(e)
				}
			},
			async submitRegister({ commit, rootState }, registerPayload) {
				try {
					commit("userDataFetching")
					const apiResults = await registerRequest(registerPayload)
					saveToLocalStorage("auth-token", apiResults.authentication.token)
					removeFromLocalStorage("anonymous-user-id")
					// console.log("rootState", rootState)
					if (rootState.party.partyId) {
						window.location.href = `/play/party/${rootState.party.partyId}`
					} else {
						window.location.href = "/profile"
					}
				} catch (e) {
					commit("userDataNoLongerFetching")
					console.error("Error occurred when attempting to submit registration", e)
					handleError(e)
				}
			},
			async submitChangeUsername({ state, commit }, usernameBody) {
				try {
					if(window.googleanalytics)
						window.googleanalytics.send('event', 'change-username')
					commit("userDataFetching")
					const apiResults = await updateUsernameRequest(state.authentication.token, usernameBody)
					commit("newUsername", apiResults.usernameChangedTo)
					Vue.toasted.success(`Username changed successfully. Welcome, ${apiResults.usernameChangedTo}!`, {
						position: "top-center",
						duration: 7500,
					})
					commit("userDataNoLongerFetching")
					this.router.push("/profile")
				} catch (e) {
					commit("userDataNoLongerFetching")
					console.error("Error occurred when attempting to change username", e)
					handleError(e)
				} finally {
					commit("userDataNoLongerFetching")
				}
			},
			async submitChangePassword({ state, commit }, usernameBody) {
				try {
					commit("userDataFetching")
					const apiResults = await updatePasswordRequest(state.authentication.token, usernameBody)
					// console.log("Results of changing password:", apiResults)
					Vue.toasted.success("Password successfully changed!", {
						position: "top-center",
						duration: 7500,
					})
					commit("userDataNoLongerFetching")
					this.router.push("/profile")
				} catch (e) {
					console.error("Error occurred when attempting to change password", e)
					handleError(e)
				} finally {
					commit("userDataNoLongerFetching")
				}
			},
			async submitEmailVefication({ commit }, verificationBody) {
				try {
					commit("userDataFetching")
					const apiResults = await verifyEmailRequest(verificationBody)
					// console.log("Results of verifying email", apiResults)
					commit("emailVerified")
					commit("userDataNoLongerFetching")
				} catch (e) {
					console.error("Error occurred when attempting to verify email", e)
					this.router.push("/play")
					handleError(e)
				} finally {
					commit("userDataNoLongerFetching")
				}
			},
			async submitEmailUnsubscribe({ commit }, unsubscribeBody) {
				try {
					commit("userDataFetching")
					const apiResults = await unsubscribeEmailRequest(unsubscribeBody)
					console.log("Results of unsubscribing email", apiResults)
					commit("unsubscribed")
					commit("userDataNoLongerFetching")
				} catch (e) {
					console.error("Error occurred when attempting to verify email", e)
					this.router.push("/play")
					handleError(e)
				} finally {
					commit("userDataNoLongerFetching")
				}
			},
			async submitResetPassword({ commit }, resetPasswordBody) {
				try {
					commit("userDataFetching")
					const apiResults = await resetPasswordRequest(resetPasswordBody)
					// console.log("Results of resetting password", apiResults)
					Vue.toasted.success("Password successfully changed! Log in again.", {
						position: "top-center",
						duration: 7500,
					})
					commit("userDataNoLongerFetching")
					this.router.push("/login-signup")
				} catch (e) {
					console.error("Error occurred when attempting to submit password reset", e)
					handleError(e)
				} finally {
					commit("userDataNoLongerFetching")
				}
			},
			async submitForgotPassword({ commit }, forgotPasswordBody) {
				try {
					commit("userDataFetching")
					const apiResults = await forgotPasswordRequest(forgotPasswordBody)
					// console.log("Results of forgot password", apiResults)
					Vue.toasted.success("Password reset email has been sent!", {
						position: "top-center",
						duration: 7500,
					})
					commit("userDataNoLongerFetching")
					this.router.push("/login-signup")
				} catch (e) {
					console.error("Error occurred when attempting to submit forgot password", e)
					handleError(e)
				} finally {
					commit("userDataNoLongerFetching")
				}
			},
			async changePreference({ state, commit, dispatch }, params) {
				try {
					const { preference, newValue } = params
					commit("userDataFetching")

					if (preference === "preferredLanguage") {
						showLoadingScreen()
					}

					const preferencesPayload = {
						...state.preferences,
						[preference]: newValue
					}
					const preferencesHeaderBlob =
						state.userType === "registered"
							? { 'auth-token': state.authentication.token }
							: { 'anonymous-user-id': state.id }

					
					const updateResults = await updatePreferencesRequest(
						preferencesHeaderBlob,
						preferencesPayload
					)

					if (preference === "selectedCharacterSkin") {
						dispatch("party/updateCharacterSkin", updateResults.preferences.selectedCharacterSkin, { root: true })
					}

					if (preference === "selectedWeaponSkin") {
						dispatch("party/updateWeaponSkin", updateResults.preferences.selectedWeaponSkin, { root: true })
					}

					if (preference === "preferredLanguage") {
						window.location.reload()
					}

					commit("updatedPreferences", updateResults.preferences)
				} catch (e) {
					console.error("Error occurred when attempting to change a user setting", e)
					handleError(e)
				} finally {
					commit("userDataNoLongerFetching")
				}
			},
			async markMissionConfirmed({ commit, rootState }, payload) {
				const { mission, originElement } = payload
				try {
					if (mission.is_complete && !mission.user_confirmed_date) {
						if (mission.mission_timespan === 'weekly') {
							window.googleanalytics.send('event', 'mark-mission-confirmed', 'weekly', mission.description)
							//NOTE: this section is hardcoded to refer to the #wallet-container and #coin-image in StatusBar.vue
							const walletContainer = document.getElementById("wallet-container")
							const sourceCoinContainer = document.getElementById("coin-image")
							const sourceCoinImage = sourceCoinContainer.firstChild
							const body = document.body

							const numCoins = 15
							const intervalMs = 20

							if (walletContainer && sourceCoinImage) {
								const originRect = originElement.getBoundingClientRect()
								const destRect = walletContainer.getBoundingClientRect()
								for (let i = 0; i < numCoins; i++) {
									setTimeout(function () {
										let newCoin = sourceCoinImage.cloneNode(true)
										newCoin.id = null
										newCoin.style.position = "absolute"
										newCoin.style.zIndex = "10000"
										newCoin.style.transform = "scale(2)"
										body.appendChild(newCoin)
										newCoin.style.left = `${originRect.left + i * 6}px`
										newCoin.style.top = `${originRect.top}px`
										Velocity(newCoin, {
											left: destRect.left,
											top: destRect.top
										}, {
											duration: 700,
											easing: "easeInSine",
											complete: function () {
												body.removeChild(newCoin)
											},
										})
									}, i * intervalMs)
								}
							}
							Vue.toasted.success(`You earned +${numCoins} coins and 14,000 experience! Nice!`, {
								position: "top-center",
								duration: 7500
							})
						} else if (mission.mission_timespan === 'daily') {
							window.googleanalytics.send('event', 'mark-mission-confirmed', 'daily', mission.description)
						}
						commit("missionConfirmed", mission)
						const results = await markUserMissionConfirmedRequest(rootState.user.authentication.token, {
							mission_timespan: mission.mission_timespan,
							mission_unique_id: mission.mission_unique_id,
						})
						// console.log({results})
						if (results.updatedBalance) {
							commit("updatedWalletBalance", results.updatedBalance)
							Vue.toasted.success(`You earned ${mission.reward} Coins! Nice!`, {
								position: "top-center",
								duration: 7500
							})
						}
					}
				}
				catch (err) {
					commit("userDataNoLongerFetching")
					console.error(err)
				}
			},
			updateMusicVolume: ({state, commit}, volume) => {
				commit("updatedMusicVolume", volume)
			},
			updateSFXVolume: ({state, commit}, volume) => {
				commit("updatedSFXVolume", volume)
			},
			ensureNoProfanity({dispatch, state}) {
				try {
					let sanitizedName = sanitizeInput(initialUserState.username)
					if(	filter.isProfane(sanitizedName) || additionalProfanityCheck(filter, sanitizedName)) {
						let randomNumber = Math.floor(Math.random() * 999999)
						let usernameChangeRequest = {
							newUsername: "BeKind" + randomNumber,
							newUsernameConfirmation: "BeKind" + randomNumber
						}
						dispatch("submitChangeUsername", usernameChangeRequest)
						handleError({}, "profaneLanguageDetected")
					}
				} catch (e) {
					console.error("Error occurred when attempting to check profanity", e)
					handleError(e)
				}
			},
			retryPlayMusicUntilAudioAvailable({ state }) {
				if (!state.preferences.musicEnabled) { return } // no music = no retry
				let count = 0

				function reval() {
					count++
					if (!Howler._audioUnlocked) {
						if (count <= 150) { // try for 15 seconds then quit
							setTimeout(reval, 100)
						}
					} else {
						if (!state.preferences.musicEnabled) { return } // no music = no retry

						bgm.stop()
						bgm.play()
					}
				}
				setTimeout(reval, 100)
			}
		}
	}
}
