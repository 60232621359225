<template>
  <div class="screen" id="reset-password">
    <Panel class="reset-password-panel">
      <template #panel-content>
        <div class="forms">
          <EmbeddedDarkBox class="lighter reset-password-form">
            <template #dark-box-content>
              <div class="form-title">Reset Password</div>
              <p class="error" v-if="resetPassword.error">
                {{ resetPassword.error }}
              </p>
              <div class="input-group">
                <div class="input-label">
                  <label for="reset-password-password">New Password</label>
                </div>
                <div class="input">
                  <input
                    v-on:keyup.enter="
                      () => {
                        resetPasswordFormSubmit()
                      }
                    "
                    v-model="resetPassword.newPassword"
                    id="reset-password-password"
                    type="password"
                  />
                </div>
              </div>
              <div class="input-group">
                <div class="input-label">
                  <label for="reset-password-password-confirmation"
                    >Confirm New Password</label
                  >
                </div>
                <div class="input">
                  <input
                    v-on:keyup.enter="
                      () => {
                        resetPasswordFormSubmit()
                      }
                    "
                    v-model="resetPassword.newPasswordConfirmation"
                    id="reset-password-password-confirmation"
                    type="password"
                  />
                </div>
              </div>
              <div class="form-submit-btn">
                <Button
                  :color="'green'"
                  :disabled="userDataFetching"
                  :onclickfn="
                    () => {
                      resetPasswordFormSubmit()
                    }
                  "
                  :text="'Save New Password'"
                ></Button>
              </div>
            </template>
          </EmbeddedDarkBox>
        </div>
      </template>
    </Panel>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { some, values } from "lodash"
import Panel from "../reusable-components/Panel"
import EmbeddedDarkBox from "../reusable-components/EmbeddedDarkBox"
import Checkbox from "../reusable-components/Checkbox"
import Button from "../reusable-components/Button"
import LoadyBro from "../reusable-components/LoadyBro"
import { parse } from "query-string"

export default {
  name: "ResetPassword",
  components: {
    Panel,
    EmbeddedDarkBox,
    Checkbox,
    Button,
    LoadyBro
  },
  data() {
    return {
      resetPassword: {
        error: false,
        newPassword: "",
        newPasswordConfirmation: ""
      }
    }
  },
  computed: {
    ...mapGetters("user", ["userDataFetching"])
  },
  methods: {
    ...mapActions("user", ["submitResetPassword"]),
    resetPasswordFormSubmit() {
      // console.log("foo")
      const { forgotPasswordToken } = parse(window.location.search)
      this.resetPassword.error = false

      let normalizedFormValues = {
        forgotPasswordToken: forgotPasswordToken,
        newPassword: this.resetPassword.newPassword,
        newPasswordConfirmation: this.resetPassword.newPasswordConfirmation
      }

      if (
        some(values(normalizedFormValues), v => {
          return v === undefined || v === ""
        })
      ) {
        return (this.resetPassword.error =
          "You need to fill out the entire form.")
      }

      this.submitResetPassword(normalizedFormValues)
    }
  }
}
</script>

<style lang="scss">
#reset-password {
  .reset-password-panel {
    position: relative;

    .loady-bro {
      position: absolute;
      top: 65%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .forms {
      display: flex;
    }

    .reset-password-form {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 30px 30px 50px 30px;
      min-width: 380px;

      p.error {
        text-align: center;
        color: red;
      }

      .form-title {
        text-align: center;
        font-size: 32px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      .input-group {
        display: flex;
        height: 35px;
        margin-bottom: 10px;

        .input-label {
          align-self: center;
          width: 105px;
          text-align: left;

          label {
            color: #d9d9d9;
          }
        }
        .input {
          margin-left: auto;
          display: flex;

          input {
            align-self: center;
            background-color: #262628;
            border: none;
            height: 20px;
            font-size: 14px;
            color: #fff;
            padding: 0 5px;
          }
        }
      }

      .form-submit-btn {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);

        button {
          height: auto;
          .button-inner {
            font-size: 24px;
          }
        }
      }
    }
  }
}
</style>
