import logger from "../utils/logger"
import {
	getUserAdRewardsProgressRequest,
	getCurrentSeasonAdRewardsRequest,
	addVideoAdWatched
} from "../api/requests"
import { each } from "lodash"
import { handleError } from "../utils/error"
import moment from 'moment'
import Toasted from "vue-toasted"
import Vue from "vue"
import { t } from "../utils/i18n"

Vue.use(Toasted)

export const vidadModule = (initialAdRewards) => {
	return {
		namespaced: true,
		state: {
			adRewardsFetching: false,
			currentSeasonAdReardsFetching: false,
			user: {
				numAdsWatched: 0,
				nextAd: null,
				adRewardsEarned: null
			},
			earnedReward: false,
			hasAdRewards: false,
			hadRewardProgressError: false,
			toastUnlockedOnFetched: false,
			isBlockingAds: false,
			currentSeasonAdRewards: {
				startDate: "",
				endDate: "",
				timeRemaining: "",
				adsRequired: 9999,
				rewardId: "",
				adRewardId: "",
				friendlyName: "",
				skinType: ""
			},
			translations: {
				unlocked: t('unlocked'),
				unknownError: t('unknownServerError'),
				noCoinsError: t('noCoinsError'),
				coinsAcquired: t('coinsAcquired'),
				closerToReward: t('closerToReward')
			}
		},
		getters: {
			adRewardsIsLoading: state => state.adRewardsFetching,
			currentSeasonAdRewardsIsLoading: state => state.currentSeasonAdRewardsFetching,
			numAdsWatched: state => state.user.numAdsWatched,
			user: state => state.user,
			currentSeasonAdRewards: state => state.currentSeasonAdRewards,
			timeToNextAd: state => state.user.nextAd,
			rewardId: state => state.currentSeasonAdRewards.rewardId,
			adRewardId: state => state.currentSeasonAdRewards.adRewardId,
			friendlyName: state => state.currentSeasonAdRewards.friendlyName,
			skinType: state => state.currentSeasonAdRewards.skinType,
			adsRequired: state => state.currentSeasonAdRewards.adsRequired,
			hasAdReward: state => state.hasAdRewards,
			isBlockingAds: state => state.isBlockingAds,
			hadRewardProgressError: state => state.hadRewardProgressError,
			earnedCurrentReward: state => state.earnedReward || (state.user.adRewardsEarned && state.user.adRewardsEarned.skins &&
				state.user.adRewardsEarned.skins.some(s => s.id === state.currentSeasonAdRewards.rewardId))
		},
		mutations: {
			updateAdRewards: (state, adRewardsResults) => { //(progress)
				state.adRewardsFetching = false

				if (adRewardsResults === null) {
					state.user.nextAd = moment()
					return
				}

				let { numAdsWatched, timeRemaining, adRewardsEarned } = adRewardsResults

				let now = moment()
				now.add(timeRemaining.minutes, 'm')
				now.add(timeRemaining.seconds, 's')
				now.add(timeRemaining.hours, 'h')

				if (!numAdsWatched) {
					numAdsWatched = 0
				}

				state.user = {
					numAdsWatched,
					nextAd: now,
					adRewardsEarned
				}
				
				state.hadRewardProgressError = false
			},
			updateCurrentSeasonAdRewards: (state, adRewards) => {
				state.currentSeasonAdRewardsFetching = false

				if (!adRewards) {

					//no promotion going on
					state.hasAdRewards = false
					return
				}
				
				const { adRewardId, rewardIdentifier, friendlyName, skinType,
					numAdsRequiredToWatch, startDate, endDate, timeRemaining } = adRewards

				let localEnd = moment(endDate)
				let localStart = moment(startDate)

				localEnd.local()
				localStart.local()

				state.currentSeasonAdRewards = {
					startDate: localStart.format("ddd, MMMM Do, hA"),
					endDate: localEnd.format("ddd, MMMM Do, hA"),
					timeRemaining,
					adsRequired: numAdsRequiredToWatch,
					rewardId: rewardIdentifier,
					adRewardId,
					friendlyName,
					skinType
				}

				if (state.currentSeasonAdRewards.skinType === 'weapon_set') {
					state.currentSeasonAdRewards.skinType = 'weapon_set_preview'
				}

				state.hasAdRewards = true

				if (state.toastUnlockedOnFetched) {
					state.toastUnlockedOnFetched = false
					Vue.toasted.success(state.currentSeasonAdRewards.friendlyName + " unlocked!", {
						position: "top-center",
						duration: 7500
					})
				}
			},
			adRewardsLoading: state => {
				state.adRewardsFetching = true
			},
			currentSeasonAdRewardsLoading: state => {
				state.currentSeasonAdRewardsFetching = true
			},
			updateTimeRemaining: (state, timeRemaining) => {
				let now = moment()
				now.add(timeRemaining.hours, 'h')
				now.add(timeRemaining.minutes, 'm')
				now.add(timeRemaining.seconds, 's')

				state.user.nextAd = now
			},
			updateNumAdsWatched: (state, adsWatched) => {
				state.user.numAdsWatched = adsWatched
			},
			setAdRewardsFetching: (state, fetching) => {
				state.adRewardsFetching = fetching
			},
			setRewardProgressError: (state, hasErr) => {
				state.hadRewardProgressError = hasErr
			},
			setEarnedReward: (state) => {
				state.earnedReward = true
			},
			setToastUnlockedOnFetched: (state, toast) => {
				state.toastUnlockedOnFetched = toast
			},
			setIsBlockingAds: (state, isBlocking) => {
				state.isBlockingAds = isBlocking
			}
		},
		actions: {
			async getAdRewardsProgress({ commit, rootState }) {
				try {
					commit("adRewardsLoading")
					const adRewardsResults = await getUserAdRewardsProgressRequest(rootState.user.authentication.token)
					commit('updateAdRewards', adRewardsResults)
				} catch (e) {
					console.error("Error occurred when attempting to get Ad Rewards progress", e)
					commit('setRewardProgressError', true)
					handleError(e)
				}
			},
			async getCurrentSeasonAdRewards({ commit }) {
				try {
					commit("currentSeasonAdRewardsLoading")
					const adRewardsResults = await getCurrentSeasonAdRewardsRequest()
					commit('updateCurrentSeasonAdRewards', adRewardsResults)
				} catch (e) {
					handleError(e)
				}
			},
			earnedReward({ commit, state }) {
				if (state.currentSeasonAdRewards.friendlyName != "") {
					// toast w/ name
					Vue.toasted.success(state.currentSeasonAdRewards.friendlyName + " " + state.translations.unlocked + "!", {
						position: "top-center",
						duration: 7500
					})
				}
				else {
					// wait for name to toast
					commit('setToastUnlockedOnFetched', true)
				}
			},
			isBlockingAds({ commit }, isBlocking) {
				commit('setIsBlockingAds', isBlocking)
			},
			async videoAdWatched({ commit, state, rootState }) {
				try {
					if (rootState.user.userType === "registered") {
						commit('setAdRewardsFetching', true)

						const addAdResults = await addVideoAdWatched(rootState.user.authentication.token) //(post-ad-watched)

						commit('setAdRewardsFetching', false)

						if (!addAdResults) {
							Vue.toasted.error(state.translations.unknownError, {
								position: "top-center",
								duration: 7500
							})
							return
						}

						if (!addAdResults.rewardAcquired) {
							const reason = addAdResults.reason
							if (reason === 'cooldown') {
								const timeRemaining = addAdResults.timeRemaining
								commit('updateTimeRemaining', timeRemaining)

								Vue.toasted.error(state.translations.unknownError, {
									position: "top-center",
									duration: 7500
								})
							} else if (reason === 'wallet') {
								// commit wallet down?
								Vue.toasted.error(state.translations.noCoinsError, {
									position: "top-center",
									duration: 7500
								})
							} else {
								//unknown
								Vue.toasted.error(state.translations.unknownError, {
									position: "top-center",
									duration: 7500
								})
							}
							
							return
						}

						if (addAdResults.gotCoins) {
							commit("user/updatedWalletBalance", addAdResults.newBalance, { root: true })
						}
						
						const nextWatchTime = addAdResults.nextWatchTime
						commit('updateTimeRemaining', nextWatchTime)
						commit('updateNumAdsWatched', addAdResults.adsWatched)

						if (addAdResults.earnedReward) {
							// toast! + do we do anything else here ?
							/* Vue.toasted.success(state.currentSeasonAdRewards.friendlyName + " unlocked!", {
								position: "top-center",
								duration: 7500
							})

							commit('setEarnedReward')*/
							// lets skin get added to user's profile on website
							const redirectURL = process.env.GAME_WEBSITE_URL + "/vidad?earnedReward=true"
							location.replace(redirectURL)
							return

						} else {
							let toastMsg = addAdResults.gotCoins ? state.translations.coinsAcquired : state.translations.closerToReward
							Vue.toasted.success(toastMsg, {
								position: "top-center",
								duration: 7500
							})
						}

						// battlepass stuff
						if (addAdResults.missions) {
							for (let i = 0; i < addAdResults.missions.length; ++i) {
								addAdResults.missions[i].mission_id = addAdResults.missions[i].user_daily_mission_id || addAdResults.missions[i].user_weekly_mission_id
							}
							commit('user/updateAdMissions', addAdResults.missions, { root: true })
						}

					} else {
						this.router.push('/account-required?feature=vidad')
					}
				} catch (e) {
					console.error("Error occured when attempting to claim video ad watched", e)
					commit('setAdRewardsFetching', false)
					handleError(e)
				}
			},
		}
	}
}
