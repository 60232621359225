<template>
  <div class="screen" id="rankings">
    <Panel class="rankings-panel">
      <template #panel-content>
        <LoadyBro v-if="rankingsCurrentlyLoading"></LoadyBro>
        <div class="rankings-real" v-else>
          <div class="rankings-left">
            <div
              class="rankings-category-btn all-time"
              v-on:click="() => { changeActiveCategory('all-time') }"
              v-bind:class="{ active: activeCategory === 'all-time' }"
            >All Time</div>
            <div
              class="rankings-category-btn seasonal"
              v-on:click="() => { changeActiveCategory('seasonal') }"
              v-bind:class="{ active: activeCategory === 'seasonal' }"
            >Season {{ currentSeason }}</div>
            <div
              class="rankings-category-btn weekly"
              v-on:click="() => { changeActiveCategory('weekly') }"
              v-bind:class="{ active: activeCategory === 'weekly' }"
            >This Week</div>
          </div>
          <div class="rankings-right">
            <EmbeddedDarkBox class="darker">
              <template #dark-box-content>
                <h3>Leaderboards</h3>
              </template>
            </EmbeddedDarkBox>
            <EmbeddedDarkBox class="lighter rows-container">
              <template #dark-box-content>
                <div class="leaderboards-rows">
                  <div class="header">
                    <div class="cell users-rank">Rank</div>
                    <div class="cell users-name">Name</div>
                    <div class="cell users-wins">Wins</div>
                    <div class="cell users-kills">Kills</div>
                    <div class="cell users-plays">Plays</div>
                    <div class="cell users-win-percentage">Win %</div>
                    <div class="cell users-avg-kills">Avg. Kills</div>
                  </div>
                  <div v-if="globalTopFifty.length > 0 && activeCategory === 'all-time'">
                    <div class="rows" v-for="(user, index) in globalTopFifty" :key="user.username">
                      <div class="cell users-rank">{{index + 1}}</div>
                      <div class="cell users-name">{{user.username}}</div>
                      <div class="cell users-wins">{{user.wins}}</div>
                      <div class="cell users-kills">{{user.kills}}</div>
                      <div class="cell users-plays">{{user.plays}}</div>
                      <div class="cell users-win-percentage">{{user.winPercentage || 0}}</div>
                      <div class="cell users-avg-kills">{{user.avgKillsPerGame || 0}}</div>
                    </div>
                  </div>
                  <div v-if="seasonalTopFifty.length > 0 && activeCategory === 'seasonal'">
                    <div
                      class="rows"
                      v-for="(user, index) in seasonalTopFifty"
                      :key="user.username"
                    >
                      <div class="cell users-rank">{{index + 1}}</div>
                      <div class="cell users-name">{{user.username}}</div>
                      <div class="cell users-wins">{{user.wins}}</div>
                      <div class="cell users-kills">{{user.kills}}</div>
                      <div class="cell users-plays">{{user.plays}}</div>
                      <div class="cell users-win-percentage">{{user.winPercentage || 0}}</div>
                      <div class="cell users-avg-kills">{{user.avgKillsPerGame || 0}}</div>
                    </div>
                  </div>
                  <div v-if="weeklyTopFifty.length > 0 && activeCategory === 'weekly'">
                    <div class="rows" v-for="(user, index) in weeklyTopFifty" :key="user.username">
                      <div class="cell users-rank">{{index + 1}}</div>
                      <div class="cell users-name">{{user.username}}</div>
                      <div class="cell users-wins">{{user.wins}}</div>
                      <div class="cell users-kills">{{user.kills}}</div>
                      <div class="cell users-plays">{{user.plays}}</div>
                      <div class="cell users-win-percentage">{{user.winPercentage || 0}}</div>
                      <div class="cell users-avg-kills">{{user.avgKillsPerGame || 0}}</div>
                    </div>
                  </div>
                </div>
              </template>
            </EmbeddedDarkBox>
          </div>
        </div>
      </template>
    </Panel>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import Panel from "../reusable-components/Panel"
import Button from "../reusable-components/Button"
import SkinImage from "../reusable-components/SkinImage"
import EmbeddedDarkBox from "../reusable-components/EmbeddedDarkBox"
import LoadyBro from "../reusable-components/LoadyBro"
export default {
	name: "Rankings",
	components: {
		Panel,
		Button,
		EmbeddedDarkBox,
		LoadyBro,
		SkinImage
	},
	data() {
		return {
			activeCategory: "all-time"
		}
	},
	methods: {
    ...mapActions("rankings", ["getGlobalRankings", "getSeasonalRankings", "getWeeklyRankings"]),
		changeActiveCategory(newCategory) {
      if (newCategory === "all-time") {
        this.getGlobalRankings()
      } else if (newCategory === "seasonal") {
        this.getSeasonalRankings()
      } else if (newCategory === "weekly") {
        this.getWeeklyRankings()
      }
      this.activeCategory = newCategory
		}
	},
	computed: {
		...mapGetters("i18n", ["t"]),
		...mapGetters("rankings", [
			"globalTopFifty",
			"seasonalTopFifty",
			"weeklyTopFifty",
			"rankingsCurrentlyLoading",
			"currentSeason"
		]),
		...mapGetters("user", [
			"userType",
			"username",
			"walletBalance",
			"accountVerified",
			"selectedCharacterSkin",
			"selectedWeaponSkin",
			"emailAddress",
			"stats"
		])
	}
}
</script>

<style lang="scss">
@media only screen and (max-width: 900px) {
	#rankings {
		.rankings-panel {
			transform: scale(0.75);
		}
	}
}
@media only screen and (max-width: 720px) {
	#rankings {
		.rankings-panel {
			transform: scale(0.50);
		}
	}
}

#rankings {
	.rankings-panel {
		display: flex;
		min-width: 722px;
		min-height: 300px;
		position: relative;

		.loady-bro {
			position: absolute;
			top: 80%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		.rankings-real {
			display: flex;
			flex-direction: row;
			flex-shrink: 0;
			width: 100%;
		}

		.rankings-left {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			flex: 20;
			margin-right: 10px;

			.rankings-category-btn {
				text-align: center;
				font-size: 18px;
				margin: 5px;
				border-radius: 5px;
				text-transform: uppercase;
				cursor: pointer;
				padding: 10px 20px;
				width: 150px;
				background-color: #7a7f85;
				border: 2px solid #9b9ea5;

				&:hover {
					// opacity: 0.9;
					transform: scale(1.05);
				}

				&.active {
					background-color: #ee9000;
					border: 2px solid #ffa443;
				}
			}
		}
		.rankings-right {
			flex: 80;

			h3 {
				text-align: center;
				text-transform: uppercase;
				color: #ffa800;
				font-size: 36px;
			}

			.rows-container {
				max-height: 300px;
				overflow: hidden;
			}

			.leaderboards-rows {
				display: flex;
				flex-direction: column;
				overflow-y: auto;
				overflow-x: hidden;
				max-height: 265px;

				&::-webkit-scrollbar {
					width: 8px;
					border-radius: 5px;
					background-color: #382f34;
				}

				&::-webkit-scrollbar-thumb {
					background-color: #9a9a9a;
				}

				.header,
				.rows {
					flex-shrink: 0;
					justify-content: space-between;
					display: flex;
					flex-direction: row;
					width: 100%;

					.cell {
						// text-align: center;
						// justify-content: center;
						display: flex;
						flex: 1;
						white-space: nowrap;

						&.users-rank {
							flex: 1;
						}
						&.users-name {
							flex: 3;
						}
						&.users-wins {
							justify-content: center;
							flex: 1;
						}
						&.users-kills {
							justify-content: center;
							flex: 1;
						}
						&.users-plays {
							justify-content: center;
							flex: 1;
						}
						&.users-win-percentage {
							justify-content: center;
							flex: 1;
						}
						&.users-avg-kills {
							justify-content: center;
							flex: 1;
						}

						&:first-child {
							margin-left: 10px;
						}

						&:last-child {
							margin-right: 10px;
						}
					}
				}

				.header {
					margin-bottom: 5px;
					.cell {
						font-size: 12px;
					}
				}

				.rows {
					margin-bottom: 10px;
				}
			}
		}
	}
}
</style>

