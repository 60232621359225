<template>
  <div class="screen" id="four-of-four">
    <Panel class="four-oh-four-panel" :title="t('notFoundPageTitle')">
      <template #panel-content>
        <p>
          We couldn't find the page you were looking for, If you think this is an error reach out to us on
          <a href="https://discord.gg/bitfoxgames" target="_blank" rel="noreferrer noopener">Discord</a> to let us know!
        </p>
      </template>
    </Panel>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Panel from "../reusable-components/Panel"
export default {
	name: "NotFound",
	components: {
		Panel
	},
	computed: {
		...mapGetters("i18n", ["t"])
	}
}
</script>


<style lang="scss">
#four-of-four {
	.four-oh-four-panel {
		p {
			width: 500px;
			text-align: center;
		}
	}
}
</style>

