<template>
  <div class="screen" id="unsubscribe">
    <Panel
      class="unsubscribe-panel"
      v-bind:class="{ loading: userDataFetching }"
    >
      <template #panel-content>
        <LoadyBro v-if="userDataFetching"></LoadyBro>
        <div v-else class="forms">
          <h2>Unsubscribe Successful!</h2>
          <p class="thanks">Thanks</p>
        </div>
      </template>
    </Panel>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Panel from "../reusable-components/Panel"
import LoadyBro from "../reusable-components/LoadyBro"

export default {
  name: "Unsubscribe",
  components: {
    Panel,
    LoadyBro
  },
  computed: {
    ...mapGetters("user", ["userDataFetching"])
  }
}
</script>

<style lang="scss">
#unsubscribe {
  .unsubscribe-panel {
    position: relative;

    &.loading {
      width: 840px;
      height: 559px;
    }

    .loady-bro {
      position: absolute;
      top: 65%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .forms {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }
}
</style>
