<template>
  <div class="app-container">
    <div id="left" class="column">
      <Navigation />
    </div>
    <div id="right" class="column">
      <div class="fs-error-modal" v-if="errorModalCurrentlyVisible">
        <Panel class="error-panel" :onclosefn="toggleTheErrorModal">
          <template #panel-content
            >Uh oh! {{ currentErrorCode }}</template
          >
        </Panel>
      </div>
      <StatusBar></StatusBar>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
Error.stackTraceLimit = 50

import { mapGetters, mapActions } from "vuex"
import Navigation from "./components/navigation"
import StatusBar from "./components/StatusBar"
import Panel from "./reusable-components/Panel"

export default {
  name: "App",
  components: {
    Navigation,
    StatusBar,
    Panel
  },
  computed: {
    ...mapGetters("error", ["currentErrorCode", "errorModalCurrentlyVisible"])
  },
  methods: {
    ...mapActions("error", ["toggleErrorModal"]),
    toggleTheErrorModal() {
      this.toggleErrorModal()
    }
  }
}
</script>

<style lang="scss">
@import "./src/assets/fonts.scss";

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

* {
  font-family: "Russo One", "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  user-select: none;

  select {
    user-select: auto;
  }

  input {
    user-select: text;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
}

@keyframes toast-glowing {
  0% {
    box-shadow: 0 0 -10px #f9a901;
  }
  40% {
    box-shadow: 0 0 20px #f9a901;
  }
  60% {
    box-shadow: 0 0 20px #f9a901;
  }
  100% {
    box-shadow: 0 0 -10px #f9a901;
  }
}

.toasted-container {
  .toasted.toasted-primary {
    padding: 15px 20px !important;
    border-image: url("./assets/bruh-dark-panel.png") round;
    border-image-slice: 14 fill;
    border-image-width: 14px;
    animation: glowing 2500ms infinite;
  }
}

.fs-error-modal {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50000;

  .error-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 200px;
  }
}

.app-container {
  height: 100%;
  overflow: hidden;
  display: flex;
  // min-height: 768px;
  // min-width: 1024px;
}

.column {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#left {
  flex-shrink: 0;
  min-width: 120px;
}

#right {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
    url("./assets/bg.png");
  background-size: cover;
}

.screen {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
</style>
