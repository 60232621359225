const { partial } = require("lodash")

const logLevels = {
	none: 5,
	debug: 4,
	info: 3,
	warn: 2,
	error: 1
}

const logLevelPrefixes = {
	debug: "%c [DEBUG] ",
	info: "%c [INFO] ",
	warn: "%c [WARN] ",
	error: "%c [ERROR] "
}
const logLevelCss = {
	debug: "",
	info: "color: blue;",
	warn: "color: orange;",
	error: "color: red;"
}

const log = (messageLogLevel, data) => {
	const messageLogLevelNumber = logLevels[messageLogLevel]
	const messageLogLevelPrefix = logLevelPrefixes[messageLogLevel]
	const limitLogLevelNumber = logLevels[process.env.LOG_LEVEL]
	const messageLogLevelColor = logLevelCss[messageLogLevel]

	if (limitLogLevelNumber === 5) return
	if (messageLogLevelNumber > limitLogLevelNumber) return

	console.log(messageLogLevelPrefix + data, messageLogLevelColor)
}

export default {
	debug: partial(log, "debug"),
	info: partial(log, "info"),
	warn: partial(log, "warn"),
	error: partial(log, "error")
}
