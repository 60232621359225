<template>
  <button v-bind:class="{ 'checkbox': true, 'on': checked }" v-on:click="onCheckboxClick()">
    <SkinImage :skin-data="{ skinCode: `check_icon` }" :skin-image-type="'sprite'"></SkinImage>
  </button>
</template>

<script>
import SkinImage from "./SkinImage"

export default {
	name: "Checkbox",
	components: {
		SkinImage
	},
	props: ["checked", "onclickfn"],
	methods: {
		onCheckboxClick() {
			this.onclickfn()
		}
	}
}
</script>

<style lang="scss">
.checkbox {
	background-color: #39363d;
	border: none;
	border-radius: 2px;
	border: 1px solid #262429;
	height: 25px;
	width: 25px;
	outline: none;
	cursor: pointer;

	.sprite-skin-image {
		display: none;
	}
	&.on {
		.sprite-skin-image {
			display: block;
			position: relative;
			left: 6px;
			top: -3px;
			transform: scale(2.5);
		}
	}
}
</style>
