<template>
  <div class="screen" id="vidad">
    <Panel class="vidad-panel" :title="t('vidAdTitle')">
      <template #panel-content>
        <div class="loading" v-if="shopFetching || adRewardsIsLoading">
          <LoadyBro></LoadyBro>
        </div>
        <div v-else>
          <div class="info-text" v-if="!hasAdReward || earnedCurrentReward">
            <p class="center">
              {{ t("adCoinRewardIntro") }} 3 {{ t("adCoinRewardEnd") }}
            </p>
          </div>
          <div v-else>
            <p class="info-text">
              {{ t("adRewardIntro") }} {{ adsRequired }}
              {{ t("adRewardMiddle") }} {{ currentSeasonAdRewards.endDate }}
              {{ t("adRewardEnding") }} {{ friendlyName }}!
            </p>
            <div class="center-div">
              <div
                class="reward-box skin-reward"
                v-bind:class="{ locked: true }"
              >
                <div class="locked-overlay">
                  <img src="../assets/locked_icon.png" />
                </div>
                <div class="reward-box-inner">
                  <SkinImage
                    :class="{
                      'weapon-skin-wrapper': skinType === 'weapon_set_preview'
                    }"
                    :key="rewardId"
                    :skin-data="{ skinCode: rewardId }"
                    :skin-image-type="skinType"
                  >
                  </SkinImage>
                  <p class="info-text">{{ friendlyName }}</p>
                </div>
              </div>
              <div class="info-text">{{ numAdsWatched }}/{{ adsRequired }}</div>
            </div>
          </div>

          <div>
            <div class="info-text" v-if="!adWatchable">
              <p class="center">{{ t("adCountdown") }} {{ getTimeString }}</p>
            </div>

            <div class="button-row">
              <Button
                :color="'green'"
                v-if="adWatchable && !isBlockingAds"
                :onclickfn="
                  () => {
                    showAd()
                  }
                "
                :text="t('playAd')"
              >
              </Button>
            </div>
          </div>

          <div class="info-text">
            <p v-if="isBlockingAds">{{ t("disableAdBlockReminder") }}</p>
          </div>
        </div>
      </template>
    </Panel>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import Panel from "../reusable-components/Panel"
import Button from "../reusable-components/Button"
import LoadyBro from "../reusable-components/LoadyBro"
import SkinImage from "../reusable-components/SkinImage"
import moment from "moment"

export default {
  name: "VidAd",
  components: {
    Panel,
    Button,
    LoadyBro,
    SkinImage
  },
  data() {
    return {
      getTimeString: "00:00:00",
      nowTime: null,
      adWatchable: false
    }
  },
  computed: {
    ...mapGetters("i18n", ["t"]),
    ...mapGetters("user", ["username", "userDataFetching", "userType"]),
    ...mapGetters("shop", ["shopFetching", "forteSystemDown"]),
    ...mapGetters("vidad", [
      "adRewardsIsLoading",
      "currentSeasonAdRewards",
      "adRewardsIsLoading",
      "rewardId",
      "numAdsWatched",
      "timeToNextAd",
      "hasAdReward",
      "adsRequired",
      "friendlyName",
      "skinType",
      "earnedCurrentReward",
      "isBlockingAds"
    ])
  },
  methods: {
    ...mapActions("vidad", ["videoAdWatched"]),
    showAd() {
      try {
        if (process.env.NODE_ENV === "production") {
          if (typeof window.adplayer == "undefined") {
            console.log("setting adplayer")

            let watchCallback = this.videoAdWatched

            window.adplayer = new aipPlayer({
              AIP_REWARDEDCOMPLETE: function(evt) {
                console.log("Rewarded ad complete: " + evt)

                if (
                  ((process.env.NODE_ENV === "production" ||
                    process.env.NODE_ENV === "qa") &&
                    evt === "granted") ||
                  process.env.NODE_ENV === "local"
                ) {
                  this.watchedCallback()
                }
                // else {
                //cancel or blocked
                //}
              },
              AD_WIDTH: 960,
              AD_HEIGHT: 540,
              AD_FULLSCREEN: false,
              AD_CENTERPLAYER: true,
              LOADING_TEXT: "loading advertisement",
              PREROLL_ELEM: function() {
                return document.getElementById("preroll")
              },
              AIP_COMPLETE: function() {},
              AIP_REMOVE: function() {},
              watchedCallback: watchCallback
            })
          }

          aiptag.cmd.player.push(function() {
            window.adplayer.startRewardedAd()
          })
        } else {
          this.videoAdWatched()
        }
      } catch (e) {
        console.log("bruh vidad // " + e.message + ": " + e.stack)
      }
      //} else {
      // blocked or timeout
      //  console.error('Rewarded Ad could not be loaded');
      //}
    },
    pad(num) {
      return ("0" + num).substr(-2)
    }
  },
  watch: {
    nowTime: {
      handler(value) {
        let now = this.nowTime
        if (this.nowTime == null) {
          now = moment()
        }
        if (this.timeToNextAd == null || now.isAfter(this.timeToNextAd)) {
          this.getTimeString = "00:00:00"

          setTimeout(() => (this.nowTime = moment()), 100)

          if (this.timeToNextAd == null && this.userType === "registered") {
            this.adWatchable = false
          } else {
            this.adWatchable = true
          }
          return
        }

        let diff = moment.duration(this.timeToNextAd.diff(now))
        this.getTimeString =
          this.pad(diff.hours()) +
          ":" +
          this.pad(diff.minutes()) +
          ":" +
          this.pad(diff.seconds())

        setTimeout(() => (this.nowTime = moment()), 1000)

        this.adWatchable = false
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
#vidad {
  .vidad-panel {
    max-width: 1100px;
    min-width: 550px;
    flex-direction: column;
    padding-bottom: 20px;

    .loading {
      width: 500px;
      height: 300px;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .loady-bro {
        position: absolute;
        top: 65%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .button-row {
      display: flex;
      margin-top: 10px;
      justify-content: center;
      .button {
        width: 150px;
      }
    }
  }

  .info-text {
    flex: 1;
    left: 50%;
    text-align: center;
    font-size: 120%;

    ul {
      padding-left: 30px;
    }
  }

  .center-div {
    margin: 0 auto;
    width: 20%;
  }

  .rewards-runner {
    flex: 1;
    display: flex;
    min-height: 236px;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 5px;
      background-color: #382f34;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #9a9a9a;
    }
  }

  .reward-box {
    height: 220px;
    width: 120px;
    display: flex;
    flex-direction: column;
    position: relative;

    .weapon-skin-wrapper {
      transform: scale(0.88) translateY(23px) !important;
    }

    .locked-overlay {
      z-index: 999;
      display: none;
      position: absolute;
      top: 35px;
      right: 0;
      bottom: 0;
      margin: 5px;
      left: 0;
      border: 2px solid rgba(0, 0, 0, 0.7);
      background-color: rgba(0, 0, 0, 0.7);
      justify-content: center;
      align-items: center;
      img {
        height: 50px;
      }
    }

    &.locked {
      .locked-overlay {
        display: flex;
      }
    }

    .reward-box-inner {
      position: absolute;
      top: 35px;
      right: 0;
      bottom: 0;
      left: 0;
      border: 2px solid #1c1c1c;
      background-color: #ffbe58;
      margin: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .skin-image-wrapper {
        pointer-events: none;
        transform: scale(3);
      }

      img {
        height: 40px;
        margin-bottom: 10px;
      }

      .skin-name {
        padding: 0;
        margin: 15px 0 0 0;
        font-size: 16px;
        position: relative;
        bottom: 12px;
        text-align: center;
      }

      .coin-amount {
        padding: 0;
        margin: 15px 0 0 0;
        font-size: 20px;
      }
    }

    .reward-box-level-title {
      background-color: #505051;
      text-align: center;
      font-size: 22px;
    }

    &.skin-reward {
    }

    &.coin-reward {
    }
  }
}
</style>
