let prefixes = [
	"Deep",
	"Same",
	"Cool",
	"Loud",
	"Wise",
	"Pure",
	"Last",
	"Epic",
	"Half",
	"Bold",
	"Calm",
	"Warm",
	"Holy",
	"Wide",
	"Slow",
	"Sane",
	"Peak",
	"Plus",
	"Salt",
	"Glad",
	"Weak",
	"Male",
	"Busy",
	"Rude",
	"Sour",
	"Foul",
	"Idle",
	"Deaf",
	"Tidy",
	"Poor",
	"Dull",
	"Near",
	"Able",
	"Rich",
	"Live",
	"Safe",
	"Hard",
	"High",
	"Long",
	"Open",
	"Evil",
	"Dark",
	"Good",
	"Sick",
	"Best",
	"Lost",
	"Free",
	"Thin",
	"Soft",
	"Full",
	"Ugly",
	"True",
	"Nice",
	"Cold",
	"Fair",
	"Fast",
	"Real",
	"Fine",
	"Mini",
	"Dead",
	"Wild",
	"Tall",
	"Easy",
	"Kind",
	"Lean",
	"bad",
	"big",
	"coy",
	"dim",
	"dry",
	"dud",
	"fed",
	"fit",
	"fun",
	"hot",
	"icy",
	"ill",
	"lax",
	"mad",
	"new",
	"odd",
	"off",
	"old",
	"raw",
	"red",
	"sad",
	"sly",
	"wet",
	"angry",
	"antsy",
	"artsy",
	"beefy",
	"bendy",
	"bossy",
	"buddy",
	"buggy",
	"bumpy",
	"burly",
	"catty",
	"chewy",
	"comfy",
	"corny",
	"crazy",
	"curly",
	"curvy",
	"dandy",
	"dirty",
	"ditsy",
	"dizzy",
	"dodgy",
	"doggy",
	"dopey",
	"dorky",
	"dusty",
	"empty",
	"enemy",
	"fairy",
	"fancy",
	"fiery",
	"fishy",
	"flaky",
	"foamy",
	"funky",
	"funny",
	"fussy",
	"fuzzy",
	"geeky",
	"germy",
	"goofy",
	"goopy",
	"gutsy",
	"hairy",
	"happy",
	"heavy",
	"hippy",
	"huggy",
	"hunky",
	"itchy",
	"jazzy",
	"jerky",
	"jolly",
	"juicy",
	"jumpy",
	"lanky",
	"leafy",
	"leaky",
	"lippy",
	"loony",
	"lucky",
	"lumpy",
	"manly",
	"meany",
	"meaty",
	"merry",
	"messy",
	"milky",
	"misty",
	"moody",
	"mopey",
	"mossy",
	"mucky",
	"muddy",
	"muggy",
	"mummy",
	"murky",
	"mushy",
	"musky",
	"musty",
	"nerdy",
	"nifty",
	"noisy",
	"nosey",
	"nutty",
	"party",
	"pasty",
	"peppy",
	"perky",
	"pesky",
	"petty",
	"phony",
	"picky",
	"piggy",
	"porky",
	"pouty",
	"pricy",
	"pudgy",
	"puffy",
	"punny",
	"puppy",
	"raspy",
	"ready",
	"risky",
	"rowdy",
	"rusty",
	"salty",
	"sandy",
	"sappy",
	"sassy",
	"saucy",
	"savvy",
	"scaly",
	"scary",
	"shady",
	"shaky",
	"shiny",
	"silky",
	"silly",
	"slimy",
	"smily",
	"smoky",
	"snaky",
	"snowy",
	"soapy",
	"soupy",
	"spicy",
	"spiky",
	"spiny",
	"sulky",
	"surly",
	"tacky",
	"tangy",
	"tardy",
	"tasty",
	"teeny",
	"tipsy",
	"tubby",
	"wacky",
	"wavey",
	"weary",
	"weepy",
	"whiny",
	"wimpy",
	"windy",
	"wishy",
	"wispy",
	"witty",
	"wonky",
	"wooly",
	"woozy",
	"wordy",
	"wormy",
	"yucky",
	"yummy",
	"zesty",
	"zippy"
]

let suffixes = [
	"goose",
	"puppy",
	"zebra",
	"horse",
	"camel",
	"mouse",
	"sheep",
	"moose",
	"rhino",
	"snail",
	"koala",
	"otter",
	"hippo",
	"doggo",
	"bison",
	"sloth",
	"llama",
	"macaw",
	"tiger",
	"hyena",
	"gecko",
	"bongo",
	"tapir",
	"tabby",
	"whelp",
	"skunk",
	"shrew",
	"panda",
	"dingo",
	"pengu",
	"ape",
	"bat",
	"cat",
	"cow",
	"cub",
	"doe",
	"dog",
	"elk",
	"fox",
	"hog",
	"kid",
	"man",
	"orc",
	"pig",
	"pup",
	"ram",
	"rat",
	"sow",
	"wat",
	"yak",
	"bear",
	"boar",
	"buck",
	"bull",
	"calf",
	"colt",
	"deer",
	"fawn",
	"foal",
	"goat",
	"hare",
	"joey",
	"lamb",
	"lion",
	"lynx",
	"maki",
	"mare",
	"mink",
	"mole",
	"mule",
	"musk",
	"orca",
	"oryx",
	"oxen",
	"puma",
	"runt",
	"seal",
	"urus",
	"wolf",
	"alpaca",
	"baboon",
	"badger",
	"beaver",
	"beluga",
	"bobcat",
	"cougar",
	"coyote",
	"donkey",
	"fennec",
	"ferret",
	"fisher",
	"gerbil",
	"gibbon",
	"gopher",
	"howler",
	"impala",
	"jackal",
	"jaguar",
	"kodiak",
	"mammal",
	"marmot",
	"monkey",
	"ocelot",
	"porker",
	"possum",
	"quagga",
	"rabbit",
	"racoon",
	"rodent",
	"simian",
	"tomcat",
	"turtle",
	"vermin",
	"walrus",
	"weasel",
	"wombat"
]

function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

export const chooseRandomName = () => {
	let prefix = capitalizeFirstLetter(
		prefixes[Math.floor(Math.random() * prefixes.length)]
	)
	let suffix = capitalizeFirstLetter(
		suffixes[Math.floor(Math.random() * suffixes.length)]
	)
	let randomName = prefix + suffix
	return randomName
}
