<template>
	<!-- Character Image -->
	<div
		class="skin-image-wrapper"
		v-if="skinImageType === 'character'"
		:style="{
		'height': '100%',
		'width': '100%'
		}"
	>
		<span
			class="character-skin-image"
			:style="{
		'background-position':`-${left}px -${top}px`,
		'width': `${width}px`,
		'height': `${height}px`
	}"
		></span>
	</div>

	<!-- Battlepass Image -->
	<div
		class="skin-image-wrapper"
		v-else-if="skinImageType === 'battlepass'"
		:style="{
			'height': '100%',
			'width': '100%'
		}"
		>
		<span
			class="battlepass-skin-image"
			:style="{
				'background-position':`${left}px ${top}px`,
				'width': `${width}px`,
				'height': `${height}px`
			}"
		></span>
	</div>

	<!-- Sprite Image -->
	<div
		class="skin-image-wrapper"
		v-else-if="skinImageType === 'sprite'"
		:style="{
		'height': '100%',
		'width': '100%'
		}"
	>
		<span
			class="sprite-skin-image"
			:style="{
		'background-position':`-${left}px -${top}px`,
		'width': `${width}px`,
		'height': `${height}px`
	}"
		></span>
	</div>

	<!-- Weapon Set Preview Image -->
	<div
		class="skin-image-wrapper"
		v-else-if="skinImageType === 'weapon_set_preview'"
		:style="{
		'height': '100%',
		'width': '100%'
		}"
	>
		<span
			class="weapon-skin-image bmg"
			:style="{
		'background-position':`-${bmg.left}px -${bmg.top}px`,
		'display': 'block',
		'width': `${bmg.width}px`,
		'height': `${bmg.height}px`
	}"
		></span>
		<span
			class="weapon-skin-image m16"
			:style="{
		'background-position':`-${m16.left}px -${m16.top}px`,
		'display': 'block',
		'width': `${m16.width}px`,
		'height': `${m16.height}px`
	}"
		></span>
		<span
			class="weapon-skin-image double-eagle"
			:style="{
		'background-position':`-${doubleEagle.left}px -${doubleEagle.top}px`,
		'display': 'block',
		'width': `${doubleEagle.width}px`,
		'height': `${doubleEagle.height}px`
	}"
		></span>
	</div>

	<!-- FULL Weapon Set Image -->
	<div
		class="skin-image-wrapper full-set"
		v-else-if="skinImageType === 'weapon_set'"
		:style="{
		'height': '100%',
		'width': '100%'
		}"
	>
		<span
			class="weapon-skin-image bmg"
			:style="{
		'background-position':`-${bmg.left}px -${bmg.top}px`,
		'display': 'block',
		'width': `${bmg.width}px`,
		'height': `${bmg.height}px`
	}"
		></span>
		<span
			class="weapon-skin-image awm"
			:style="{
		'background-position':`-${awm.left}px -${awm.top}px`,
		'display': 'block',
		'width': `${awm.width}px`,
		'height': `${awm.height}px`
	}"
		></span>
		<span
			class="weapon-skin-image dragunov"
			:style="{
		'background-position':`-${dragunov.left}px -${dragunov.top}px`,
		'display': 'block',
		'width': `${dragunov.width}px`,
		'height': `${dragunov.height}px`
	}"
		></span>
		<span
			class="weapon-skin-image m249"
			:style="{
		'background-position':`-${m249.left}px -${m249.top}px`,
		'display': 'block',
		'width': `${m249.width}px`,
		'height': `${m249.height}px`
	}"
		></span>
		<span
			class="weapon-skin-image m16"
			:style="{
		'background-position':`-${m16.left}px -${m16.top}px`,
		'display': 'block',
		'width': `${m16.width}px`,
		'height': `${m16.height}px`
	}"
		></span>
		<span
			class="weapon-skin-image ak47"
			:style="{
		'background-position':`-${ak47.left}px -${ak47.top}px`,
		'display': 'block',
		'width': `${ak47.width}px`,
		'height': `${ak47.height}px`
	}"
		></span>
		<span
			class="weapon-skin-image qbz95"
			:style="{
		'background-position':`-${qbz95.left}px -${qbz95.top}px`,
		'display': 'block',
		'width': `${qbz95.width}px`,
		'height': `${qbz95.height}px`
	}"
		></span>
		<span
			class="weapon-skin-image ump45"
			:style="{
		'background-position':`-${ump45.left}px -${ump45.top}px`,
		'display': 'block',
		'width': `${ump45.width}px`,
		'height': `${ump45.height}px`
	}"
		></span>
		<span
			class="weapon-skin-image mk2"
			:style="{
		'background-position':`-${mk2.left}px -${mk2.top}px`,
		'display': 'block',
		'width': `${mk2.width}px`,
		'height': `${mk2.height}px`
	}"
		></span>
		<span
			class="weapon-skin-image colt45"
			:style="{
		'background-position':`-${colt45.left}px -${colt45.top}px`,
		'display': 'block',
		'width': `${colt45.width}px`,
		'height': `${colt45.height}px`
	}"
		></span>
		<span
			class="weapon-skin-image glock"
			:style="{
		'background-position':`-${glock.left}px -${glock.top}px`,
		'display': 'block',
		'width': `${glock.width}px`,
		'height': `${glock.height}px`
	}"
		></span>
		<span
			class="weapon-skin-image p1911"
			:style="{
		'background-position':`-${p1911.left}px -${p1911.top}px`,
		'display': 'block',
		'width': `${p1911.width}px`,
		'height': `${p1911.height}px`
	}"
		></span>
		<span
			class="weapon-skin-image ppbizon"
			:style="{
		'background-position':`-${ppbizon.left}px -${ppbizon.top}px`,
		'display': 'block',
		'width': `${ppbizon.width}px`,
		'height': `${ppbizon.height}px`
	}"
		></span>
		<span
			class="weapon-skin-image s686"
			:style="{
		'background-position':`-${s686.left}px -${s686.top}px`,
		'display': 'block',
		'width': `${s686.width}px`,
		'height': `${s686.height}px`
	}"
		></span>
		<span
			class="weapon-skin-image sbs"
			:style="{
		'background-position':`-${sbs.left}px -${sbs.top}px`,
		'display': 'block',
		'width': `${sbs.width}px`,
		'height': `${sbs.height}px`
	}"
		></span>
		<span
			class="weapon-skin-image double-eagle"
			:style="{
		'background-position':`-${doubleEagle.left}px -${doubleEagle.top}px`,
		'display': 'block',
		'width': `${doubleEagle.width}px`,
		'height': `${doubleEagle.height}px`
	}"
		></span>
	</div>
</template>

<script>
import spriteSheetJson from "../assets/spritesheet.json"
import spriteSheetImage from "../assets/spritesheet.png"

export default {
	name: "SkinImage",
	data: function() {
		const { skinCode } = this.skinData
		if (this.skinImageType === "weapon_set_preview") {
			let bmgSkinFrameMetadata
			let m16SkinFrameMetadata
			let doubleEagleSkinFrameMetadata
			
			try {
				bmgSkinFrameMetadata =
					spriteSheetJson.frames[`bmg_${skinCode}.png`].frame
				m16SkinFrameMetadata =
					spriteSheetJson.frames[`m16_${skinCode}.png`].frame
				doubleEagleSkinFrameMetadata =
					spriteSheetJson.frames[`dbleagle_${skinCode}.png`].frame
			} catch(e) {
				throw `Sprite 'bmg_${skinCode}.png' not found in spritesheet`
			}

			return {
				bmg: {
					left: bmgSkinFrameMetadata.x,
					top: bmgSkinFrameMetadata.y,
					width: bmgSkinFrameMetadata.w,
					height: bmgSkinFrameMetadata.h
				},
				m16: {
					left: m16SkinFrameMetadata.x,
					top: m16SkinFrameMetadata.y,
					width: m16SkinFrameMetadata.w,
					height: m16SkinFrameMetadata.h
				},
				doubleEagle: {
					left: doubleEagleSkinFrameMetadata.x,
					top: doubleEagleSkinFrameMetadata.y,
					width: doubleEagleSkinFrameMetadata.w,
					height: doubleEagleSkinFrameMetadata.h
				}
			}
		} else if (this.skinImageType === "battlepass") {
			return {
				left: 4,
				top: 7,
				width: 131,
				height: 99,
			}
		} else if (this.skinImageType === "sprite") {
			const skinFrameMetadata = spriteSheetJson.frames[`${skinCode}.png`]
			if (skinFrameMetadata === undefined) throw `Sprite '${skinCode}.png' not found in spritesheet`

			const { x: left, y: top, w: width, h: height } = skinFrameMetadata.frame

			return { left, top, width, height }
		} else if (this.skinImageType === "character") {
			const skinFrameMetadata = spriteSheetJson.frames[`${skinCode}_idle_0.png`]
			if (skinFrameMetadata === undefined) throw `Sprite '${skinCode}_idle_0.png' not found in spritesheet`

			const { x: left, y: top, w: width, h: height } = skinFrameMetadata.frame

			return { left, top, width, height }
		} else if (this.skinImageType === "weapon_set") {
			const bmgSkinFrameMetadata =
				spriteSheetJson.frames[`bmg_${skinCode}.png`].frame
			const m16SkinFrameMetadata =
				spriteSheetJson.frames[`m16_${skinCode}.png`].frame
			const doubleEagleSkinFrameMetadata =
				spriteSheetJson.frames[`dbleagle_${skinCode}.png`].frame
			const ak47SkinFrameMetadata =
				spriteSheetJson.frames[`ak47_${skinCode}.png`].frame
			const awmSkinFrameMetadata =
				spriteSheetJson.frames[`awm_${skinCode}.png`].frame
			const colt45SkinFrameMetadata =
				spriteSheetJson.frames[`colt45_${skinCode}.png`].frame
			const dragunovSkinFrameMetadata =
				spriteSheetJson.frames[`dragunov_${skinCode}.png`].frame
			const glockSkinFrameMetadata =
				spriteSheetJson.frames[`glock_${skinCode}.png`].frame
			const m249SkinFrameMetadata =
				spriteSheetJson.frames[`m249_${skinCode}.png`].frame
			const mk2SkinFrameMetadata =
				spriteSheetJson.frames[`mk2_${skinCode}.png`].frame
			const p1911SkinFrameMetadata =
				spriteSheetJson.frames[`p1911_${skinCode}.png`].frame
			const ppbizonSkinFrameMetadata =
				spriteSheetJson.frames[`ppbizon_${skinCode}.png`].frame
			const qbz95SkinFrameMetadata =
				spriteSheetJson.frames[`qbz95_${skinCode}.png`].frame
			const s686SkinFrameMetadata =
				spriteSheetJson.frames[`s686_${skinCode}.png`].frame
			const sbsSkinFrameMetadata =
				spriteSheetJson.frames[`sbs_${skinCode}.png`].frame
			const ump45SkinFrameMetadata =
				spriteSheetJson.frames[`ump45_${skinCode}.png`].frame

			if (bmgSkinFrameMetadata === undefined) throw `Sprite 'bmg_${skinCode}.png' not found in spritesheet`

			return {
				bmg: {
					left: bmgSkinFrameMetadata.x,
					top: bmgSkinFrameMetadata.y,
					width: bmgSkinFrameMetadata.w,
					height: bmgSkinFrameMetadata.h
				},
				m16: {
					left: m16SkinFrameMetadata.x,
					top: m16SkinFrameMetadata.y,
					width: m16SkinFrameMetadata.w,
					height: m16SkinFrameMetadata.h
				},
				doubleEagle: {
					left: doubleEagleSkinFrameMetadata.x,
					top: doubleEagleSkinFrameMetadata.y,
					width: doubleEagleSkinFrameMetadata.w,
					height: doubleEagleSkinFrameMetadata.h
				},
				ak47: {
					left: ak47SkinFrameMetadata.x,
					top: ak47SkinFrameMetadata.y,
					width: ak47SkinFrameMetadata.w,
					height: ak47SkinFrameMetadata.h
				},
				awm: {
					left: awmSkinFrameMetadata.x,
					top: awmSkinFrameMetadata.y,
					width: awmSkinFrameMetadata.w,
					height: awmSkinFrameMetadata.h
				},
				colt45: {
					left: colt45SkinFrameMetadata.x,
					top: colt45SkinFrameMetadata.y,
					width: colt45SkinFrameMetadata.w,
					height: colt45SkinFrameMetadata.h
				},
				dragunov: {
					left: dragunovSkinFrameMetadata.x,
					top: dragunovSkinFrameMetadata.y,
					width: dragunovSkinFrameMetadata.w,
					height: dragunovSkinFrameMetadata.h
				},
				glock: {
					left: glockSkinFrameMetadata.x,
					top: glockSkinFrameMetadata.y,
					width: glockSkinFrameMetadata.w,
					height: glockSkinFrameMetadata.h
				},
				m249: {
					left: m249SkinFrameMetadata.x,
					top: m249SkinFrameMetadata.y,
					width: m249SkinFrameMetadata.w,
					height: m249SkinFrameMetadata.h
				},
				mk2: {
					left: mk2SkinFrameMetadata.x,
					top: mk2SkinFrameMetadata.y,
					width: mk2SkinFrameMetadata.w,
					height: mk2SkinFrameMetadata.h
				},
				p1911: {
					left: p1911SkinFrameMetadata.x,
					top: p1911SkinFrameMetadata.y,
					width: p1911SkinFrameMetadata.w,
					height: p1911SkinFrameMetadata.h
				},
				ppbizon: {
					left: ppbizonSkinFrameMetadata.x,
					top: ppbizonSkinFrameMetadata.y,
					width: ppbizonSkinFrameMetadata.w,
					height: ppbizonSkinFrameMetadata.h
				},
				qbz95: {
					left: qbz95SkinFrameMetadata.x,
					top: qbz95SkinFrameMetadata.y,
					width: qbz95SkinFrameMetadata.w,
					height: qbz95SkinFrameMetadata.h
				},
				s686: {
					left: s686SkinFrameMetadata.x,
					top: s686SkinFrameMetadata.y,
					width: s686SkinFrameMetadata.w,
					height: s686SkinFrameMetadata.h
				},
				sbs: {
					left: sbsSkinFrameMetadata.x,
					top: sbsSkinFrameMetadata.y,
					width: sbsSkinFrameMetadata.w,
					height: sbsSkinFrameMetadata.h
				},
				ump45: {
					left: ump45SkinFrameMetadata.x,
					top: ump45SkinFrameMetadata.y,
					width: ump45SkinFrameMetadata.w,
					height: ump45SkinFrameMetadata.h
				}
			}
		}
	},
	props: ["skinData", "skinImageType"]
}
</script>

<style lang="scss" scoped>
span.character-skin-image, span.sprite-skin-image {
	background-image: url("../assets/spritesheet.png");
}
span.battlepass-skin-image {
	background-image: url("../assets/battlepass.png");
}

.skin-image-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;

	&.full-set {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-column-gap: 60px;
		grid-row-gap: 15px;

		span.weapon-skin-image {
			&.bmg,
			&.awm,
			&.dragunov,
			&.m249,
			&.m16,
			&.ak47,
			&.qbz95,
			&.ump45,
			&.mk2,
			&.revolver,
			&.glock,
			&.p1911,
			&.ppbizon,
			&.s686,
			&.sbs,
			&.double-eagle {
				position: relative;
				left: auto;
				margin-top: 0 !important;
			}

			&.sbs {
				left: -15px;
			}
		}
	}

	span.weapon-skin-image {
		background-image: url("../assets/spritesheet.png");
		transform: scale(2);
		&.bmg {
			margin-top: -30px;
			left: 1px;
			position: relative;
		}
		&.m16 {
			position: relative;
			left: 3px;
		}
		&.double-eagle {
			position: relative;
			left: 10px;
		}
	}
}
</style>
