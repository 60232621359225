<template>
  <button :disabled="disabled" v-bind:class="{ 'icon-button': true, [`${icon}`]: true }">
    <div class="icon-button-inner" v-on:click="onIconButtonClick()">
      <SkinImage v-if="!loading" :skin-data="{ skinCode: `${this.icon}_icon` }" :skin-image-type="'sprite'" :key="`${this.icon}_icon`"></SkinImage>
	  <div v-if="loading" class="loading-indicator"><img src="../assets/loading_icon.gif"/></div>
    </div>
  </button>
</template>

<script>
import SkinImage from "./SkinImage"
export default {
	name: "IconButton",
	components: {
		SkinImage
	},
	props: ["icon", "disabled", "loading", "onclickfn"],
	methods: {
		onIconButtonClick() {
			this.onclickfn()
		}
	}
}
</script>

<style lang="scss">
@import "../assets/colors.scss";
.icon-button {
	position: relative;
	height: 38px;
	width: 35px;
	background-color: #f3c113;
	border: none;
	border-bottom: 3px solid #000;
	border-radius: 5px;
	cursor: pointer;

	.loading-indicator {
		img {
			width: 28px;
			height: 28px;
			margin: 0 auto;
		}
	}

	&.music {
		.skin-image-wrapper {
			.sprite-skin-image {
				transform: scale(1.5) translateY(-1px);
			}
		}
	}

	 &.music_off {
		.skin-image-wrapper {
			.sprite-skin-image {
				transform: scale(1.5) translateY(0px);
			}
		}
	 }

	&.sounds, &.sounds_off {
		.skin-image-wrapper {
			.sprite-skin-image {
				transform: scale(1);
			}
		}
	}

	&.sounds-off {
		.skin-image-wrapper {
			.sprite-skin-image {
				transform: scale(2);
			}
		}
	}

	&.fullscreen {
		.skin-image-wrapper {
			.sprite-skin-image {
				transform: scale(1);
			}
		}
	}

	&:hover {
		height: 35px;
		top: 2px;
		border: none;
	}

	.icon-button-inner {
		height: 101%;
		width: 101%;
		border: 3px solid #570c00;
		border-radius: 2px;
	}

	.skin-image-wrapper {
		.sprite-skin-image {
			transform: scale(2);
		}
	}
}
</style>
