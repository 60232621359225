<template>
  <div class="screen" id="change-skin">
    <Panel
      class="change-skin-panel"
      :title="pageTitle"
      :oncloseroute="'/profile'"
    >
      <template #panel-content>
        <LoadyBro v-if="shopFetching || userDataFetching"></LoadyBro>
        <div v-else class="panel-inner">
          <div class="panel-left">
            <p class="forte-down-notification" v-if="forteSystemDown">
              {{ t("forteDownProfileNotice") }}
            </p>
            <div
              v-for="skin in availableSkinsOfType"
              :key="skin.identifier"
              v-on:click="previewSkin(skin.identifier)"
              v-bind:class="{
                'owned-skin': true,
                active: selectedSkin === skin.identifier,
                previewed: previewedSkin === skin.identifier
              }"
            >
              <div class="owned-skin-inner">
                <div class="skin-name">
                  {{ getFriendlySkinNameBySkinCode(skin.identifier) }}
                </div>
                <div
                  v-bind:class="{
                    'skin-picture': true,
                    [skinPreviewSpriteType]: true
                  }"
                >
                  <SkinImage
                    :skin-data="{ skinCode: skin.identifier }"
                    :skin-image-type="skinPreviewSpriteType"
                    :key="skin.identifier"
                  ></SkinImage>
                </div>
                <div
                  class="skin-selected-indicator"
                  v-if="selectedSkin === skin.identifier"
                >
                  <SkinImage
                    :skin-data="{ skinCode: 'check_icon' }"
                    :skin-image-type="'sprite'"
                  ></SkinImage>
                </div>
              </div>
            </div>
          </div>
          <div class="panel-right">
            <EmbeddedDarkBox class="selected-skin lighter">
              <template #dark-box-content>
                <h3>{{ getFriendlySkinNameBySkinCode(previewedSkin) }}</h3>
                <div
                  v-bind:class="{
                    'large-skin-preview': true,
                    [skinPreviewSpriteType]: true,
                    'offset-up': previewedSkin === 'traitor'
                  }"
                >
                  <SkinImage
                    :skin-data="{ skinCode: previewedSkin }"
                    :skin-image-type="skinPreviewSpriteType"
                    :key="previewedSkin"
                  ></SkinImage>
                </div>
                <div
                  class="project-winter-steam-link"
                  v-if="previewedSkin === 'traitor'"
                >
                  <a
                    href="https://store.steampowered.com/app/774861/Project_Winter/?utm_source=bruhio&utm_medium=shop&utm_campaign=skin_traitor"
                    target="_blank"
                    class="steambutton"
                  >
                    <span>Project Winter on Steam!</span>
                    <div class="icon">
                      <img src="../assets/steam.svg" />
                    </div>
                  </a>
                </div>
                <Button
                  v-if="previewedSkin !== selectedSkin"
                  class="equip-btn"
                  :color="'green'"
                  :onclickfn="
                    () => {
                      equipSkin(previewedSkin)
                    }
                  "
                  :text="t('changeSkinEquip')"
                ></Button>
              </template>
            </EmbeddedDarkBox>
          </div>
        </div>
      </template>
    </Panel>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import Panel from "../reusable-components/Panel"
import Button from "../reusable-components/Button"
import SkinImage from "../reusable-components/SkinImage"
import LoadyBro from "../reusable-components/LoadyBro"
import EmbeddedDarkBox from "../reusable-components/EmbeddedDarkBox"
import { parse } from "query-string"
import { filter, find } from "lodash"

export default {
  name: "ChangeSkin",
  components: {
    Panel,
    Button,
    EmbeddedDarkBox,
    SkinImage,
    LoadyBro
  },
  methods: {
    ...mapActions("user", ["changePreference"]),
    getFriendlySkinNameBySkinCode(skinCode) {
      return find(this.availableSkins, s => {
        return s.skinCode === skinCode
      }).skinFriendlyName
    },
    equipSkin(skinCode) {
      let { skinType } = parse(window.location.search)
      if (skinType !== "character" && skinType !== "weapon") {
        skinType = "character"
      }

      const preference =
        skinType === "character"
          ? "selectedCharacterSkin"
          : "selectedWeaponSkin"
      window.googleanalytics.send("event", "change-skin", skinCode)
      this.changePreference({ preference, newValue: skinCode })
    },
    previewSkin(skinCode) {
      if (this.selectedSkin === skinCode) return
      this.previewedSkin = skinCode
    }
  },
  data: function() {
    let { skinType } = parse(window.location.search)
    if (skinType !== "character" && skinType !== "weapon") {
      skinType = "character"
    }
    const initialPreviewedSkin = skinType === "character" ? "man" : "default"
    return { previewedSkin: initialPreviewedSkin }
  },
  computed: {
    ...mapGetters("i18n", ["t"]),
    ...mapGetters("user", [
      "selectedCharacterSkin",
      "selectedWeaponSkin",
      "userDataFetching"
    ]),
    ...mapGetters("shop", [
      "ownedSkins",
      "availableSkins",
      "shopFetching",
      "forteSystemDown"
    ]),
    selectedSkin() {
      let { skinType } = parse(window.location.search)
      if (skinType !== "character" && skinType !== "weapon") {
        skinType = "character"
      }
      return skinType === "character"
        ? this.selectedCharacterSkin
        : this.selectedWeaponSkin
    },
    pageTitle() {
      let { skinType } = parse(window.location.search)
      if (skinType !== "character" && skinType !== "weapon") {
        skinType = "character"
      }
      return skinType === "character"
        ? this.t("changeCharacterSkinPageTitle")
        : this.t("changeWeaponSkinPageTitle")
    },
    skinPreviewSpriteType() {
      let { skinType } = parse(window.location.search)
      if (skinType !== "character" && skinType !== "weapon") {
        skinType = "character"
      }
      return skinType === "character" ? "character" : "weapon_set_preview"
    },
    availableSkinsOfType() {
      let { skinType } = parse(window.location.search)
      if (skinType !== "character" && skinType !== "weapon") {
        skinType = "character"
      }
      const validType = skinType === "character" ? "character" : "weapon_set"
      let ownedSkinsOfType = filter(this.ownedSkins, s => {
        const fullSkinDetails = find(this.availableSkins, as => {
          return as.skinCode === s.identifier
        })
        // console.log(
        // 	`attempting to find skin ${s.identifier}, found ${fullSkinDetails}`
        // )
        if (fullSkinDetails) {
          return fullSkinDetails.skinType === validType
        } else {
          return null
        }
      })

      if (skinType === "weapon") {
        ownedSkinsOfType = [
          { source: "free", identifier: "default" },
          ...ownedSkinsOfType
        ]
      } else {
        ownedSkinsOfType = [
          { source: "free", identifier: "man" },
          { source: "free", identifier: "woman" },
          ...ownedSkinsOfType
        ]
      }

      return ownedSkinsOfType
    }
  }
}
</script>

<style lang="scss">
@media only screen and (max-width: 958px) {
  #change-skin {
    .change-skin-panel {
      transform: scale(0.75);
    }
  }
}

@media only screen and (max-width: 750px) {
  #change-skin {
    .change-skin-panel {
      transform: scale(0.5);
    }
  }
}

@media only screen and (max-width: 575px) {
  #change-skin {
    .change-skin-panel {
      transform: scale(0.4);
    }
  }
}

#change-skin {
  .change-skin-panel {
    display: flex;
    justify-content: center;
    height: 410px;
    width: 839px;
    min-width: 800px;
    position: relative;

    .loady-bro {
      position: absolute;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .panel-inner {
      display: flex;
      justify-content: center;
    }

    .panel-left,
    .panel-right {
      display: flex;
      height: 350px;
    }

    .panel-left {
      width: 457px;
      flex-wrap: wrap;
      justify-content: center;
      overflow-y: scroll;
      margin-right: 10px;

      p.forte-down-notification {
        text-align: center;
        color: darken(red, 10%);
        font-size: 14px;
        padding: 20px 15px;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 10px;
        height: 99px;
        margin: 0 20px;
        padding: 15px 30px;
      }

      &::-webkit-scrollbar {
        width: 8px;
        border-radius: 5px;
        background-color: #382f34;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #9a9a9a;
      }

      .owned-skin {
        cursor: pointer;
        width: 130px;
        height: 135px;
        margin: 5px;
        border-radius: 10px;
        position: relative;
        border: 2px solid #000;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          border: 2px solid #ffb425;
        }

        &.previewed {
          border: 2px solid white;
        }

        &.active {
          &:hover {
            border: 2px solid #000;
            cursor: auto;
          }
        }

        .owned-skin-inner {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          background-color: #bcbfc4;
          border: 2px solid #cdd0d5;
          position: relative;
          text-align: center;

          .skin-name {
            top: 0;
            left: 0;
            right: 0;
          }
          .skin-picture {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);

            .skin-image-wrapper {
              transform: scale(2);
            }

            &.weapon_set_preview {
              top: 57%;
              left: 54%;
              .skin-image-wrapper {
                transform: scale(0.75);
              }
            }
          }
          .skin-selected-indicator {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;

            .sprite-skin-image {
              transform: scale(2);
              position: relative;
              top: -7px;
            }
          }
        }
      }
    }
    .panel-right {
      justify-content: center;
      align-items: center;
      width: 292px;

      .selected-skin {
        height: 350px;
        width: 100%;
        position: relative;

        .project-winter-steam-link {
          z-index: 10;
          position: absolute;
          bottom: 105px;
          left: 50%;

          .steambutton {
            display: block;
            background-color: #6f9f31;
            width: 240px;
            height: 50px;
            line-height: 18px;
            margin: auto;
            color: #fff;
            position: absolute;
            cursor: pointer;
            overflow: hidden;
            border-radius: 5px;
            transform: translateX(-50%);
            border: 2px solid #19591c;
            border-bottom: 3px solid #153913;
          }

          .steambutton span {
            font-family: "Exo 2", sans-serif;
            font-weight: bold;
            letter-spacing: 0.1em;
            width: 75%;
            font-size: 14px;
            padding: 5px;
            text-transform: uppercase;
            left: 0;
            -webkit-transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
            transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
          }

          .steambutton span,
          .steambutton .icon {
            display: block;
            height: 100%;
            text-align: center;
            position: absolute;
            top: 0;
          }

          .steambutton .icon img {
            margin: 5px;
            height: 80%;
            -webkit-transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4),
              height 0.25s ease;
            transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4),
              height 0.25s ease;
          }

          .steambutton .icon {
            width: 25%;
            right: 0;
            -webkit-transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
            transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
          }

          .steambutton span,
          .steambutton .icon {
            display: block;
            height: 100%;
            text-align: center;
            position: absolute;
            top: 0;
          }

          img {
            display: inline-block;
            transform: translate(0, 0);
          }

          .steambutton span:after {
            content: "";
            background-color: #5d8628;
            width: 2px;
            height: 70%;
            position: absolute;
            top: 15%;
            right: -1px;
          }

          .steambutton.success span,
          .steambutton:hover span {
            left: -50%;
            opacity: 0;
          }

          .steambutton.success .icon,
          .steambutton:hover .icon {
            width: 100%;
          }
        }

        h3 {
          position: absolute;
          font-size: 24px;
          top: 10px;
          left: 50%;
          transform: translateX(-50%);
          width: 85%;
          text-align: center;
        }

        .large-skin-preview {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          &.offset-up {
            top: 40%;
          }

          .skin-image-wrapper {
            z-index: -1;
            transform: scale(6);
          }

          &.weapon_set_preview {
            top: 57%;
            left: 53%;
            .skin-image-wrapper {
              transform: scale(2);
            }
          }
        }

        .equip-btn {
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .equip-btn {
        .button-inner {
          text-transform: none;
        }
      }
    }
  }
}
</style>
