<template>
  <div class="screen" id="buy-coins">
    <Panel
      :title="'Coins'"
      class="buy-coins-panel"
      v-bind:class="{ loading: userDataFetching }"
      :oncloseroute="'/shop'"
    >
      <template #panel-content>
        <LoadyBro v-if="userDataFetching || shopFetching"></LoadyBro>
        <div v-else class="buy-coins-panel-inner">
          <div
            v-if="userType === 'registered'"
            :style="{ display: 'flex', 'flex-direction': 'row' }"
          >
            <EmbeddedDarkBox class="lighter buy-coins-balance">
              <template #dark-box-content>
                <p>Coin Balance</p>

                <div
                  class="currency"
                  id="wallet-container"
                  v-if="!forteSystemDown"
                >
                  <SkinImage
                    id="coin-image"
                    class="oo-coin-icon"
                    :skin-data="{ skinCode: `coin_icon` }"
                    :skin-image-type="'sprite'"
                  ></SkinImage>
                  <AnimatedNumber
                    class="wallet-balance"
                    :value="walletBalance"
                    :round="true"
                    :duration="2000"
                    :easing="'easeOutExpo'"
                  />
                </div>

                <!-- <p>Exchange Value</p>

                <div class="exchange-value">
                  <div class="currency-type">XRP</div>
                  <div class="amount">{{ xrpValue }}</div>
                </div> -->
              </template>
            </EmbeddedDarkBox>
            <EmbeddedDarkBox
              class="lighter buy-coins-cta"
              v-if="usersExchangeAccountIsLinked"
            >
              <template #dark-box-content>
                <h3>NEED MORE COIN?</h3>
                <p>
                  Visit the Game Exchange Service to purchase and exchange
                  coins.
                </p>
                <Button
                  class="exchange-btn"
                  :color="'green'"
                  :onclickfn="
                    () => {
                      openStaticExchangeLink()
                    }
                  "
                  :text="'Game Exchange'"
                ></Button>
              </template>
            </EmbeddedDarkBox>
            <EmbeddedDarkBox class="lighter buy-coins-cta" v-else>
              <template #dark-box-content>
                <h3>NEED MORE COIN?</h3>
                <div v-if="clickedLinkAccount">
                  <p>
                    <a href="/buy-coins">Click here</a> when you have finished the account linking process!
                  </p>
                </div>
                <div v-else>
                  <p>
                    Link your bruh.io account to Game Exchange Service to
                    purchase and exchange coins.
                  </p>
                  <Button
                    class="exchange-btn"
                    :color="'green'"
                    :onclickfn="
                      () => {
                        openLink()
                      }
                    "
                    :text="'Link Account'"
                  ></Button>
                </div>
              </template>
            </EmbeddedDarkBox>
          </div>
          <div v-else>
            <p>
              You need to register an account in order to buy and exchange
              coins.
            </p>
            <router-link to="/login-signup">
              <Button
                :color="'green'"
                :style="{ 'margin-top': '20px' }"
                :onclickfn="() => {}"
                :text="t('playSignUpButtonLabel')"
              ></Button>
            </router-link>
          </div>
        </div>
      </template>
    </Panel>
  </div>
</template>

<style lang="scss" scoped>
#buy-coins {
  .buy-coins-panel {
    position: relative;

    &.loading {
      width: 840px;
      height: 559px;
    }

    .loady-bro {
      position: absolute;
      top: 65%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .buy-coins-panel-inner {
      display: flex;
      flex-direction: row;

      div {
        text-align: center;
      }

      .buy-coins-balance,
      .buy-coins-cta {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .buy-coins-balance {
        margin-right: 20px;
        padding: 20px 40px;
        p {
          text-transform: uppercase;
          color: #9f9f9f;
          margin-top: 10px;
        }

        .currency {
          color: white;
          position: relative;
          margin: 9px 5px 5px 10px;
          height: 25px;
          left: 10px;

          .wallet-balance {
            text-align: center;
            padding: 3px;
            // margin-right: 12px;
            padding: 0 20px;
            position: relative;
            top: 3px;
          }

          .oo-coin-icon {
            pointer-events: none;
            position: absolute;
            left: -40px;
            transform: scale(2);
          }
        }

        .exchange-value {
          display: flex;
          flex-direction: row;
          margin-top: 10px;

          .currency-type {
            margin-right: 15px;
          }
        }
      }

      .buy-coins-cta {
        width: 350px;
        display: flex;
        justify-content: center;
        align-items: center;
        h3 {
          text-align: center;
          text-transform: uppercase;
          color: #ffa800;
          font-size: 28px;
        }

        div {
          text-align: center;
        }

        p {
          text-align: center;
          margin-top: 20px;
        }

        a {
          color: #ffa800;
        }

        .exchange-btn {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex"
import Panel from "../reusable-components/Panel"
import AnimatedNumber from "animated-number-vue"
import LoadyBro from "../reusable-components/LoadyBro"
import EmbeddedDarkBox from "../reusable-components/EmbeddedDarkBox"
import SkinImage from "../reusable-components/SkinImage"
import Button from "../reusable-components/Button"

export default {
  name: "BuyCoins",
  components: {
    Panel,
    LoadyBro,
    EmbeddedDarkBox,
    SkinImage,
    AnimatedNumber,
    Button,
  },
  methods: {
    openLink() {
      this.$data.clickedLinkAccount = true
      open(this.exchangeUrl)
    },
    openStaticExchangeLink() {
      open(process.env.STATIC_EXCHANGE_LINK)
    },
  },
  data() {
    return {
      clickedLinkAccount: false,
    }
  },
  computed: {
    ...mapGetters("i18n", ["t"]),
    ...mapGetters("shop", [
      "forteSystemDown",
      "shopFetching",
      "xrpValue",
      "exchangeUrl",
    ]),
    ...mapGetters("user", [
      "userType",
      "userDataFetching",
      "walletBalance",
      "usersExchangeAccountIsLinked",
    ]),
  },
}
</script>

