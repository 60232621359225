<template>
  <div class="screen" id="play-party">
    <div class="invite-friends-modal-wrapper" v-if="inviteFriendsModalOpen">
      <EmbeddedDarkBox class="invite-friends-modal darker upper">
        <template #dark-box-content>
          <div
            class="panel-close"
            v-on:click="
              () => {
                toggleInviteFriendsModal()
              }
            "
          >
            <SkinImage
              class="close-icon"
              :skin-data="{ skinCode: 'close_icon' }"
              :skin-image-type="'sprite'"
            ></SkinImage>
          </div>
          <p>Send your friends this link to play together!</p>
          <div class="copy">
            <input type="text" :value="currentPartyUrl" disabled />
            <button
              id="invite-link"
              v-on:click="
                () => {
                  copyPartyUrlToClipboard()
                }
              "
            >
              Copy To Clipboard
            </button>
          </div>
        </template>
      </EmbeddedDarkBox>
    </div>
    <div class="top">
      <div class="queue-btns">
        <div
          class="card-button small"
          v-on:click="
            () => {
              if (thisPlayerIsLeader) {
                squadQueue()
              }
            }
          "
          v-bind:class="{ disabled: thisPlayerIsLeader === false }"
        >
          <div ref="squad" class="card-content squad">
            <p v-if="userDataFetching">{{ t("loadingText") }}</p>
            <div v-if="!userDataFetching" class="squad-inset">
              <div class="squad-transparency">
                <div class="title">{{ t("partySquadButtonLabel") }}</div>
              </div>
            </div>
          </div>
          <div class="shadow squad-shadow"></div>
        </div>
      </div>
      <div class="players">
        <div
          v-for="member in memberDetails"
          :key="member.userId"
          class="player-slot"
        >
          <div class="player-character">
            <SkinImage
              class="party-slot-char-skin"
              :key="member.characterSkin"
              :skin-data="{ skinCode: member.characterSkin }"
              :skin-image-type="'character'"
            ></SkinImage>
            <SkinImage
              class="party-slot-wep-skin"
              :key="member.weaponSkin"
              :skin-data="{ skinCode: `s686_${member.weaponSkin}` }"
              :skin-image-type="'sprite'"
            ></SkinImage>
            <SkinImage
              class="party-slot-hand-skin"
              :skin-data="{ skinCode: 's686_hands' }"
              :skin-image-type="'sprite'"
            ></SkinImage>
          </div>
          <div class="is-leader" v-if="member.isLeader">
            <img src="../assets/crown_icon.png" alt="party leader" />
          </div>
          <div class="player-status">
            <div class="display-name">{{ member.username | truncate(9) }}</div>
            <div v-bind:class="{ 'ready-status': true, ready: member.isReady }">
              {{
                member.isReady ? t("playPartyReady") : t("playPartyNotReady")
              }}
            </div>
          </div>
        </div>

        <div
          class="player-slot"
          v-for="i in emptySlots"
          :key="i"
          v-on:click="
            () => {
              toggleInviteFriendsModal()
            }
          "
        >
          <div class="slot-invite-friends">
            <img
              src="../assets/invite_icon.png"
              alt="Invite Friends"
              class="plus"
            />
            <div class="invite-label">{{ t("playPartyInviteFriends") }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="group-actions">
      <div
        class="accept-randoms"
        v-bind:class="{ 'not-leader': thisPlayerIsLeader === false }"
      >
        <Tooltip
          v-if="!thisPlayerIsLeader"
          :text="'Only the party leader can change this setting!'"
        ></Tooltip>
        <Checkbox
          :checked="partyAllowsRandoms"
          :onclickfn="
            () => {
              if (thisPlayerIsLeader) {
                toggleAllowRandomsToJoin()
              }
            }
          "
        ></Checkbox>
        <div
          class="accept-randoms-label"
          v-on:click="
            () => {
              if (thisPlayerIsLeader) {
                toggleAllowRandomsToJoin()
              }
            }
          "
        >
          {{ t("playPartyAllowRandoms") }}
        </div>
      </div>
      <div class="party-actions">
        <Button
          v-if="!thisPlayerIsLeader"
          v-bind:class="{ 'readiness-btn': true, ready: thisPlayerIsReady }"
          :onclickfn="
            () => {
              toggleReadiness()
            }
          "
          :color="'green'"
          :text="
            thisPlayerIsReady ? t('playPartyGoNotReady') : t('playPartyGoReady')
          "
        ></Button>
        <Button
          class="leave"
          :color="'green'"
          :onclickfn="
            () => {
              leaveParty()
            }
          "
          :text="t('playPartyLeave')"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import SkinImage from "../reusable-components/SkinImage"
import Checkbox from "../reusable-components/Checkbox"
import Button from "../reusable-components/Button"
import Tooltip from "../reusable-components/Tooltip"
import EmbeddedDarkBox from "../reusable-components/EmbeddedDarkBox"
import Vue from "vue"
import Toasted from "vue-toasted"
Vue.use(Toasted)

export default {
  name: "PlayParty",
  components: {
    SkinImage,
    Checkbox,
    Button,
    Tooltip,
    EmbeddedDarkBox
  },
  data() {
    return {
      inviteFriendsModalOpen: false,
      currentPartyUrl: window.location.href
    }
  },
  methods: {
    async copyPartyUrlToClipboard(element) {
      const copyResult = await navigator.clipboard.writeText(
        this.currentPartyUrl
      )
      Vue.toasted.success("Copied to clipboard!", {
        position: "top-center",
        duration: 3000
      })
      this.toggleInviteFriendsModal()
    },
    toggleInviteFriendsModal() {
      this.inviteFriendsModalOpen = !this.inviteFriendsModalOpen
    },
    ...mapActions("party", [
      "leaveParty",
      "toggleAllowRandomsToJoin",
      "squadQueue",
      "toggleReadiness"
    ]),
    ...mapActions("user", ["soloQueue"])
  },
  computed: {
    ...mapGetters("i18n", ["t"]),
    ...mapGetters("user", [
      "selectedCharacterSkin",
      "selectedWeaponSkin",
      "userDataFetching"
    ]),
    ...mapGetters("party", [
      "partyId",
      "memberCount",
      "memberDetails",
      "thisPlayerIsLeader",
      "thisPlayerIsReady",
      "partyAllowsRandoms"
    ]),
    emptySlots() {
      return 4 - this.memberCount
    }
  }
}
</script>

<style lang="scss">
@import "../assets/colors.scss";
@import "../assets/buttons.scss";

#play-party {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  .invite-friends-modal-wrapper {
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .invite-friends-modal {
      height: 100px;
      width: 475px;
      position: relative;
      display: flex;
      flex-direction: column;

      p {
        margin-top: 10px;
        text-align: center;
        font-size: 14px;
      }

      .copy {
        padding: 5px;
        display: flex;
        flex-direction: row;
        input {
          margin-right: 5px;
          flex: 75;
          border-radius: 2px;
          background-color: darken(#262628, 30%);
          border: none;
          height: 36px;
          padding: 0 10px;
          border: 2px solid lighten(#262628, 20%);
          font-size: 14px;
          color: #fff;

          cursor: initial !important;
          user-select: all;
        }
        button {
          white-space: nowrap;
          text-transform: uppercase;
          position: relative;
          top: -1px;
          padding: 0 10px;
          color: white;
          border-radius: 3px;
          border: 1px solid lighten(#498e4b, 20%);
          background-color: #498e4b;
          height: 36px;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
          flex: 25;
        }
      }

      .panel-close {
        position: absolute;
        cursor: pointer;
        top: -22px;
        right: -22px;

        .close-icon {
          opacity: 1;
          transform: scale(0.8);

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }

  .top {
    display: flex;
    justify-content: center;
    align-items: center;

    .queue-btns {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 15px 10px 10px 10px;
      margin-right: 5px;
      position: relative;

      .card-button {
        margin: 0 0 10px 0;

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        .title {
          font-size: 22px;
          position: relative;
          top: 4px;
          white-space: nowrap;
        }

        .shadow {
          top: 5px;
        }
      }
    }

    .players {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      .player-slot {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 3px;
        border: 3px solid white;
        padding: 10px;
        height: 209px;
        width: 126px;
        position: relative;

        .is-leader {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);

          img {
            height: 30px;
          }
        }

        .slot-invite-friends {
          cursor: pointer;

          &:hover {
            transform: scale(1.05);
          }
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          img {
            transform: scale(3);
            margin-top: 20px;
            margin-bottom: 40px;
          }

          div.invite-label {
            text-align: center;
          }
        }

        .player-status {
          margin-bottom: 20px;

          .display-name {
            text-align: center;
            color: #ffa800;
          }
          .ready-status {
            text-align: center;
            color: #7f7f7f;

            &.ready {
              color: #488f4b;
            }
          }
        }

        .player-character {
          position: relative;
          height: 125px;
          width: 75px;
          pointer-events: none;

          .party-slot-char-skin {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(2.5);
          }
          .party-slot-wep-skin,
          .party-slot-hand-skin {
            position: absolute;
            top: 71%;
            left: 82%;
            transform: translate(-50%, -50%) scale(2) rotate(20deg);
          }
        }
      }
    }
  }
  .group-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    padding-top: 30px;

    .accept-randoms {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      cursor: pointer;

      .tooltip {
        display: none;
        top: -80px;
        width: 275px;
      }

      &:hover {
        .tooltip {
          top: -80px;
          display: block;
        }
      }

      &.not-leader {
        .accept-randoms-label,
        .checkbox {
          opacity: 0.5;
        }
      }

      .accept-randoms-label {
        margin-left: 15px;
      }
    }

    .party-actions {
      margin-left: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      button {
        margin-right: 15px;

        &.readiness-btn {
          width: 218px;
        }

        &.ready,
        &.leave {
          background-color: #7f7f7f;
        }
      }
    }
  }
}
</style>
