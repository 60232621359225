<template>
  <div class="status-bar">
    <div class="accent">
      <div class="username">
        <span>{{ username }}</span>
      </div>
      <div class="side-twiddle"></div>
    </div>
    <div class="background">
      <div class="border-accent">
        <div class="friends">
          <router-link to="/friends">
            <IconButton class="friends" :icon="'friend'" :key="'friend'" :onclickfn="() => {}"></IconButton>
            <span class="num-online"
              v-if="numberOfFriendsOnline >= 1"
            >{{ numberOfFriendsOnline }} {{ t('friendOnline') }}</span>
            <span class="num-online" v-if="!numberOfFriendsOnline">{{ t('friends') }}</span>
          </router-link>
        </div>
        <div class="settings-wrapper">
          <div class="settings-area">
            <IconButton class="open-settings-icon" :icon="'settings'" :onclickfn="openSettings"></IconButton>
            <IconButton
              class="toggle-music-icon"
              :disabled="changingPreferences['musicEnabled']"
              :loading="changingPreferences['musicEnabled']"
              :icon="preferences.musicEnabled === true ? 'music' : 'music_off'"
              :key="preferences.musicEnabled === true ? 'music' : 'music_off'"
              :onclickfn="() => { togglePreference('musicEnabled') }"
            ></IconButton>
            <IconButton
              class="toggle-sound-icon"
              :disabled="changingPreferences['sfxEnabled']"
              :loading="changingPreferences['sfxEnabled']"
              :icon="preferences.sfxEnabled === true ? 'sounds' : 'sounds_off'"
              :key="preferences.sfxEnabled === true ? 'sounds' : 'sounds_off'"
              :onclickfn="() => { togglePreference('sfxEnabled') }"
            ></IconButton>
            <IconButton class="toggle-fullscreen-icon" :icon="'fullscreen'" :onclickfn="toggleFS"></IconButton>
          </div>
        </div>
        <div class="currency" id="wallet-container" v-if="!forteSystemDown">
          <SkinImage
            id="coin-image"
            class="oo-coin-icon"
            :skin-data="{ skinCode: `coin_icon` }"
            :skin-image-type="'sprite'"
          ></SkinImage>
          <animated-number
            class="wallet-balance"
            :value="walletBalance"
            :round="true"
            :duration="2000"
            :easing="'easeOutExpo'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import IconButton from "../reusable-components/IconButton"
import AnimatedNumber from "animated-number-vue"
import { toggleFullScreen } from "../utils/fullscreen"
import SkinImage from "../reusable-components/SkinImage"
import Tooltip from "../reusable-components/Tooltip"
export default {
	name: "StatusBar",
	components: {
		AnimatedNumber,
		IconButton,
		SkinImage,
		Tooltip
	},
	data: function() {
		return {
			changingPreferences: {
				musicEnabled: false,
				sfxEnabled: false
			}
		}
	},
	computed: {
		...mapGetters("i18n", ["t"]),
		...mapGetters("user", [
			"username",
			"walletBalance",
			"preferences",
			"userDataFetching"
		]),
		...mapGetters("shop", ["forteSystemDown"]),
		...mapGetters("friends", ["numberOfFriendsOnline"])
	},
	methods: {
		...mapActions("user", ["changePreference"]),
		async togglePreference(preference) {
			if (this.userDataFetching) return
			this.changingPreferences[preference] = true
			await this.changePreference({
				preference,
				newValue: !this.preferences[preference]
			})
			this.changingPreferences[preference] = false
		},
		openSettings() {
			this.$router.push("/settings")
		},
		toggleFS() {
			toggleFullScreen()
		}
	}
}
</script>

<style lang="scss">
.status-bar {
	z-index: 10000;
	background-color: #39363d;
	height: 50px;
	min-height: 50px;
	display: flex;
	align-items: center;

	.friends {
		margin-left: 16px;
		margin-top: 1px;
		text-overflow: ellipsis;
		overflow: hidden;

		span.num-online {
			margin-left: 10px;
			text-overflow: ellipsis;
		}

		a {
			text-overflow: ellipsis;
			color: white;
			text-decoration: none;
			text-transform: uppercase;
		}
	}

	.wallet-balance {
		display: inline-block;
		min-width: 85px;
		text-align: right;
	}

	.accent {
		display: flex;
		position: relative;
		height: 100%;
		background-color: #666b6f;
		border-bottom: 3px solid #8c8d91;
		color: white;
		font-size: 24px;
		padding: 0 25px 0 20px;

		.username {
			margin: auto;
			white-space: nowrap;
			span {
				white-space: nowrap;
			}
		}

		.side-twiddle {
			$width: 30px;
			position: absolute;
			transform: rotate(25deg);
			right: $width / -2 + 2;
			top: $width / -3 - 1;

			width: $width;
			height: 120%;

			background-color: #666b6f;
			border-right: 3px solid #8c8d91;
		}
	}

	.background {
		display: flex;
		width: 100%;
		height: 100%;

		border-top: 2px solid #17161b;
		border-bottom: 2px solid #484850;

		.border-accent {
			display: flex;
			width: 100%;
			height: 100%;

			border-top: 2px solid #6d6d70;
			border-bottom: 2px solid #2a292d;
		}

		.settings-wrapper {
			display: flex;
			overflow: hidden;
			margin-left: auto;
			height: 100%;
			vertical-align: top;

			.settings-area {
				padding-top: 2px;
				vertical-align: top;
				margin-right: 10px;

				.icon-button {
					vertical-align: top;
				}
			}
		}

		.currency {
			color: white;
			position: relative;
			background-color: #1b1b1d;
			margin: 9px 5px 5px 10px;
			height: 25px;

			.wallet-balance {
				padding: 3px;
				margin-right: 12px;
			}

			.oo-coin-icon {
				pointer-events: none;
				position: absolute;
				left: -40px;
				transform: scale(2);
			}
		}
	}
}
</style>
