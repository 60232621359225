<template>
  <div class="screen" id="shop">
    <div
      v-if="previewedSkin"
      class="shop-confirm-purchase-overlay"
      v-bind:class="{ visible: modalVisible }"
    >
      <div class="confirm-purchase-modal">
        <div v-if="shopFetching || userDataFetching" class="modal-loading">
          <LoadyBro></LoadyBro>
          <p>{{ t("pleaseWaitProcessingPurchase") }}</p>
        </div>
        <div v-else class="modal-not-loading">
          <div
            class="close"
            v-on:click="
              () => {
                hideConfirmPurchaseModal()
              }
            "
          >
            <SkinImage
              class="close-icon"
              :skin-data="{ skinCode: 'close_icon' }"
              :skin-image-type="'sprite'"
            ></SkinImage>
          </div>

          <div class="modal-title">
            <h2>{{ t("shopConfirmPurchase") }}</h2>
            <h3>
              {{ previewedSkin.skinFriendlyName }} -
              <img
                v-if="previewedSkin.skinPurchaseType === 'forte'"
                src="../assets/coin.png"
              />
              {{ previewedSkin.skinPurchaseMetadata.priceString }}
            </h3>
          </div>

          <div
            v-bind:class="{
              'full-skin-image': true,
              [previewedSkin.skinType]: true,
            }"
          >
            <SkinImage
              :skin-data="{ skinCode: previewedSkin.skinCode }"
              :skin-image-type="previewedSkin.skinType"
              :key="previewedSkin.skinCode"
            ></SkinImage>
          </div>

          <div class="purchase-btn">
            <p
              class="forte-down-notification"
              v-if="
                forteSystemDown && previewedSkin.skinPurchaseType === 'forte'
              "
            >
              {{ t("forteDownShopNotice") }}
            </p>
            <Button
              v-else-if="userType === 'registered'"
              class="buy-btn"
              :color="'green'"
              :onclickfn="
                () => {
                  purchaseSkin(previewedSkin)
                }
              "
              :text="t('shopBuyNow')"
            ></Button>
          </div>
        </div>
      </div>
    </div>
    <Panel class="shop" :title="t('navShopButtonText')">
      <template #panel-content>
        <LoadyBro v-if="shopFetching || userDataFetching"></LoadyBro>
        <div v-else class="panel-inner">
          <!-- Removing 2022-03-29 for BRUH-532 -->
          <div class="buy-coins-btn" v-if="false">
            <router-link to="/buy-coins">
              <Button
                :color="'green'"
                :style="{ 'margin-top': '20px', height: '75px' }"
                :onclickfn="() => {}"
                :text="'Buy Coins!'"
              ></Button>
            </router-link>
          </div>
          <div class="panel-left">
            <div class="feature-items-wrapper" v-if="featuredSkins.length >= 1">
              <div class="limited-items">
                <div class="shop-area-label">{{ t("shopFeaturedItems") }}</div>
                <div class="time-left">
                  {{ t("shopNewIn") }}
                  <img src="../assets/clock_icon.png" /> 06:12
                </div>
              </div>
              <div
                v-for="skin in featuredSkins"
                :key="skin.skinCode"
                v-on:click="previewSkin(skin)"
                v-bind:class="{
                  'owned-skin': false,
                  'almost-sold-out': true,
                  previewed: previewedSkin.skinCode === skin.skinCode,
                }"
              >
                <div class="almost-sold-out-indicator">
                  <img src="../assets/shop_indicator_banner.png" />
                  <p>{{ t("shopAlmostSoldOut") }}</p>
                </div>

                <div class="owned-skin-inner">
                  <div class="skin-name">{{ skin.skinFriendlyName }}</div>
                  <div
                    v-bind:class="{
                      'skin-picture': true,
                      [skin.skinType]: true,
                    }"
                  >
                    <SkinImage
                      :skin-data="{ skinCode: skin.skinCode }"
                      :skin-image-type="
                        skin.skinType === 'weapon_set'
                          ? 'weapon_set_preview'
                          : skin.skinType
                      "
                      :key="skin.skinCode"
                    ></SkinImage>
                  </div>
                </div>
                <div class="skin-price">
                  <SkinImage
                    v-if="skin.skinPurchaseType === 'forte'"
                    class="oo-coin-icon"
                    :skin-data="{ skinCode: `coin_icon` }"
                    :skin-image-type="'sprite'"
                  ></SkinImage>
                  {{
                    filteredAvailableSkins[8].skinPurchaseMetadata.priceString
                  }}
                </div>
              </div>
            </div>

            <div class="regular-items">
              <div class="shop-area-label">{{ t("shopRegularItems") }}</div>
            </div>

            <div v-if="filteredAvailableSkins.length === 0">
              It seems you've already got every skin the shop has to offer!
            </div>

            <div
              v-for="skin in filteredAvailableSkins"
              :key="skin.skinCode"
              v-on:click="previewSkin(skin)"
              v-bind:class="{
                'owned-skin': true,
                previewed: previewedSkin.skinCode === skin.skinCode,
              }"
            >
              <div class="owned-skin-inner">
                <div class="skin-name">{{ skin.skinFriendlyName }}</div>
                <div
                  v-bind:class="{ 'skin-picture': true, [skin.skinType]: true }"
                >
                  <SkinImage
                    :skin-data="{ skinCode: skin.skinCode }"
                    :skin-image-type="
                      skin.skinType === 'weapon_set'
                        ? 'weapon_set_preview'
                        : skin.skinType
                    "
                    :key="skin.skinCode"
                  ></SkinImage>
                </div>
              </div>
              <div class="skin-price">
                <SkinImage
                  v-if="skin.skinPurchaseType === 'forte'"
                  class="oo-coin-icon"
                  :skin-data="{ skinCode: `coin_icon` }"
                  :skin-image-type="'sprite'"
                ></SkinImage>
                {{ skin.skinPurchaseMetadata.priceString }}
              </div>
            </div>
          </div>
          <div class="panel-right" v-if="previewedSkin">
            <EmbeddedDarkBox class="selected-skin lighter">
              <template #dark-box-content>
                <div class="previewed-skin-wrapper">
                  <h3>{{ previewedSkin.skinFriendlyName }}</h3>
                  <h2>
                    <img
                      v-if="previewedSkin.skinPurchaseType === 'forte'"
                      src="../assets/coin.png"
                    />
                    {{ previewedSkin.skinPurchaseMetadata.priceString }}
                  </h2>
                  <div
                    v-bind:class="{
                      'large-skin-preview': true,
                      [previewedSkin.skinType]: true,
                    }"
                  >
                    <SkinImage
                      :skin-data="{ skinCode: previewedSkin.skinCode }"
                      :skin-image-type="
                        previewedSkin.skinType === 'weapon_set'
                          ? 'weapon_set_preview'
                          : previewedSkin.skinType
                      "
                      :key="previewedSkin.skinCode"
                    ></SkinImage>
                  </div>
                  <Button
                    v-if="userType === 'registered'"
                    class="buy-btn"
                    :color="'green'"
                    :onclickfn="
                      () => {
                        showConfirmPurchaseModal()
                      }
                    "
                    :text="t('shopBuy')"
                  ></Button>
                  <p class="buy-btn" v-else>
                    {{ t("shopAccountNeeded") }}
                    <br />
                    <router-link to="/login-signup">{{
                      t("shopClickHereToSignUp")
                    }}</router-link>
                  </p>
                </div>
              </template>
            </EmbeddedDarkBox>
          </div>
        </div>
      </template>
    </Panel>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import Panel from "../reusable-components/Panel"
import Button from "../reusable-components/Button"
import SkinImage from "../reusable-components/SkinImage"
import LoadyBro from "../reusable-components/LoadyBro"
import EmbeddedDarkBox from "../reusable-components/EmbeddedDarkBox"
import { parse } from "query-string"
import { filter, find, map } from "lodash"

export default {
  name: "Shop",
  components: {
    Panel,
    Button,
    EmbeddedDarkBox,
    SkinImage,
    LoadyBro,
  },
  methods: {
    ...mapActions("user", ["changePreference"]),
    ...mapActions("shop", ["purchaseStripeSkin", "purchaseForteSkin"]),
    previewSkin(skin) {
      if (this.previewedSkin === skin) return
      this.previewedSkin = skin
    },
    hideConfirmPurchaseModal() {
      this.modalVisible = false
    },
    showConfirmPurchaseModal() {
      this.modalVisible = true
    },
    purchaseSkin(skin) {
      if (this.shopFetching) return

      if (skin.skinPurchaseType === "stripe") {
        this.purchaseStripeSkin(skin)
      } else {
        this.purchaseForteSkin({
          skinCode: skin.skinCode,
          redirect: "/payment-success",
        })
      }
    },
  },
  data: function () {
    return { previewedSkin: false, modalVisible: false, featuredSkins: [] }
  },
  computed: {
    ...mapGetters("i18n", ["t"]),
    ...mapGetters("user", [
      "userType",
      "selectedCharacterSkin",
      "selectedWeaponSkin",
      "userDataFetching",
    ]),
    ...mapGetters("shop", [
      "ownedSkins",
      "availableSkins",
      "shopFetching",
      "forteSystemDown"
    ]),
    filteredAvailableSkins() {
      const skinCodeFilters = [
        "man",
        "woman",
        "default",
        "bpseason1",
        "bpseason2",
        "bpseason3",
        "bpseason4",
        "bpseason5",
        "bpseason6",
        "bpseason7",
        "bpseason8",
        "bpseason9",
        "bpseason10",
        ...map(this.ownedSkins, (s) => {
          return s.identifier
        }),
        ...map(this.availableSkins, (s) => {
          return s.skinPurchaseType === "battlepass" ? s.skinCode : undefined
        }),
      ].filter((e) => {
        return e !== undefined
      })

      return filter(this.availableSkins, (s) => {
        return !skinCodeFilters.includes(s.skinCode)
      })
    },
  },
}
</script>

<style lang="scss">
@media only screen and (max-width: 958px) {
  #shop {
    .shop {
      transform: scale(0.75);
    }
  }
}

@media only screen and (max-width: 750px) {
  #shop {
    .shop {
      transform: scale(0.5);
    }
  }
}

#shop {
  .limited-items,
  .regular-items {
    background-color: #262628;
    width: 100%;
    height: 25px;
    margin: 0 10px 10px 10px;
    display: inline-block;
    padding: 3px 10px;

    .shop-area-label,
    .time-left {
      display: inline-block;
    }

    .time-left {
      float: right;

      img {
        position: relative;
        top: 2px;
        height: 15px;
      }
    }
  }

  .modal-loading {
    padding-top: 150px;
  }

  .modal-not-loading,
  .modal-loading {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .loady-bro,
    p {
      display: inline-block;
    }

    p {
      position: relative;
      top: -50px;
      text-align: center;
      width: 300px;
    }
  }

  .shop-confirm-purchase-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.7);
    display: none;

    &.visible {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .confirm-purchase-modal {
      color: white;
      height: 400px;
      width: 550px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      border-image: url("../assets/bruh-dark-panel.png") round;
      border-image-slice: 14 fill;
      border-image-width: 14px;

      .close {
        position: absolute;
        top: 5px;
        right: 8px;
        opacity: 0.8;
        transform: scale(0.8);
        cursor: pointer;
        z-index: 99;

        &:hover {
          opacity: 1;
        }
      }

      .modal-title {
        position: absolute;
        top: 18px;
        width: 100%;
        text-align: center;

        img {
          height: 22px;
          vertical-align: top;
        }

        h2 {
          font-size: 20px;
        }

        h3 {
          font-size: 16px;
        }
      }

      .full-skin-image {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        &.character {
          transform: scale(5);
        }
      }

      .purchase-btn {
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);

        .forte-down-notification {
          position: relative;
          width: 165%;
          left: -90px;
          top: 10px;
          text-align: center;
          margin-bottom: 20px;
          color: darken(red, 10%);
          font-size: 14px;
          background-color: rgba(0, 0, 0, 0.4);
          padding: 10px;
          border-radius: 10px;
        }
      }
    }
  }
  .shop {
    max-width: 989px;
    display: flex;
    justify-content: center;
    height: 450px;
    min-width: 789px;
    position: relative;

    .loady-bro {
      position: absolute;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .panel-inner {
      display: flex;
      justify-content: center;
    }

    .panel-left,
    .panel-right {
      display: flex;
      margin-top: 10px;
      height: 380px;
    }

    .panel-left {
      flex-wrap: wrap;
      justify-content: center;
      overflow-y: auto;
      margin-right: 10px;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 8px;
        border-radius: 5px;
        background-color: #382f34;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #9a9a9a;
      }

      .owned-skin {
        cursor: pointer;
        width: 115px;
        height: 140px;
        margin: 5px;
        border-radius: 10px;
        position: relative;
        border: 2px solid #000;
        display: flex;
        justify-content: center;
        align-items: center;

        .almost-sold-out-indicator,
        &.sold-out-indicator {
          display: none;
        }

        &.sold-out {
          opacity: 0.5;
          pointer-events: none;
        }

        &.almost-sold-out,
        &.sold-out {
          .almost-sold-out-indicator,
          .sold-out-indicator {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            // transform: scaleX(-1);
            z-index: 9998;
            pointer-events: none;

            p {
              position: absolute;
              text-align: center;
              top: 28px;
              left: 2px;
              transform: rotate(45deg);
              width: 100px;
              font-size: 10px;
            }

            img {
              width: 100%;
            }
          }
        }

        &:hover {
          border: 2px solid #ffb425;
        }

        &.previewed {
          border: 2px solid white;
        }

        &.active {
          &:hover {
            border: 2px solid #000;
            cursor: auto;
          }
        }

        .owned-skin-inner {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          background-color: #9a9a9a;
          border: 2px solid #cdd0d5;
          position: relative;
          text-align: center;

          .skin-name {
            position: absolute;
            top: 4px;
            left: 0;
            right: 0;
            font-size: 14px;
          }
          .skin-picture {
            position: absolute;
            top: 52%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);

            .skin-image-wrapper {
              transform: scale(2);
            }

            &.weapon_set_preview,
            &.weapon_set {
              top: 57%;
              left: 54%;
              .skin-image-wrapper {
                transform: scale(0.75);
              }
            }
            &.battlepass {
              .skin-image-wrapper {
                transform: scale(0.75);
              }
            }
          }
        }
        .skin-selected-indicator,
        .skin-price {
          position: absolute;
          bottom: 4px;
          left: 50%;
          transform: translateX(-50%);

          .oo-coin-icon {
            transform: scale(0.6);
            position: relative;
            left: -30px;
            top: 20px;
            // display: inline-block;
          }

          .sprite-skin-image {
            transform: scale(2);
            position: relative;
            top: -7px;
          }
        }
      }
    }
    .panel-right {
      justify-content: center;
      align-items: center;
      width: 292px;

      .previewed-skin-wrapper,
      .no-preview-selected {
        width: 100%;
        height: 100%;
      }

      .no-preview-selected {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 30px;
      }

      .selected-skin {
        height: 350px;
        width: 457px;
        position: relative;

        h3,
        h2 {
          width: 100%;
          text-align: center;
          // position: absolute;
          font-size: 24px;
          top: 10px;
          // left: 50%;
          // transform: translateX(-50%);

          img {
            height: 30px;
            vertical-align: top;
            position: relative;
            top: -2px;
          }
        }

        .large-skin-preview {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          .skin-image-wrapper {
            transform: scale(6);
          }

          &.weapon_set {
            top: 57%;
            left: 53%;
            .skin-image-wrapper {
              transform: scale(2);
            }
          }
          &.battlepass {
            .skin-image-wrapper {
              transform: scale(2);
            }
          }
        }

        .buy-btn {
          position: absolute;
          bottom: 15px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      p.buy-btn {
        font-size: 14px;
        text-align: center;
        width: 54%;
        bottom: 25px;

        a {
          color: white;
        }
      }

      .buy-btn {
        .button-inner {
          text-transform: none;
        }
      }
    }
  }
}
</style>

