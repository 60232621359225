import englishStrings from "../i18n/english"
import portugueseStrings from "../i18n/portuguese"
import spanishStrings from "../i18n/spanish"
import frenchStrings from "../i18n/french"
import russianStrings from "../i18n/russian"
import chineseStrings from "../i18n/chinese"
import koreanStrings from "../i18n/korean"

const strings = {
	en: englishStrings,
	pt: portugueseStrings,
	es: spanishStrings,
	fr: frenchStrings,
	ru: russianStrings,
	zh: chineseStrings,
	ko: koreanStrings
}

export const t = (stringCode) => {
	const localeStrings = strings[window.currentLocale]

	if (localeStrings === undefined) return `UNKNOWN LOCALE ${window.currentLocale}`

	const translatedString = localeStrings[stringCode]

	if (translatedString === undefined) return `MISSING TRANSLATION FOR KEY: ${stringCode}`

	return translatedString
}
