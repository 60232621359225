<template>
  <div class="screen" id="play">
    <div class="play-wrapper">
      <div class="play-top-left-info">
        <!-- <router-link to="/friends">
										<IconButton class="friends" :icon="'friend'" :key="'friend'" :onclickfn="() => {}"></IconButton>
				</router-link>-->
      </div>

      <div class="play-top-right-info">
        <div class="language-select">
          <div class="select-label">{{ t("playLanguageInputLabel") }}</div>
          <select
            v-model="preferences.preferredLanguage"
            :disabled="userDataFetching"
            name="language-select"
            id="language-select"
            @change="onChange($event, 'preferredLanguage')"
          >
            <option value="en">English</option>
            <option value="fr">Français</option>
            <option value="es">Español</option>
            <option value="ru">русский</option>
            <option value="pt">Português</option>
            <option value="ko">한국어</option>
            <option value="zh">中文</option>
          </select>
          <img class="select-arrow" src="../assets/select_arrow.png" />
        </div>
        <div
          class="region-select"
          :disabled="userDataFetching"
          v-if="population"
          v-bind:class="{ [preferences.preferredMatchmakingRegion]: true }"
        >
          <div class="select-label">
            {{ t("playMatchmakingRegionInputLabel") }}
          </div>
          <select
            v-model="preferences.preferredMatchmakingRegion"
            name="region-select"
            id="region-select"
            @change="onChange($event, 'preferredMatchmakingRegion')"
          >
            <option value="auto">
              {{ t("playMatchmakingRegionDropdownAutoDetectLabel") }}
            </option>
            <option value="na">
              {{ t("playMatchmakingRegionDropdownNALabel") }}
            </option>
            <option value="eu">
              {{ t("playMatchmakingRegionDropdownEULabel") }}
            </option>
          </select>
          <img class="select-arrow" src="../assets/select_arrow.png" />
        </div>
        <div class="game-version">BRUH.IO {{ gameVersion }}</div>
      </div>

      <!-- <div class="play-bottom-left-info"> -->
      <!-- <router-link to="/battlepass">
										<img src="../assets/battlepass.png" />
			</router-link>-->
      <!-- </div> -->

      <div class="play-central">
        <div class="version-floater">{{ gameVersion }}</div>
        <img class="bruh-logo" src="../assets/logo.png" alt="BRUH.IO" />
        <h2>Battle Royale: {{ randomGameTitleAcronym }}</h2>

        <div class="play-central-swappable">
          <router-view></router-view>
        </div>

        <div class="signup-cta">
          <div class="logged-in" v-if="userType === 'registered'">
            <h4>{{ t("playThanksForSigingUp") }}</h4>
          </div>
          <div class="not-logged-in" v-else>
            <router-link to="/login-signup">
              <Button
                :color="'green'"
                :onclickfn="() => {}"
                :text="t('playSignUpButtonLabel')"
              ></Button>
            </router-link>
            <h4>{{ t("playFeaturesCTA") }}</h4>
          </div>
          <SocialLinks style="margin-top: 15px"></SocialLinks>
        </div>

        <div
          class="la"
          :class="{ placeholder: !isProduction }"
          id="bruh-io_728x90"
        ></div>
      </div>

      <div class="right-and-dailies">
        <div class="missions" v-if="userType === 'registered'">
          <MissionList></MissionList>
        </div>
        <div
          class="ra"
          :class="{ placeholder: !isProduction }"
          id="bruh-io_300x250"
        ></div>
      </div>
    </div>
    <div id="affiliate-links">
      <a target="_blank" href="https://iogames.space/?utm_source=bruhio"
        >Play more .IO games @ iogames.space!</a
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import chooseRandomAcronym from "../utils/random-acronym"
import IconButton from "../reusable-components/IconButton"
import EmbeddedDarkBox from "../reusable-components/EmbeddedDarkBox"
import Button from "../reusable-components/Button"
import ProgressBar from "../reusable-components/ProgressBar"
import MissionList from "../reusable-components/MissionList"
import SocialLinks from "../reusable-components/SocialLinks"

export default {
  name: "Play",
  components: {
    ProgressBar,
    MissionList,
    Button,
    IconButton,
    EmbeddedDarkBox,
    SocialLinks,
  },
  computed: {
    ...mapGetters("i18n", ["t"]),
    ...mapGetters("user", [
      "userType",
      "preferences",
      "userDataFetching",
      "population",
      "dailyMissions",
      "weeklyMissions",
    ]),
    ...mapGetters("friends", ["numberOfFriendsOnline"]),
    randomGameTitleAcronym() {
      return chooseRandomAcronym()
    },
    gameVersion() {
      return `v${process.env.GAME_VERSION}`
    },
  },
  data() {
    return {
      isProduction: process.env.NODE_ENV === "production",
    }
  },
  mounted() {
    if (process.env.NODE_ENV === "production") {
      aiptag.cmd.display.push(function () {
        aipDisplayTag.display("bruh-io_728x90")
      })
      aiptag.cmd.display.push(function () {
        aipDisplayTag.display("bruh-io_300x250")
      })
    }
  },
  methods: {
    ...mapActions("i18n", ["explicitlyChangeLocale"]),
    ...mapActions("user", ["changePreference"]),
    onChange(event, preference) {
      const newValue = event.target.value
      if (preference === "preferredLanguage") {
        this.explicitlyChangeLocale(newValue)
      }
      this.changePreference({ preference, newValue })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./src/assets/colors.scss";

@media only screen and (max-width: 1610px) {
  #play {
    .right-and-dailies {
      display: flex;
      flex-direction: column;
      margin-top: 10vh;
      height: 75vh;
    }
    .ra {
      margin-bottom: 20px;
    }
    .ra,
    .missions {
      width: 300px;
      position: relative;
      top: auto;
      right: auto;
      left: auto;
      transform: none;
      display: inline-block;
      vertical-align: top;
    }
  }
}

@media only screen and (max-width: 1220px) {
  #play {
    .ra,
    .missions {
      display: none;
    }
  }
}

#play {
  position: relative;

  .placeholder {
    box-sizing: border-box;
    border: 3px solid magenta;
  }

  .play-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ra {
    width: 300px;
    height: 250px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5%;
  }

  .missions {
    width: 340px;
    height: 100%;
    max-height: 350px;
    flex-grow: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 3%;
    color: white;

    .mission-box {
      max-height: 50vh;
      padding: 15px 15px;
      background-image: url("../assets/tile.png");
      background-repeat: repeat;
      border-radius: 5px;
      border: 10px solid rgba(0, 0, 0, 0);
      border-image: url("../assets/bruh-panel.png");
      border-image-slice: 18%;
      border-image-width: 12px;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 8px;
        border-radius: 5px;
        background-color: #382f34;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #9a9a9a;
      }

      h3 {
        font-size: 22px;
        margin-bottom: 5px;
        white-space: nowrap;
      }

      &::-webkit-scrollbar {
        width: 8px;
        border-radius: 5px;
        background-color: #382f34;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #9a9a9a;
      }

      .mission-rows {
        height: 100%;
        margin-bottom: 20px;
        overflow-y: hidden;
        overflow-x: hidden;
        padding-right: 15px;
      }

      .mission-row {
        font-size: 14px;
        margin-bottom: 5px;

        .mission-desc {
          padding-left: 3px;
        }

        .mission-progress {
          height: 18px;
          width: 100%;
          background-color: #0e0e0e;
          position: relative;

          .mission-progress-numbers {
            position: absolute;
            top: 1px;
            left: 5px;
          }

          .mission-progress-inner {
            height: 100%;
            border: 2px solid #a34900;
            background-color: #e5b200;
          }
        }
      }
    }
  }

  #affiliate-links {
    position: absolute;
    bottom: 10px;
    font-size: 80%;

    a {
      color: #de7300;
    }
  }

  .play-central {
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    position: relative;

    .version-floater {
      text-align: center;
      position: absolute;
      top: 100px;
      right: 40px;
      font-size: 36px;
      text-shadow: 3px 3px 1px darken(#f9a901, 20%);
      color: #f9a901;
      animation: grow-shrink 2s ease-in-out infinite;

      @keyframes grow-shrink {
        from {
          transform: scale(1) rotate(32deg);
        }
        50% {
          transform: scale(1.2) rotate(18deg);
        }
        to {
          transform: scale(1) rotate(32deg);
        }
      }

      .tiny {
        font-size: 30px;
        line-height: 5px;
      }
    }

    .play-central-swappable {
      min-width: 570px;
      min-height: 230px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h2 {
      color: #f9a901;
      text-transform: uppercase;
    }

    .bruh-logo {
      width: 762px;
    }

    .signup-cta {
      margin: 20px 0;
      text-align: center;
      h4 {
        margin-top: 10px;
        text-transform: uppercase;
        font-size: 12px;
      }
    }
    .la {
      width: 728px;
      height: 90px;
    }
  }

  .play-top-left-info {
    position: absolute;
    top: 15px;
    left: 25px;
  }

  .play-bottom-left-info {
    position: absolute;
    bottom: 15px;
    left: 15px;
  }
  .play-top-right-info {
    user-select: none;
    position: absolute;
    top: 15px;
    right: 15px;

    .game-version {
      text-align: right;
      color: white;
      font-size: 10px;
      margin-top: 5px;
    }

    .region-select {
      &.auto {
        width: 190px;
      }
      &.na {
        width: 190px;
      }
      &.eu {
        width: 190px;
      }
      &.ap {
        width: 190px;
      }
    }

    .language-select,
    .region-select {
      user-select: none;
      margin-left: 10px;
      display: inline-block;
      color: white;
      border: 3px solid #7d8085;
      background-color: #1f1f1e;
      border-radius: 3px;
      position: relative;

      .select-arrow {
        transform: scale(3);
        position: absolute;
        top: 17px;
        right: 21px;
        pointer-events: none;
      }

      .select-label {
        user-select: none;
        pointer-events: none;
        padding-left: 3px;
        color: #aeaeae;
        font-size: 12px;
        position: absolute;
        top: 0;
      }

      select {
        user-select: none;
        -webkit-appearance: none;
        padding: 15px 50px 0 3px;
        color: white;
        font-size: 18px;
        border: none;
        background: none;
        height: 100%;
        width: 100%;

        option {
          color: black;
        }
      }
    }

    .game-version {
    }
  }
}
</style>
