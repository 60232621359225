import logger from "../utils/logger"
import { getGlobalRankingsRequest, getSeasonalRankingsRequest, getWeeklyRankingsRequest, getCurrentSeasonRequest } from "../api/requests"
import { handleError } from "../utils/error"

export const rankingsModule = () => {
	// logger.debug("Initializing rankings store module...")

	return {
		namespaced: true,
		state: {
			rankingsFetching: true,
			globalRankings: [],
			seasonalRankings: [],
			weeklyRankings: [],
			season: -1
		},
		mutations: {
			rankingsLoading(state) {
				state.rankingsFetching = true
			},
			receivedGlobalRankings(state, global) {
				state.globalRankings = global.topFifty
				state.rankingsFetching = false
			},
			receivedSeasonalRankings(state, seasonal) {
				state.seasonalRankings = seasonal.topFifty
				state.rankingsFetching = false
			},
			receivedWeeklyRankings(state, weekly) {
				state.weeklyRankings = weekly.topFifty
				state.rankingsFetching = false
			},
			receivedSeasonNum(state, number) {
				state.season = number
			}
		},
		getters: {
			rankingsCurrentlyLoading: state => state.rankingsFetching,
			globalTopFifty: state => state.globalRankings,
			seasonalTopFifty: state => state.seasonalRankings,
			weeklyTopFifty: state => state.weeklyRankings,
			currentSeason: state => state.season,
		},
		actions: {
			async getGlobalRankings({ commit }) {
				try {
					commit("rankingsLoading")
					const results = await getGlobalRankingsRequest()
					commit("receivedGlobalRankings", results)
				} catch (e) {
					console.error("Error occurred when attempting to get global rankings", e)
					handleError(e)
				}
			},
			async getCurrentSeason({ commit }) {
				try {
					const results = await getCurrentSeasonRequest()
					if(results.length > 0)
						commit("receivedSeasonNum", results[0].current_season)
					else
						commit("receivedSeasonNum", -1)
				} catch (e) {
					console.error("Error occurred when attempting to get global rankings", e)
					handleError(e)
				}
			},
			async getSeasonalRankings({ commit }) {
				try {
					commit("rankingsLoading")
					const results = await getSeasonalRankingsRequest()
					commit("receivedSeasonalRankings", results)
				} catch (e) {
					console.error("Error occurred when attempting to get seasonal rankings", e)
					handleError(e)
				}
			},
			async getWeeklyRankings({ commit }) {
				try {
					commit("rankingsLoading")
					const results = await getWeeklyRankingsRequest()
					commit("receivedWeeklyRankings", results)
				} catch (e) {
					console.error("Error occurred when attempting to get weekly rankings", e)
					handleError(e)
				}
			},
		}
	}
}
