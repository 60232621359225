<template>
  <div class="screen" id="credits">
    <Panel
      class="credits-panel"
      :title="t('settingsCreditsButtonLabel')"
      :oncloseroute="'/settings'"
    >
      <template #panel-content>
        <ul>
          <li :key="credit.name" v-for="credit in credits">
            {{credit.name}}
            <span class="role" v-if="credit.role">- {{credit.role}}</span>
            <span v-if="credit.twitterLink">
              -
              <a
                target="_blank"
                rel="noopener noreferrer"
                :href="`https://twitter.com/${credit.twitterLink}`"
              >Twitter</a>
            </span>
            <span v-if="credit.websiteLink">
              -
              <a target="_blank" rel="noopener noreferrer" :href="credit.websiteLink">Website</a>
            </span>
          </li>
        </ul>
        <div class="logos">
          <a href="https://bitfoxgames.com/" target="_blank" rel="noopener noreferrer">
            <img src="../assets/bitfox-logo.png" alt="Bitfox Games Logo" />
          </a>
          <a href="https://otherocean.com/" target="_blank" rel="noopener noreferrer">
            <img src="../assets/oo-logo.png" alt="Other Ocean Logo" />
          </a>
        </div>
      </template>
    </Panel>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Panel from "../reusable-components/Panel"

export default {
	name: "Credits",
	components: {
		Panel
	},
	computed: {
		...mapGetters("i18n", ["t"])
	},
	data() {
		const credits = [
			{
				name: "Dave MacIntosh",
				role: "Programming / Design"
			},
			{
				name: "Cian Bottomley",
				role: "Programming / Design",
			},
			{
				name: "Daniel Eichler",
				role: "Programming / Design",
				twitterLink: "polatrite",
				websiteLink: "https://github.com/polatrite"
			},
			{
				name: "Patrick Williams",
				role: "Programming / Design",
				websiteLink: "https://ayetistudios.com",
				twitterLink: "acadianyeti"
			},
			{
				name: "Alex Bennett",
				role: "Programming / Design",
				twitterLink: "bennettor",
				websiteLink: "https://timetocode.com/"
			},
			{
				name: "Sam Stiles",
				role: "Programming / Design",
				twitterLink: "samueldev",
				websiteLink: "https://sammakes.games"
			},
			{ name: "Brandon Ogon", role: "Community Management / Design" },
			{ name: "Max Knechtel", role: "Project Lead / Design"},
			{ name: "Ben Thwaites", role: "Art" },
			{ name: "Nick Tremblay", role: "Art" },
			{ name: "And many wonderful QA staff from Other Ocean Interactive!" },
			{ name: "Additional sound effects from Zapsplat.com", websiteLink: "https://www.zapsplat.com"}
		]
		return {
			credits: credits.sort((a, b) => {
				return a.order > b.order
			})
		}
	}
} 
</script>


<style lang="scss" scoped>
#credits {
	.credits-panel {
		max-width: 700px;
		display: inline-block;
		ul {
			text-align: center;
			list-style: none;

			li {
				margin-top: 3px;
			}

			span.role {
				color: #c1c1c1;
			}
		}
		.logos {
			width: 100%;
			text-align: center;
			display: inline-block;
			img {
				transition: opacity 50ms linear;

				&:hover {
					opacity: 0.75;
				}
				margin: 20px 20px 0 20px;
			}
		}
	}
}
</style>

