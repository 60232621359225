<template>
  <div class="screen" id="verify-email">
    <Panel class="verify-email-panel" v-bind:class="{ loading: userDataFetching }">
      <template #panel-content>
        <LoadyBro v-if="userDataFetching"></LoadyBro>
        <div v-else class="forms">
			<h2>Email Verified!</h2>
			<p class="thanks">Thank you! :)</p>
        </div>
      </template>
    </Panel>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { some, values } from "lodash"
import Panel from "../reusable-components/Panel"
import EmbeddedDarkBox from "../reusable-components/EmbeddedDarkBox"
import Checkbox from "../reusable-components/Checkbox"
import Button from "../reusable-components/Button"
import LoadyBro from "../reusable-components/LoadyBro"

export default {
	name: "VerifyEmail",
	components: {
		Panel,
		EmbeddedDarkBox,
		Checkbox,
		Button,
		LoadyBro
	},
	computed: {
		...mapGetters("user", ["userDataFetching"])
	}
}
</script>


<style lang="scss">
#verify-email {
	.verify-email-panel {
		position: relative;

		&.loading {
			width: 840px;
			height: 559px;
		}

		.loady-bro {
			position: absolute;
			top: 65%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		.forms {
			display: flex;
			flex-direction: column;
			text-align: center;
		}
	}
}
</style>

