
const swapSymbolsForChar = (stringToSanitize) => {
    let result = ""
    for(let i = 0; i < stringToSanitize.length; i++) {
        let currChar = stringToSanitize[i]
        let foundSymbol = false
        for(let j = 0; j < symbolCharLookup.length && !foundSymbol; j++) {
            
            symbolCharLookup[j].possibilities.forEach(possibleSymbol => {
                if(possibleSymbol == currChar) {
                    //console.log("replacing symbol with equivalent char")
                    result = result + symbolCharLookup[j].equivalent
                    foundSymbol = true
                }
            })
            
        }

        if(!foundSymbol)
            result = result + currChar
    }

    return result
}

const removeSuspiciousDoubles = (stringToSanitize) => {

    // Check for extra characters at the start and end of the string.  Currently only supports that
    // Doing this for sanitation problems where fuck would be caught, but fuckkk would avoid it.  
    // Wont support fuuck
    let startSubstr = 0
    let strLength = stringToSanitize.length 
    let endSubstr = strLength - 1
    let startChar = stringToSanitize.charAt(0)
    let endChar = stringToSanitize.charAt(strLength - 1)
    while(startSubstr + 1 < strLength && startChar == stringToSanitize.charAt(startSubstr + 1)) {
        startSubstr = startSubstr + 1
    }
    while(endSubstr - 1 >= 0 && endChar == stringToSanitize.charAt(endSubstr - 1)) {
        endSubstr = endSubstr - 1
    }
    
    return startSubstr > endSubstr ? stringToSanitize : stringToSanitize.substring(startSubstr, endSubstr + 1)
}

const sanitizeSymbolsAndDoubles = (stringToSanitize) => {
    let result = removeSuspiciousDoubles(stringToSanitize)
    result = swapSymbolsForChar(stringToSanitize)
    return result
}

// We will have to update this as time goes on I guess - Patrick 2022-04-20
const symbolCharLookup = [
    {
        equivalent: 'a',
        possibilities: ['@', 'ⓐ', 'â', 'ä', 'à', 'å', 'Ä', 'Å', 'á', 'ª', 'α']
    },
    {
        equivalent: 'ae',
        possibilities: ['æ', 'Æ']
    },
    {
        equivalent: 'b',
        possibilities: ['ß']
    },
    {
        equivalent: 'c',
        possibilities: ['ç', '¢', 'с']
    },
    {
        equivalent: 'd',
        possibilities: []
    },
    {
        equivalent: 'e',
        possibilities: ['é', 'ê', 'ë', 'è', 'É', '£', 'Σ', 'ε']
    },
    {
        equivalent: 'f',
        possibilities: ['ƒ']
    },
    {
        equivalent: 'g',
        possibilities: ['ℊ', '₲', 'g̃', '⅁', 'Ǥ', 'ǥ', 'ģ', 'Ģ', 'ģ', 'ğ', 'ĝ', 'ġ', 'ǵ']
    },
    {
        equivalent: 'h',
        possibilities: []
    },
    {
        equivalent: 'i',
        possibilities: ['ï', 'î', 'ì', 'í', '!']
    },
    {
        equivalent: 'j',
        possibilities: []
    },
    {
        equivalent: 'k',
        possibilities: []
    },
    {
        equivalent: 'l',
        possibilities: []
    },
    {
        equivalent: 'm',
        possibilities: []
    },
    {
        equivalent: 'n',
        possibilities: ['ñ', 'Ñ', 'π', '∩']
    },
    {
        equivalent: 'o',
        possibilities: ['ô', 'ö', 'ò', 'Ö', 'ó', 'º', 'σ', 'Φ', 'Θ', 'φ']
    },
    {
        equivalent: 'oo',
        possibilities: ['∞']
    },
    {
        equivalent: 'p',
        possibilities: []
    },
    {
        equivalent: 'Pts',
        possibilities: ['₧']
    },
    {
        equivalent: 'q',
        possibilities: []
    },
    {
        equivalent: 'r',
        possibilities: ['Γ']
    },
    {
        equivalent: 's',
        possibilities: ['$', '§']
    },
    {
        equivalent: 't',
        possibilities: ['τ', '+']
    },
    {
        equivalent: 'u',
        possibilities: ['û', 'ù', 'ü', 'Ü', 'ú', 'µ']
    },
    {
        equivalent: 'v',
        possibilities: []
    },
    {
        equivalent: 'w',
        possibilities: []
    },
    {
        equivalent: 'x',
        possibilities: []
    },
    {
        equivalent: 'y',
        possibilities: ['ÿ', '¥']
    },
    {
        equivalent: 'z',
        possibilities: []
    },
]


export default sanitizeSymbolsAndDoubles