<template>
  <div class="screen" id="login-signup">
    <Panel
      class="login-signup-panel"
      v-bind:class="{ loading: userDataFetching }"
    >
      <template #panel-content>
        <LoadyBro v-if="userDataFetching"></LoadyBro>
        <div v-else class="forms">
          <EmbeddedDarkBox class="lighter signup-form">
            <template #dark-box-content>
              <div class="form-title">Sign Up</div>
              <p class="error" v-if="register.error">{{ register.error }}</p>
              <div class="input-group">
                <div class="input-label">
                  <label for="register-username">Username</label>
                </div>
                <div class="input">
                  <input
                    v-on:keyup.enter="
                      () => {
                        registerFormSubmit()
                      }
                    "
                    v-model="register.username"
                    maxlength="14"
                    id="register-username"
                    type="text"
                  />
                </div>
              </div>
              <div class="input-group">
                <div class="input-label">
                  <label for="register-email">Email</label>
                </div>
                <div class="input">
                  <input
                    v-on:keyup.enter="
                      () => {
                        registerFormSubmit()
                      }
                    "
                    v-model="register.email"
                    id="register-email"
                    type="text"
                  />
                </div>
              </div>
              <div class="input-group">
                <div class="input-label">
                  <label for="register-confirm-email">Confirm Email</label>
                </div>
                <div class="input">
                  <input
                    v-on:keyup.enter="
                      () => {
                        registerFormSubmit()
                      }
                    "
                    v-model="register.emailConfirm"
                    id="register-confirm-email"
                    type="text"
                  />
                </div>
              </div>
              <div class="input-group">
                <div class="input-label">
                  <label for="register-password">Password</label>
                </div>
                <div class="input">
                  <input
                    v-on:keyup.enter="
                      () => {
                        registerFormSubmit()
                      }
                    "
                    v-model="register.password"
                    maxlength="64"
                    id="register-password"
                    type="password"
                  />
                </div>
              </div>
              <div class="input-group">
                <div class="input-label">
                  <label for="register-confirm-password"
                    >Confirm Password</label
                  >
                </div>
                <div class="input">
                  <input
                    v-on:keyup.enter="
                      () => {
                        registerFormSubmit()
                      }
                    "
                    v-model="register.passwordConfirm"
                    maxlength="64"
                    id="register-confirm-password"
                    type="password"
                  />
                </div>
              </div>
              <div class="checkbox-group">
                <div class="checkbox-indicator">
                  <Checkbox
                    :checked="register.agreedToTerms"
                    :onclickfn="
                      () => {
                        toggleRegisterFormCheckbox('agreedToTerms')
                      }
                    "
                  ></Checkbox>
                </div>
                <div class="checkbox-label">
                  I agree to the
                  <a href="/terms" target="_blank">Terms &amp; Conditions</a>
                </div>
              </div>
              <div class="checkbox-group">
                <div class="checkbox-indicator">
                  <Checkbox
                    :checked="register.agreedToPromotion"
                    :onclickfn="
                      () => {
                        toggleRegisterFormCheckbox('agreedToPromotion')
                      }
                    "
                  ></Checkbox>
                </div>
                <div class="checkbox-label">
                  It's OK for Other Ocean Interactive to contact me when we update bruh and
                  release new games
                </div>
              </div>
              <div class="form-submit-btn">
                <Button
                  :color="'green'"
                  :onclickfn="
                    () => {
                      registerFormSubmit()
                    }
                  "
                  :text="'Sign Up'"
                ></Button>
              </div>
            </template>
          </EmbeddedDarkBox>

          <EmbeddedDarkBox class="lighter login-form">
            <template #dark-box-content>
              <div class="form-title">Log In</div>
              <p class="error" v-if="login.error">{{ login.error }}</p>
              <div class="input-group">
                <div class="input-label">
                  <label for="login-email">Email</label>
                </div>
                <div class="input">
                  <input
                    v-on:keyup.enter="
                      () => {
                        loginFormSubmit()
                      }
                    "
                    v-model="login.email"
                    id="login-email"
                    type="text"
                  />
                </div>
              </div>
              <div class="input-group">
                <div class="input-label">
                  <label for="login-password">Password</label>
                </div>
                <div class="input">
                  <input
                    v-on:keyup.enter="
                      () => {
                        loginFormSubmit()
                      }
                    "
                    v-model="login.password"
                    id="login-password"
                    type="password"
                  />
                </div>
              </div>
              <router-link to="/forgot-password"
                >Forgot your password?</router-link
              >
              <div class="form-submit-btn">
                <Button
                  :color="'green'"
                  :onclickfn="
                    () => {
                      loginFormSubmit()
                    }
                  "
                  :text="'Log In'"
                ></Button>
              </div>
            </template>
          </EmbeddedDarkBox>
        </div>
      </template>
    </Panel>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { some, values } from "lodash"
import Panel from "../reusable-components/Panel"
import EmbeddedDarkBox from "../reusable-components/EmbeddedDarkBox"
import Checkbox from "../reusable-components/Checkbox"
import Button from "../reusable-components/Button"
import LoadyBro from "../reusable-components/LoadyBro"

export default {
  name: "LoginSignup",
  components: {
    Panel,
    EmbeddedDarkBox,
    Checkbox,
    Button,
    LoadyBro
  },
  data() {
    const rand = Math.floor(Math.random() * 1000)
    return {
      login: {
        error: false,
        email: "",
        password: ""
      },
      register: {
        error: false,
        username: this.$store.getters["user/username"] || "",
        email: "",
        emailConfirm: "",
        password: "",
        passwordConfirm: "",
        agreedToTerms: false,
        agreedToPromotion: true
      }
    }
  },
  computed: {
    ...mapGetters("user", ["userDataFetching", "username"])
  },
  methods: {
    ...mapActions("user", ["submitLogin", "submitRegister"]),
    toggleRegisterFormCheckbox(check) {
      this.register[check] = !this.register[check]
    },
    loginFormSubmit() {
      this.login.error = false

      let normalizedFormValues = {
        emailAddress: this.login.email.toLowerCase(),
        password: this.login.password
      }

      if (
        some(values(normalizedFormValues), v => {
          return v === undefined || v === ""
        })
      ) {
        return (this.login.error = "You need to fill out the entire form.")
      }

      this.submitLogin(normalizedFormValues)
    },
    registerFormSubmit() {
      this.register.error = false

      let normalizedFormValues = {
        username: this.register.username,
        emailAddress: this.register.email.toLowerCase(),
        emailAddressConfirmation: this.register.emailConfirm.toLowerCase(),
        password: this.register.password,
        passwordConfirmation: this.register.passwordConfirm,
        agreedToTerms: this.register.agreedToTerms,
        agreedToPromotion: this.register.agreedToPromotion,
        sourceGame: "bruh.io"
      }

      if (
        some(values(normalizedFormValues), v => {
          return v === undefined || v === ""
        })
      ) {
        return (this.register.error = "You need to fill out the entire form.")
      }

      if (
        normalizedFormValues.username.length > 14 ||
        normalizedFormValues.username.length < 3
      ) {
        return (this.register.error =
          "Your username must be between 3 and 14 characters in length.")
      }

      if (
        normalizedFormValues.emailAddress !==
        normalizedFormValues.emailAddressConfirmation
      ) {
        return (this.register.error = "Your email fields don't match.")
      }

      if (
        normalizedFormValues.password !==
        normalizedFormValues.passwordConfirmation
      ) {
        return (this.register.error = "Your password fields don't match.")
      }

      if (normalizedFormValues.agreedToTerms !== true) {
        return (this.register.error =
          "You must agree to the terms and conditions in order to sign up.")
      }

      this.submitRegister(normalizedFormValues)
    }
  }
}
</script>

<style lang="scss">
@import "./src/assets/colors.scss";

@media only screen and (max-width: 958px) {
  #login-signup {
    .login-signup-panel {
      transform: scale(0.75);
    }
  }
}

@media only screen and (max-width: 750px) {
  #login-signup {
    .login-signup-panel {
      transform: scale(0.5);
    }
  }
}

@media only screen and (max-width: 575px) {
  #login-signup {
    .login-signup-panel {
      transform: scale(0.4);
    }
  }
}

#login-signup {
  .login-signup-panel {
    position: relative;
    min-width: 821px;

    router-link,
    a {
      color: $link-color-on-dark;
    }

    &.loading {
      width: 840px;
      height: 559px;
    }

    .loady-bro {
      position: absolute;
      top: 65%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .signup-form {
      margin-right: 5px;
    }

    .login-form {
      margin-left: 5px;
    }

    .forms {
      display: flex;
    }

    .signup-form,
    .login-form {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 30px 30px 50px 30px;
      min-width: 380px;
      max-width: 380px;
      // align-items: center;
      // justify-content: center;

      p.error {
        text-align: center;
        color: red;
        max-width: inherit;
      }

      .form-title {
        text-align: center;
        font-size: 48px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      .input-group {
        display: flex;
        height: 35px;
        margin-bottom: 10px;

        .input-label {
          align-self: center;
          width: 105px;
          text-align: left;

          label {
            color: #d9d9d9;
          }
        }
        .input {
          margin-left: auto;
          display: flex;

          input {
            align-self: center;
            background-color: #262628;
            border: none;
            height: 20px;
            font-size: 14px;
            color: #fff;
            padding: 0 5px;
          }
        }
      }

      .checkbox-group {
        display: flex;
        height: 35px;
        margin-top: 10px;
        padding-left: 15px;
        .checkbox-indicator {
          align-self: center;
        }
        .checkbox-label {
          font-size: 14px;
          padding-left: 20px;
          align-self: center;
        }
      }

      .form-submit-btn {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);

        button {
          height: auto;
          .button-inner {
            font-size: 24px;
          }
          // height: 40px;
        }
      }
    }
  }
}
</style>
