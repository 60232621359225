<template>
  <div class="mission-box" :class="{ 'bigly-pulse': !hasBattlepass && false, 'constrain-height': !hasBattlepass && false }"> <!-- Set to false 2022-04-26, consider removing - Patrick -->
    <h3 v-if="dailyMissions.length">Today's Daily Missions:</h3>
    <div v-if="dailyMissions.length" class="mission-rows">
      <transition-group name="list" tag="div">
        <div
          class="mission-row list-item"
          v-for="mission in dailyMissions"
          :key="mission.mission_unique_id"
          :class="{ complete: mission.is_complete }"
          @click="tryMarkConfirmed($event, mission)"
        >
          <div v-if="forteSystemDown" class="forte-down-overlay">
            Missions can't be completed right now, check back later!
          </div>
          <div>
            <div class="mission-desc">
              {{ mission.description }}
              <div class="mission-reward">
                <div style="display:inline-block;">
                  <SkinImage
                    id="coin-image"
                    class="oo-coin-icon"
                    :skin-data="{ skinCode: `coin_icon` }"
                    :skin-image-type="'sprite'"
                  ></SkinImage>
                </div>
                {{mission.reward}}  
              </div>
            </div>
          </div>

          <ProgressBar
            class="progress-bar"
            min-value="0"
            max-value="100"
            :current-value="mission.progress_percent"
            :label-text="getLabelText(mission)"
            label-color="#fff"
            :foreground-color="mission.is_complete ? '#488f4b' : '#e5b200'"
            :animated-foreground="mission.is_complete"
            :background-color="mission.is_complete ? '#19591c' : '#bb6f00'"
            :border-color="mission.is_complete ? '#153913' : '#bb6f00'"
          ></ProgressBar>
        </div>
      </transition-group>
    </div>
    <div v-else>
      {{ t("battlepassCompletedAllMissions") }}
    </div>
    <!-- UIncomment these to re-integrate visual battle pass missions - Patrick 2022-04-26  -->

    <!--
    <h3 v-if="hasBattlepass">Battle Pass Missions:</h3>
    <div v-if="hasBattlepass" class="mission-rows" style="margin-top: 20px;">
      <transition-group v-if="weeklyMissions.length" name="list" tag="div">
        <div
          class="mission-row list-item"
          v-for="mission in weeklyMissions"
          :key="mission.mission_unique_id"
          :class="{ complete: mission.is_complete }"
          @click="tryMarkConfirmed($event, mission)"
        >
          <div v-if="forteSystemDown" class="forte-down-overlay">
            Missions can't be completed right now, check back later!
          </div>
          <div class="mission-desc">{{ mission.description }}</div>
          <ProgressBar
            class="progress-bar"
            min-value="0"
            max-value="100"
            :current-value="mission.progress_percent"
            :label-text="getLabelText(mission)"
            label-color="#fff"
            :foreground-color="mission.is_complete ? '#488f4b' : '#e5b200'"
            :animated-foreground="mission.is_complete"
            :background-color="mission.is_complete ? '#19591c' : '#bb6f00'"
            :border-color="mission.is_complete ? '#153913' : '#bb6f00'"
          ></ProgressBar>
        </div>
      </transition-group>
      <div v-else>{{ t("battlepassCompletedAllMissions") }}</div>
    </div>

    <div v-if="!hasBattlepass && false" class="battlepass-teaser">
      <router-link to="/battlepass">
        <img class="battlepass-icon weeble-wobble" src="../assets/battlepass-season-5.png" />
        <div class="blurb">{{ t("buyBattlepassDontMissOut") }}</div>
      </router-link>
    </div>
    --> <!-- Battle pass removed from missions 2022-04-26 -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import ProgressBar from "../reusable-components/ProgressBar"
import SkinImage from "../reusable-components/SkinImage"
import AnimatedNumber from "animated-number-vue"

export default {
  components: {
    ProgressBar,
    SkinImage,
    AnimatedNumber
  },
  props: [
    "animationClass",
    "minValue",
    "maxValue",
    "currentValue",
    "foregroundColor",
    "backgroundColor",
    "borderColor",
    "labelText",
    "labelColor"
  ],
  methods: {
    ...mapActions("user", ["markMissionConfirmed"]),
    getLabelText(mission) {
      let text = ""
      if (mission.is_complete) {
        text = "✓"
      } else {
        text = mission.progress_current + "/" + mission.progress_max
      }
      return text
    },
    tryMarkConfirmed(event, mission) {
      const originElement = event.target

      if (this.forteSystemDown) {
        // console.info("Not marking as complete because Forte is having troubles")
      } else {
        this.markMissionConfirmed({ mission, originElement })
      }
    }
  },
  computed: {
    ...mapGetters("i18n", ["t"]),
    ...mapGetters("user", ["dailyMissions", "weeklyMissions"]),
    ...mapGetters("battlepass", ["hasBattlepass"]),
    ...mapGetters("shop", ["forteSystemDown"])
  }
}
</script>

<style lang="scss" scoped>
.mission-box {
  height: 100%;

  .mission-rows {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  /* start confirmation animations */
  .list-item {
    transition-duration: 0.7s;
    transition-timing-function: ease-in;
    transition-property: position, opacity, transform;
  }

  .list-leave-active {
    position: absolute;
  }

  .list-enter,
  .list-leave-to {
    opacity: 0;
    transform: translateX(500px);
  }
  /* end confirmation animations */

  .mission-row {
    font-size: 14px;
    box-sizing: content-box;
    padding: 7px 3px;
    margin: 3px 3px 5px 3px;
    border-radius: 3px;
    position: relative;

    .forte-down-overlay {
      display: none;
      padding: 0 15px;
      background-color: rgba(0, 0, 0, 0.8);
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: darken(red, 10%);
      text-align: center;
    }

    &.complete {
      cursor: pointer;

      &:hover:not(.list-leave-active) {
        // don't have a hover on confirmation animations
        box-shadow: inset 0px 0px 1px 1px #79f17e, 0px 0px 1px 1px #79f17e;
      }

      &:hover {
        .forte-down-overlay {
          display: flex;
        }
      }
    }
    
    .mission-reward {
      float: right;
      text-align: right;
      .oo-coin-icon {
        pointer-events: none;
        left: 0px;
        top: 3px;
      }
    }

    .mission-desc {
      padding-left: 3px;
    }

    .mission-progress {
      height: 18px;
      width: 100%;
      background-color: #0e0e0e;
      position: relative;

      .mission-progress-numbers {
        position: absolute;
        top: 1px;
        left: 5px;
      }

      .mission-progress-inner {
        height: 100%;
        border: 2px solid #a34900;
        background-color: #e5b200;
      }
    }
  }

  .battlepass-teaser {
    text-align: center;
    position: relative;

    a {
      text-decoration: none;
      color: inherit;
    }

    .battlepass-icon {
		height: 150px;
		position: absolute;
		top: 0;
		transform: translate(-50%, -20px);
    }

    .blurb {
		position: absolute;
		top: 140px;
    }

	.weeble-wobble {
		animation: wobble 1.5s infinite;
	}
  }

  .bigly-pulse, &.bigly-pulse {
	animation: bigly-pulse 1.5s infinite;
  }

  &.constrain-height {
	max-height: 266px !important;
  }

  @keyframes wobble {
	0%, 100% { transform: translate(-50%, -20px) rotate(-6deg); }
	50% { transform: translate(-50%, -20px) rotate(6deg); }
  }

  @keyframes bigly-pulse {
	0%, 100% { transform: scale(1); }
	50% { transform: scale(1.1) rotate(-2deg); }
  }
}
</style>
