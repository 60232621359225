import Filter from "bad-words"

const veryBadWords = [
    'fuck',
    'shit',
    'cunt',
    'faggot',
    'bitch',
    'bastard',
    'nigger',
    'nigga',
    'whore',
    'chink',
	'wetback',
    'spick',
	
]

const additionalProfanityCheck = (originalFilter, string) => {
    let foundResult = false

    veryBadWords.forEach(cuss => {
        // Builds a regex for each cuss to detect duplicate letters
        let testCuss = '('
        for(let letter = 0; letter < cuss.length; letter++) {
            testCuss = testCuss + cuss[letter] + '+'
        }
        testCuss += ')'
        let testRegex = new RegExp(testCuss, 'gi')
        if(testRegex.test(string)) {
            foundResult = true
        }
    })
    if(!foundResult) {
        originalFilter.list.forEach(cuss => {
            // Not testing with cusses below 4 chars due to too many things getting blocked
            if(!originalFilter.exclude.includes(cuss) && cuss.length > 3) {
                let testRegex = new RegExp( cuss.replace(/(\W)/g, '\\$1'), 'gi')
                if(testRegex.test(string)) {
                    foundResult = true
                }
            }
    
        })
    }
    return foundResult
}

/*
// Old Profanity Filter that processes the entire bad words list to match the string
const additionalProfanityCheck = (originalFilter, string) => {
    let foundResult = false
    let foundException = false
    const specialChars = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/)

    originalFilter.list.forEach(cuss => {
        // Builds a regex for each cuss to detect duplicate letters
        if(!specialChars.test(cuss)) {
            let testCuss = '('
            for(let letter = 0; letter < cuss.length; letter++) {
                testCuss = testCuss + cuss[letter] + '+'
            }
            testCuss += ')'
            let testRegex = new RegExp(testCuss, 'gi')
            if(testRegex.test(string)) {
                // Catches excluded words
                let hasCuss = new RegExp(cuss, 'gi')
                originalFilter.exclude.forEach(word => {
                    let hasExcluded = new RegExp(word, 'gi')
                    // returns if the cuss is part of an excluded word that is present in the string,
                    // or if the cuss has an excluded word in it
                    // FIX: needs changing to check if excluded word is present in a word, not string, very rare exception
                    if((hasCuss.test(word) && hasExcluded.test(string)) || hasExcluded.test(cuss)) {
                        foundException = true
                        return
                    }
                })
                if(foundException) {
                    foundException = false
                    return
                }
                foundResult = true
            }
        }
    })
    return foundResult
}*/

const filter = new Filter()
filter.addWords(...[
	"foesio",
	"foes.io",
	"foes",
	"survivio",
	"surviv.io",
	"surviv",
	"hitler",
	"stalin",
	"Rape",
	"Faggot",
	"Hitler",
	"Nigger",
	"Nigga",
	"Slut",
	"Whore",
	"Spic",
	"rape",
	"nig",
	"nigr",
	"cunt",
	"hebe",
	"heeb"
])
filter.removeWords(...[
	"spawn",
	"hell",
	"god",
	"Dick",
	"Slunk",
	"hells",
	"hello",
	"sexy",
	"sadist",
	"tit",
	"screw",
	"yeet",
	"destroy",
	"destroyed",
	"popular",
	"ass",
	"played",
	"willy",
	"willies",
	"wank",
	"wanky",
	"yed"
])

module.exports = {
    filter,
    additionalProfanityCheck
}
