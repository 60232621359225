<template>
  <div class="screen" id="payment-success">
    <Panel class="payment-success-panel" :title="t('success')">
      <template #panel-content>
        <p>
          {{ t('pmtSuccessThanks') }}
          <br />
          {{t('pmtSuccessGoTo')}}
          <router-link to="/profile">{{t('pmtSuccessProfilePage')}}</router-link>
          {{ t('pmtSuccessToEquip') }}
        </p>
      </template>
    </Panel>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Panel from "../reusable-components/Panel"

export default {
	name: "PaymentSuccess",
	components: {
		Panel
	},
	computed: {
		...mapGetters("i18n", ["t"])
	}
}
</script>


<style lang="scss" scoped>
#payment-success {
	.payment-success-panel {
		max-width: 500px;
	}
}
</style>

