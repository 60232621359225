<template>
  <div class="screen" id="forgot-password">
    <Panel class="forgot-password-panel" v-bind:class="{loading: userDataFetching}" :oncloseroute="'/login-signup'">
      <template #panel-content>
        <LoadyBro v-if="userDataFetching"></LoadyBro>
        <div v-else class="forms">
          <EmbeddedDarkBox class="lighter forgot-password-form">
            <template #dark-box-content>
              <div class="form-title">Forgot Password</div>
              <p class="error" v-if="forgotPassword.error">{{forgotPassword.error}}</p>
              <div class="input-group">
                <div class="input-label">
                  <label for="forgot-password-email-address">Email Address</label>
                </div>
                <div class="input">
                  <input
                    v-on:keyup.enter="() => { forgotPasswordFormSubmit() }"
                    v-model="forgotPassword.emailAddress"
                    id="forgot-password-email-address"
                    type="text"
                  />
                </div>
              </div>
              <div class="form-submit-btn">
                <Button :color="'green'" :onclickfn="() => { forgotPasswordFormSubmit() }" :text="'Send Reset Email'"></Button>
              </div>
            </template>
          </EmbeddedDarkBox>
        </div>
      </template>
    </Panel>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { some, values } from "lodash"
import Panel from "../reusable-components/Panel"
import EmbeddedDarkBox from "../reusable-components/EmbeddedDarkBox"
import Checkbox from "../reusable-components/Checkbox"
import Button from "../reusable-components/Button"
import LoadyBro from "../reusable-components/LoadyBro"

export default {
	name: "ForgotPassword",
	components: {
		Panel,
		EmbeddedDarkBox,
		Checkbox,
		Button,
		LoadyBro
	},
	data() {
		return {
			forgotPassword: {
				error: false,
				emailAddress: ""
			}
		}
	},
	computed: {
		...mapGetters("user", ["userDataFetching"])
	},
	methods: {
		...mapActions("user", ["submitForgotPassword"]),
		forgotPasswordFormSubmit() {
			this.forgotPassword.error = false

			let normalizedFormValues = {
				emailAddress: this.forgotPassword.emailAddress
			}

			if (
				some(values(normalizedFormValues), v => {
					return v === undefined || v === ""
				})
			) {
				return (this.forgotPassword.error = "You need to fill out the entire form.")
			}

			this.submitForgotPassword(normalizedFormValues)
		}
	}
}
</script>


<style lang="scss">
#forgot-password {
	.forgot-password-panel {
		position: relative;

		&.loading {
			width: 840px;
			height: 559px;
		}

		.loady-bro {
			position: absolute;
			top: 65%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		.forms {
			display: flex;
		}

		.forgot-password-form {
			position: relative;
			display: flex;
			flex-direction: column;
			padding: 30px 30px 50px 30px;
			min-width: 380px;

			p.error {
				text-align: center;
				color: red;
			}

			.form-title {
				text-align: center;
				font-size: 32px;
				text-transform: uppercase;
				margin-bottom: 10px;
			}

			.input-group {
				display: flex;
				height: 35px;
				margin-bottom: 10px;

				.input-label {
					align-self: center;
					width: 105px;
					text-align: left;

					label {
						color: #d9d9d9;
					}
				}
				.input {
					margin-left: auto;
					display: flex;

					input {
						align-self: center;
						background-color: #262628;
						border: none;
						height: 20px;
						font-size: 14px;
						color: #fff;
						padding: 0 5px;
					}
				}
			}

			.form-submit-btn {
				position: absolute;
				bottom: -20px;
				left: 50%;
				transform: translateX(-50%);

				button {
					height: auto;
					.button-inner {
						font-size: 24px;
					}
				}
			}
		}
	}
}
</style>

