<template>
  <div class="loady-bro">
    <div class="loading-runner"></div>
    <div class="loading-gun"></div>
    <div class="loading-hands"></div>
  </div>
</template>

<script>
export default {
	name: "LoadyBro"
}
</script>

<style lang="scss"></style>
