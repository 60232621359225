<template>
	<div class="progress-bar" :class="animationClass">
		<div class="icon">
			<slot name="icon"></slot>
		</div>
		<div class="bar" ref="bar">
			<div class="foreground" 
				:class="{
					'animated': animatedForeground,
				}"
				:style="{ 
					'width': `${barWidth}px`, 
					'border': foregroundBorder, 
					'background-color': foregroundColor,
				}">
			</div>
			<div class="label" :style="{
				'color': labelColor || 'black'
			}">{{ labelText }}</div>
			<div class="background"></div>
		</div>
	</div>
</template>

<script>
export default {
	components: {
	},
	props: [
		'animationClass',
		'minValue',
		'maxValue',
		'currentValue',
		'foregroundColor',
		'backgroundColor',
		'animatedForeground',
		'borderColor',
		'labelText',
		'labelColor',
	],
	computed: {
		barMaxWidth: function() {
			if(!this.refsAreMounted) {
				return 100
			}
			return this.$refs.bar.clientWidth
		},
		barWidth: function() {
			return this.percentValue * this.barMaxWidth
		},
		percentValue: function() {
			return this.currentValue / (this.maxValue - this.minValue)
		},
		foregroundBorder: function() {
			return (this.currentValue > this.minValue) ? `2px solid ${this.borderColor}` : '0'
		}
	},
	data() {
		return {
			refsAreMounted: false
		}
	},
	mounted() {
		this.refsAreMounted = true
	},
}
</script>

<style lang="scss" scoped>
.progress-bar {
    display: flex;
    padding: 2px;
	width: 100%;

	.icon {
		padding-right: 4px;
		img {
			position: relative;
			image-rendering: pixelated;
			image-rendering: -moz-crisp-edges;
			image-rendering: crisp-edges;
			width: 24px;
		}
	}

	.bar {
		width: 100%;
		position: relative;

		.foreground {
			position: absolute;
			width: 75%;
			height: 22px;
			background-color: #105870;
			border: 0;

			transition: 1.0s ease-in-out;
			transition-property: width;

			&.animated {
				&:after {
					content: "";
					position: absolute;
					top: 0; left: 0; bottom: 0; right: 0;
					background-image: linear-gradient(
						to right, 
						rgba(255, 255, 255, .2) 0%, 
						transparent 25%, 
						rgba(255, 255, 255, .2) 75%,
					);
					z-index: 1;
					background-size: 100px 50px;
					animation: move 4s linear infinite;
					border-top-right-radius: 8px;
					border-bottom-right-radius: 8px;
					border-top-left-radius: 20px;
					border-bottom-left-radius: 20px;
					overflow: hidden;
				}

				@keyframes move {
					0% {
						background-position: 0 0;
					}
					100% {
						background-position: 100px 50px;
					}
				}
			}
		}

		.background {
			width: 100%;
			height: 22px;
			background-color: rgba(26, 26, 26, 0.8);
			border: 2px solid rgba(26, 26, 26, 0.8);
		}

		.label {
			position: absolute;
			height: 24px;
			line-height: 24px;
			padding-left: 6px;
		}
	}

	&.slow-animation {
		.bar {
			.foreground {
				transition: 1.5s ease-in-out;
				transition-property: width;
			}
		}
	}

	&.fast-animation {
		.bar {
			.foreground {
				transition: 0.1s linear;
				transition-property: width;
			}
		}
	}
}


</style>
