const randomGameTitleAcronymOptions = [
	"Unbridled Harpischords",
	"Unhappy Hippopotami",
	"Universe-trekking Hillbillies",
	"Unanimous Haters",
	"Uninspired Hippies",
	"Undercover Hamsters",
	"Underrated Helicopters",
	"Unionized Hipsters",
	"Unbelievable Holiness",
	"Ultimate Hangover",
	"Uncomfortable High-Fives",
	"Uhhh Hey",
	"Uhhh Heck",
	"Unicorn Horns",
	"Uranium Ham",
	"Unattractive Hercules",
	"Unbound Hyenas",
	"Unnecessary Hostility",
	"Uncanny He-Man",
	"Underwater Horsemanship",
	"Unfazed Haunts",
	"Unhappy Halestorm",
	"Uninspiring Halifax",
	"Universal Healthcare",
	"Unkempt Holsters",
	"Unlimited Hemp",
	"Unlicensed Handyman",
	"Unlucky Horsemen",
	"Underwear Heretics",
	"Ulterior Hackerator",
	"Unhappy Happiness",
	"Unwanted Hygiene",
	"Unwashed Humans",
	"Unlocked Hayabusa",
	"Unlike Halo",
	"Uncensored Hobos",
	"UltraHot",
	"Undercooked Hamburgers",
	"Unity Help",
	"Unsteamed Hams",
	"Upsetting Hedgehog",
	"Unlimited Hummus",
	"Unending Hunger",
	"Unlawful Habitation",
	"Unforgotten Harambe",
	"Urawizard Harry",
	"Unsanctioned Hootenanny",
	"Unicycle Hyperdrive",
	"Uhhhhh.....",
	"Unsung Heroes",
	"Unparalleled Hype",
	"🤔",
	"👉😎👉",
]

const chooseRandomAcronym = () => {
	return randomGameTitleAcronymOptions[
		Math.floor(Math.random() * randomGameTitleAcronymOptions.length)
	]
}

export default chooseRandomAcronym
