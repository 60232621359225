<template>
	<div class="screen" id="battlepass">
		<div
			v-if="confirmModalVisible"
			class="shop-confirm-purchase-overlay"
			v-bind:class="{ visible: confirmModalVisible }"
		>
			<div class="confirm-purchase-modal">
				<div v-if="shopFetching || userDataFetching" class="modal-loading">
					<LoadyBro></LoadyBro>
					<p>{{ t('pleaseWaitProcessingPurchase') }}</p>
				</div>
				<div v-else class="modal-not-loading">
					<div class="close" v-on:click="()=>{ hideConfirmPurchaseModal() }">
						<SkinImage
							class="close-icon"
							:skin-data="{ skinCode: 'close_icon' }"
							:skin-image-type="'sprite'"
						></SkinImage>
					</div>

					<div class="modal-title">
						<h1>{{ currentSeasonBattlepass.name }}</h1>
						<h2>
							<img src="../assets/battlepass.png">
						</h2>
						<h2>500 coins</h2>
						<h2>{{ t('shopConfirmPurchase') }}</h2>
					</div>

					<div class="purchase-btn">
						<Button
							class="buy-btn"
							:color="'green'"
							:onclickfn="()=>{ purchaseBattlepass() }"
							:text="t('shopBuyNow')"
						></Button>
					</div>
				</div>
			</div>
		</div>

		<Panel
			v-if="hasBattlepass"
			class="battlepass-panel"
			:title="'Battlepass'"
			v-bind:class="{ 'loading': battlepassIsLoading || shopFetching }"
		>
			<template #panel-content>
				<LoadyBro v-if="battlepassIsLoading || shopFetching"></LoadyBro>
				<div v-else class="battlepass-contents">
					<EmbeddedDarkBox class="bp-top lighter">
						<template #dark-box-content>
							<div class="user-info">
								<div class="username-and-level">
									<h2 class="un">{{username}}</h2>
									<h3 class="level">Level {{level}}</h3>
								</div>
								<ProgressBar
									class="progress-bar"
									min-value="0"
									max-value="100"
									:current-value="experiencePercentTilNextLevel"
									:label-text="experiencePercentTilNextLevel + '%'"
									label-color="#fff"
									foreground-color="#e5b200"
									background-color="#272727"
									border-color="#bb6f00"
								>
								</ProgressBar>
								<div
									class="xp"
								>{{relativeExperienceEarnedInCurrentLevel}}/{{relativeExperienceForNextLevel}} xp</div>
							</div>
							<div class="bp-info">
								<div class="season-title">
									<h2>{{battlepassName}}</h2>
								</div>
								<div class="time-left">
									Ends in:
									<img src="../assets/clock_icon.png" />
									{{timeRemainingString}}
								</div>
							</div>
						</template>
					</EmbeddedDarkBox>
					<div class="battlepass-status">
						<div class="bp-bottom">
							<div
								class="bp-level-box"
								v-for="battlepassLevel in battlepassLevels"
								:key="battlepassLevel.level"
							>
								<div
									class="reward-box coin-reward"
									v-bind:class="{ 'locked': level < battlepassLevel.level }"
									v-if="battlepassLevel.rewardType === 'coins'"
								>
									<div v-if="level < battlepassLevel.level" class="locked-overlay">
										<img src="../assets/locked_icon.png" />
									</div>
									<div class="reward-box-level-title">{{battlepassLevel.level}}</div>
									<div class="reward-box-inner">
										<img src="../assets/coin.png" />
										<p class="coin-amount">{{battlepassLevel.metadata.coinAmount}}</p>
									</div>
								</div>

								<div
									class="reward-box skin-reward"
									v-bind:class="{ 'locked': level < battlepassLevel.level }"
									v-if="battlepassLevel.rewardType === 'skin'"
								>
									<div v-if="level < battlepassLevel.level" class="locked-overlay">
										<img src="../assets/locked_icon.png" />
									</div>
									<div class="reward-box-level-title">{{battlepassLevel.level}}</div>
									<div class="reward-box-inner">
										<SkinImage
											:class="{ 'weapon-skin-wrapper': battlepassLevel.metadata.skinType === 'weapon_set_preview' }"
											:key="battlepassLevel.metadata.skinIdentifier"
											:skin-data="{ skinCode: battlepassLevel.metadata.skinIdentifier }"
											:skin-image-type="battlepassLevel.metadata.skinType"
										></SkinImage>
										<p class="skin-name">{{battlepassLevel.metadata.skinFriendlyName}}</p>
									</div>
								</div>
							</div>
						</div>
						<div class="mission-list">
							<MissionList></MissionList>
						</div>
					</div>
				</div>
			</template>
		</Panel>

		<div
			v-if="!hasBattlepass"
			class="no-battlepass-overlay"
			v-bind:class="{ visible: modalVisible }"
		>
			<div class="no-battlepass-modal">
				<div class="close" v-on:click="()=>{ redirectToHome() }">
					<SkinImage
						class="close-icon"
						:skin-data="{ skinCode: 'close_icon' }"
						:skin-image-type="'sprite'"
					></SkinImage>
				</div>

				<div class="modal-title">
					<h1>{{ currentSeasonBattlepass.name }}</h1>
					<h2>
						<img src="../assets/battlepass.png">
					</h2>
				</div>

				<div class="modal-content">
					<div class="rewards-runner">
						<div class="reward-wrapper" v-for="battlepassLevel in currentSeasonBattlepass.levels" :key="battlepassLevel.level">
							<div
								class="reward-box coin-reward"
								v-if="battlepassLevel.rewardType === 'coins'"
							>
								<div class="reward-box-level-title">{{battlepassLevel.level}}</div>
								<div class="reward-box-inner">
									<img src="../assets/coin.png" />
									<p class="coin-amount">{{battlepassLevel.metadata.coinAmount}}</p>
								</div>
							</div>

							<div
								class="reward-box skin-reward"
								v-if="battlepassLevel.rewardType === 'skin'"
							>
								<div class="reward-box-level-title">{{battlepassLevel.level}}</div>
								<div class="reward-box-inner">
									<SkinImage
										:class="{ 'weapon-skin-wrapper': battlepassLevel.metadata.skinType === 'weapon_set_preview' }"
										:key="battlepassLevel.metadata.skinIdentifier"
										:skin-data="{ skinCode: battlepassLevel.metadata.skinIdentifier }"
										:skin-image-type="battlepassLevel.metadata.skinType"
									></SkinImage>
									<p class="skin-name">{{battlepassLevel.metadata.skinFriendlyName}}</p>
								</div>
							</div>
						</div>
					</div>

					<div class="blurb">
						<p class="center">{{ t('buyBattlepassSeasonBlurb1') }}</p>
						<p>
							<ul>
								<li>{{ t('buyBattlepassSeasonBlurb2') }}</li>
								<li>{{ t('buyBattlepassSeasonBlurb3') }}</li>
								<li>{{ t('buyBattlepassSeasonBlurb4') }}</li>
							</ul>
						</p>
						<div class="purchase-btn">
							<p v-if="forteSystemDown" class="forte-down-notification"> {{ t("forteDownBattlePassNotice2") }} </p>
							<Button
								v-else
								class="buy-btn"
								:color="'green'"
								:onclickfn="()=>{ showConfirmPurchaseModal() }"
							>
								<template #text>
									<span v-if="purchaseLoading" class="purchase-loading">
										<img src="../assets/loading_icon.gif"/>
									</span>
									<span v-else>
										{{ t('shopBuyNow') }}
										<img src="../assets/coin.png" /> 500
									</span>
								</template>
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { some, values } from "lodash"
import Panel from "../reusable-components/Panel"
import EmbeddedDarkBox from "../reusable-components/EmbeddedDarkBox"
import Checkbox from "../reusable-components/Checkbox"
import Button from "../reusable-components/Button"
import SkinImage from "../reusable-components/SkinImage"
import LoadyBro from "../reusable-components/LoadyBro"
import ProgressBar from "../reusable-components/ProgressBar"
import MissionList from "../reusable-components/MissionList"

export default {
	name: "LoginSignup",
	components: {
		Panel,
		EmbeddedDarkBox,
		Button,
		LoadyBro,
		SkinImage,
		ProgressBar,
		MissionList,
	},
	data: function() {
		return {
			modalVisible: true,
			confirmModalVisible: false,
			purchaseLoading: false,
			/*
			previewedSkin: {
				skinCode: 'bpseason4',
				skinPurchaseMetadata: {
					stripeSku: process.env.BPSEASON4_SKU,
					priceString: '$3.99',
				},
				redirect: "/battlepass",
			}
			*/
		}
	},
	methods: {
		...mapActions("shop", [
			"purchaseStripeSkin",
			"purchaseForteSkin"
		]),
		async purchaseBattlepass() {
			if(this.purchaseLoading) { return }

			this.purchaseLoading = true
			//await this.purchaseStripeSkin(this.previewedSkin)
			const skinCode = 'bpseason5'
			await this.purchaseForteSkin({
				skinCode,
				redirect: '/battlepass'
            })

			this.purchaseLoading = false
			window.location.href = "/battlepass"
		},
		showConfirmPurchaseModal() {
			this.confirmModalVisible = true
		},
		hideConfirmPurchaseModal() {
			this.confirmModalVisible = false
		},
		redirectToHome() {
			this.$router.push("/")
		},
	},
	computed: {
		...mapGetters("i18n", ["t"]),
		...mapGetters("user", ["username", "userDataFetching"]),
		...mapGetters("shop", ["shopFetching", "forteSystemDown"]),
		...mapGetters("battlepass", [
			"hasBattlepass",
			"currentSeasonBattlepass",
			"battlepassIsLoading",
			"battlepassName",
			"level",
			"experience",
			"totalExperienceForCurrentLevel",
			"totalExperienceForNextLevel",
			"relativeExperienceEarnedInCurrentLevel",
			"relativeExperienceForNextLevel",
			"experiencePercentTilNextLevel",
			"battlepassLevels",
			"timeRemainingString",
		]),
	}
}
</script>


<style lang="scss">
@media only screen and (max-width: 900px) {
	#battlepass {
		.battlepass-panel, .no-battlepass-overlay {
			transform: scale(0.75);
		}
	}
}

@media only screen and (max-width: 700px) {
	#battlepass {
		.battlepass-panel, .no-battlepass-overlay {
			transform: scale(0.50);
		}
	}
}
#battlepass {
	.no-battlepass-overlay {
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: 9999;
		background-color: rgba(0, 0, 0, 0.7);
		display: none;

		&.visible {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.no-battlepass-modal {
			color: white;
			height: 600px;
			width: 700px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			position: relative;
			border-image: url("../assets/bruh-dark-panel.png") round;
			border-image-slice: 14 fill;
			border-image-width: 14px;

			.close {
				position: absolute;
				top: 5px;
				right: 8px;
				opacity: 0.8;
				transform: scale(0.8);
				cursor: pointer;
				z-index: 99;

				&:hover {
					opacity: 1;
				}
			}

			.modal-title {
				width: 100%;
				height: 120px;
				text-align: center;

				img {
					height: 80px;
					vertical-align: top;
				}

				h2 {
					font-size: 20px;
				}

				h3 {
					font-size: 16px;
				}
			}

			.modal-content {
				width: 100%;
				display: flex;
				flex-direction: column;

				.rewards-runner {
					flex: 1;
					display: flex;
					min-height: 236px;
					flex-direction: row;
					overflow-x: scroll;
					overflow-y: hidden;

					&::-webkit-scrollbar {
						width: 8px;
						border-radius: 5px;
						background-color: #382f34;
					}

					&::-webkit-scrollbar-thumb {
						background-color: #9a9a9a;
					}
				}

				.blurb {
					flex: 1;
					padding: 30px;
					font-size: 120%;

					ul {
						padding-left: 30px;
					}
				}
			}


			.full-skin-image {
				&.character {
					transform: scale(5);
				}
			}

			.purchase-btn {
				position: absolute;
				bottom: 10px;
				left: 50%;
				transform: translateX(-50%);



			p.forte-down-notification {
				text-align: center;
				color: darken(red, 10%);
				font-size: 16px;
				padding: 0 15px;
				width: 500px;
				position: relative;
				bottom: 2px;
				background-color: rgba(0,0,0,0.4);
				padding: 10px;
				border-radius: 10px;
			}

				.purchase-loading img {
					height: 20px;
				}
				
				.strike-diagonal {
					position: relative;

					&:before {
						position: absolute;
						content: "";
						left: 0;
						top: 40%;
						right: 0;
						border-top: 3px solid white;

						transform: rotate(-5deg);
					}
				}
			}
		}
	}


	.battlepass-panel {
		position: relative;
		min-width: 720px;
		max-width: 1018px;
		margin: 0 40px;

		&.loading {
			width: 840px;
			height: 559px;
		}

		.loady-bro {
			position: absolute;
			top: 65%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		.bp-top {
			display: flex;
			flex-direction: row;
			padding: 15px 20px;

			.user-info,
			.bp-info {
				flex: 1;
			}

			.bp-info {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.season-title {
					font-size: 32px;
					text-transform: uppercase;
					color: #ffb425;
					white-space: nowrap;
					margin-bottom: 10px;
				}
				.time-left {
					img {
						position: relative;
						top: 2px;
						height: 15px;
						margin-left: 5px;
					}
				}
			}

			.user-info {
				display: flex;
				flex-direction: column;
				margin-right: 40px;

				.username-and-level {
					display: flex;
					flex-direction: row;
					align-items: flex-end;
					padding: 5px;

					.un {
						padding: 0;
						margin: 0;
						align-self: flex-start;
						color: #ffa800;
					}

					.level {
						margin-left: auto;
						font-style: italic;
					}
				}

				.xp-bar {
					margin: 10px 0;

					height: 30px;
					// margin: 0;
					padding: 0;
					background-color: #0e0e0e;
					.xp-bar-earned {
						padding-left: 10px;
						line-height: 25px;
						height: 100%;
						border: 2px solid #a34900;
						background-color: #e5b200;
					}
				}

				.xp {
					padding: 5px;
					font-size: 13px;
					font-style: italic;
				}
			}
		}

		.battlepass-contents {
			overflow: hidden;
			display: flex;	
			flex-direction: column;
		}

		.battlepass-status {
			display: flex;
			overflow-x: hidden;
			overflow-y: hidden;
			flex-direction: row;
			flex: 1;
		}

		.mission-list {
			margin-top: 10px;
			max-width: 340px;
			max-height: 300px;
			flex: 1;
			padding: 15px 20px;
			overflow-y: scroll;

			&::-webkit-scrollbar {
				width: 8px;
				border-radius: 5px;
				background-color: #382f34;
			}

			&::-webkit-scrollbar-thumb {
				background-color: #9a9a9a;
			}

		}

		.bp-bottom, .rewards-runner {
			display: flex;
			flex-direction: row;
			overflow-x: scroll;
			overflow-y: hidden;
			flex: 1;
			padding: 10px 0;

			&::-webkit-scrollbar {
				width: 8px;
				border-radius: 5px;
				background-color: #382f34;
			}

			&::-webkit-scrollbar-thumb {
				background-color: #9a9a9a;
			}
		}
	}

	.reward-box {
		height: 220px;
		width: 120px;
		display: flex;
		flex-direction: column;
		position: relative;

		.weapon-skin-wrapper {
			transform: scale(0.88) translateY(23px) !important;
		}

		.locked-overlay {
			z-index: 999;
			display: none;
			position: absolute;
			top: 35px;
			right: 0;
			bottom: 0;
			margin: 5px;
			left: 0;
			border: 2px solid rgba(0, 0, 0, 0.7);
			background-color: rgba(0, 0, 0, 0.7);
			justify-content: center;
			align-items: center;

			img {
				height: 50px;
			}
		}

		&.locked {
			.locked-overlay {
				display: flex;
			}
		}

		.reward-box-inner {
			position: absolute;
			top: 35px;
			right: 0;
			bottom: 0;
			left: 0;
			border: 2px solid #1c1c1c;
			background-color: #ffbe58;
			margin: 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			.skin-image-wrapper {
				pointer-events: none;
				transform: scale(3);
			}

			img {
				height: 40px;
				margin-bottom: 10px;
			}

			.skin-name {
				padding: 0;
				margin: 15px 0 0 0;
				font-size: 16px;
				position: relative;
				bottom: 12px;
				text-align: center;
			}

			.coin-amount {
				padding: 0;
				margin: 15px 0 0 0;
				font-size: 20px;
			}
		}

		.reward-box-level-title {
			background-color: #505051;
			text-align: center;
			padding: 5px;
			font-size: 22px;
		}

		&.skin-reward {
		}

		&.coin-reward {
		}
	}
	
	.shop-confirm-purchase-overlay {
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: 10999;
		background-color: rgba(0, 0, 0, 0.7);
		display: none;

		&.visible {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.loady-bro {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		.confirm-purchase-modal {
			z-index: 11000;
			color: white;
			height: 410px;
			width: 450px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			position: relative;
			border-image: url("../assets/bruh-dark-panel.png") round;
			border-image-slice: 14 fill;
			border-image-width: 14px;

			.close {
				position: absolute;
				top: 5px;
				right: 8px;
				opacity: 0.8;
				transform: scale(0.8);
				cursor: pointer;
				z-index: 99;

				&:hover {
					opacity: 1;
				}
			}

			.modal-title {
				text-align: center;

				h2 {
					font-size: 20px;
				}

				h3 {
					font-size: 16px;
				}
			}

			.purchase-btn {
				position: absolute;
				bottom: 10px;
				left: 50%;
				transform: translateX(-50%);

				.forte-down-notification {
					position: relative;
					width: 165%;
					left: -90px;
					top: 10px;
					text-align: center;
					margin-bottom: 20px;
					color: darken(red, 10%);
					font-size: 14px;
					background-color: rgba(0,0,0,0.4);
					padding: 10px;
					border-radius: 10px;
				}
			}
		}
	}

}
</style>

