import { t } from "./i18n"

const errorPageElement = document.getElementById("error-page")
const errorPageTextElement = document.getElementById("error-inner-text")
document.getElementById("error-close").addEventListener("click", () => { hideErrorScreen() })

export const showErrorScreen = (errorString) => {
	errorPageTextElement.innerHTML = errorString
	errorPageElement.style.display = "flex"
}

export const hideErrorScreen = () => {
	errorPageElement.style.display = "none"
}

export const handleError = (errorResponse, optionalOverrideErrorCode) => {
	console.error(errorResponse)
	let errorCode
	if (errorResponse.response && errorResponse.response.data && errorResponse.response.data.errorCode) {
		errorCode = errorResponse.response.data.errorCode
	} else if (optionalOverrideErrorCode) {
		errorCode = optionalOverrideErrorCode
	} else {
		errorCode = 'unknownError'
	}
	const translatedErrorCode = t(errorCode)
	showErrorScreen(translatedErrorCode)
}