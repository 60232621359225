<template>
	<button v-bind:class="{ 'button': true, [`${color}`]: true }" v-on:click="onButtonClick()">
		<div v-if="text" class="button-inner">{{text}}</div>
		<div v-else class="button-inner">
			<slot name="text"></slot>
		</div>
	</button>
</template>

<script>
export default {
	name: "Button",
	props: ["color", "onclickfn", "text"],
	methods: {
		onButtonClick() {
			this.onclickfn()
		}
	}
}
</script>

<style lang="scss">
@import "../assets/colors.scss";

.button {
	border: none;
	outline: none;
	cursor: pointer;
	border-radius: 5px;
	background-color: $gray-button-background-color;
	border-bottom: 3px solid $gray-button-bottom-shadow;
	height: 38px;

	// transition: all 0.09s ease-out;

	&:hover {
		margin-bottom: 3px;
		height: 35px;
		border-bottom: none !important;
		transform: translate(0, 3px);
		filter: drop-shadow(0 0 3px $gray-button-hover-glow);

		// this keeps the div from flickering when you hover over the top edge
		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: -5px;
			height: 10px;
			width: 100%;
		}

		.button-inner {
			border: solid 2px $gray-button-hover-glow;
		}
	}

	.button-inner {
		height: 101%;
		width: 101%;
		position: relative;
		left: -1px;
		color: white;
		font-size: 20px;
		text-transform: uppercase;
		border-radius: 5px;
		padding: 4px 10px;
		border: 2px solid $gray-button-border;
	}

	&.gray {
		background-color: $gray-button-background-color;
		border-bottom: 3px solid $gray-button-bottom-shadow;

		.button-inner {
			border: 2px solid $gray-button-border;
		}

		&:hover {
			filter: drop-shadow(0 0 3px $gray-button-hover-glow);

			.button-inner {
				border: solid 2px $gray-button-hover-glow;
			}
		}
	}

	&.green {
		background-color: $green-button-background-color;
		border-bottom: 3px solid $green-button-bottom-shadow;

		.button-inner {
			border: 2px solid $green-button-border;
		}

		&:hover {
			filter: drop-shadow(0 0 3px $green-button-hover-glow);

			.button-inner {
				border: solid 2px $green-button-hover-glow;
			}
		}
	}
}
</style>
