<template>
  <div class="embedded-dark-box">
    <slot name="dark-box-content"></slot>
  </div>
</template>

<script>
export default {
	name: "EmbeddedDarkBox"
}
</script>

<style lang="scss" scoped>
.embedded-dark-box {
	border-image: url("../assets/missing_asset.png") round;
	border-image-slice: 14 fill;
	border-image-width: 14px;
	position: relative;
	padding: 15px;

	&.lighter {
		border-image: url("../assets/bruh-dark-panel.png") round;
		border-image-slice: 14 fill;
		border-image-width: 14px;
	}
	
	&.darker {
		border-image: url("../assets/ultra-dark.png") round;
		border-image-slice: 14 fill;
		border-image-width: 14px;
		}
}
</style>
