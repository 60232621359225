<template>
  <div class="screen" id="settings">
    <Panel class="settings-panel" :title="t('settingsPanelTitle')" :onclosefn="handleMenuClose">
      <template #panel-content>
        <div
          class="settings-toggle-block"
          v-bind:class="{ loading: userDataFetching }"
        >
          <LoadyBro></LoadyBro>

          <div class="setting" v-if="!userDataFetching">
            <div class="setting-label">
                {{ t("settingsSFXVolumeLabel") }}
            </div>
            <Slider
              minValue='0'
              maxValue='200'
              setting='sfxVolume'
              :value='preferences.sfxVolume'
              @input="handleSlide"
            ></Slider>
          </div>
          <div class="setting" v-if="!userDataFetching">
            <div class="setting-text">{{ t("settingsSfxEnabledLabel") }}</div>
            <Checkbox
              :checked="preferences.sfxEnabled"
              :onclickfn="
                () => {
                  togglePreference('sfxEnabled')
                }
              "
            ></Checkbox>
          </div>
          <div class="setting" v-if="!userDataFetching">
            <div class="setting-label">
              {{ t("settingsMusicVolumeLabel") }}
            </div>
            <Slider
              minValue='0'
              maxValue='200'
              setting='musicVolume'
              :value='preferences.musicVolume'
              @input="handleSlide"
            ></Slider>
          </div>
          <div class="setting" v-if="!userDataFetching">
            <div class="setting-text">{{ t("settingsMusicLabel") }}</div>
            <Checkbox
              :checked="preferences.musicEnabled"
              :onclickfn="
                () => {
                  togglePreference('musicEnabled')
                }
              "
            ></Checkbox>
          </div>
          <div class="setting">
            <div class="setting-text">{{ t("settingsDisableHUDLabel") }}</div>
            <Checkbox
              :checked="preferences.disableHud"
              :onclickfn="
                () => {
                  togglePreference('disableHud')
                }
              "
            ></Checkbox>
          </div>
          <div class="setting">
            <div class="setting-text">{{ t("settingsVeganModeLabel") }}</div>
            <Checkbox
              :checked="preferences.veganModeEnabled"
              :onclickfn="
                () => {
                  togglePreference('veganModeEnabled')
                }
              "
            ></Checkbox>
          </div>
          <!-- <div class="setting">
            <div class="setting-text">{{ t('settingsShowOwnUsernameLabel') }}</div>
            <Checkbox :checked="preferences.showOwnUsername" :onclickfn="() => { togglePreference('showOwnUsername') }"></Checkbox>
          </div> -->
          <div class="setting">
            <div class="setting-text">
              {{ t("settingsShowOwnHealthBarLabel") }}
            </div>
            <Checkbox
              :checked="preferences.showOwnHealthBar"
              :onclickfn="
                () => {
                  togglePreference('showOwnHealthBar')
                }
              "
            ></Checkbox>
          </div>
        </div>

        <div class="button-row">
          <router-link to="/support"
            ><Button
              :color="'green'"
              :onclickfn="() => {}"
              :text="t('settingsSupportButtonLabel')"
            ></Button
          ></router-link>
        </div>

        <div class="button-row">
          <router-link to="/credits"
            ><Button
              :color="'green'"
              :onclickfn="() => {}"
              :text="t('settingsCreditsButtonLabel')"
            ></Button
          ></router-link>
        </div>

        <div class="button-row" v-if="userType === 'registered'">
          <Button
            :color="'green'"
            :onclickfn="
              () => {
                logout()
              }
            "
            :text="t('logoutButtonLabel')"
          ></Button>
        </div>
      </template>
    </Panel>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import Panel from "../reusable-components/Panel"
import Checkbox from "../reusable-components/Checkbox"
import Button from "../reusable-components/Button"
import LoadyBro from "../reusable-components/LoadyBro"
import Slider from "../reusable-components/Slider"

export default {
  name: "Settings",
  components: {
    Panel,
    Checkbox,
    Button,
    LoadyBro,
    Slider
  },
  computed: {
    ...mapGetters("i18n", ["t"]),
    ...mapGetters("user", ["userType", "preferences", "userDataFetching"]),
  },
  methods: {
    ...mapActions("user", ["changePreference", "logout", "updateMusicVolume", "updateSFXVolume"]),
    togglePreference(preference) {
      if (this.userDataFetching) return
      this.changePreference({
        preference,
        newValue: !this.preferences[preference]
      })
    },
    handleSlide(event) {
			this.queuedVolumePrefChange = true
			if(event.target.settingType === 'musicVolume') {
				this.updateMusicVolume(parseInt(event.target.value))
			}
      else if(event.target.settingType === 'sfxVolume') {
        this.updateSFXVolume(parseInt(event.target.value))
      }
    },
    handleMenuClose(event) {
      if(this.queuedVolumePrefChange) {
        let preference = 'musicVolume'
        this.changePreference({
          preference,
          newValue: this.preferences.musicVolume
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#settings {
  .settings-panel {
    max-width: 550px;
    min-width: 300px;
    flex-direction: column;
    padding-bottom: 20px;

    .settings-toggle-block {
      height: 370px;
      width: 255px;
      margin-top: 10px;

      .loady-bro {
        display: none;
      }

      &.loading {
        .loady-bro {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-5%) translateX(-40%);
        }
        
        .setting {
          display: none;
        }
      }
    }

    .setting {
      border-bottom: 2px solid rgba(57, 54, 61, 0.75);
      width: 100%;
      padding: 10px;
      position: relative;

      .setting-text {
        display: inline-block;
        margin-right: 50px;
      }
      
      .checkbox {
        position: absolute;
        right: 0;
      }

      .setting-label {
        text-align: center;
      }
    }

    .button-row {
      display: flex;
      margin-top: 10px;
      justify-content: center;
      .button {
        width: 150px;
      }
    }
  }
}
</style>
