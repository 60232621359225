import Vue from "vue"
import Router from "vue-router"
import { isUndefined, some } from "lodash"
import NotFound from "./screens/NotFound.vue"
import Profile from "./screens/Profile.vue"
import AccountRequired from "./screens/AccountRequired.vue"
import Settings from "./screens/Settings.vue"
import ChangeSkin from "./screens/ChangeSkin.vue"
import Shop from "./screens/Shop.vue"
import Credits from "./screens/Credits.vue"
import Support from "./screens/Support.vue"
import Friends from "./screens/Friends.vue"
import BuyCoins from './screens/BuyCoins.vue'
import PaymentSuccess from "./screens/PaymentSuccess.vue"
import PaymentError from "./screens/PaymentError.vue"
import Play from "./screens/Play.vue"
import LoginSignup from "./screens/LoginSignup.vue"
import PlayLanding from "./screens/PlayLanding.vue"
import PlayJoinOrCreate from "./screens/PlayJoinOrCreate.vue"
import PlayParty from "./screens/PlayParty.vue"
import Battlepass from "./screens/Battlepass.vue"
import Terms from "./screens/Terms"
import ChangeUsername from "./screens/ChangeUsername"
import ChangePassword from "./screens/ChangePassword"
import VerifyEmail from "./screens/VerifyEmail"
import Unsubscribe from "./screens/Unsubscribe"
import ForgotPassword from "./screens/ForgotPassword"
import ResetPassword from "./screens/ResetPassword"
import Rankings from "./screens/Rankings"
import VidAd from "./screens/VidAd"
import { parse } from "query-string"
import { loadFromLocalStorage } from "./utils/local-storage"

Vue.use(Router)

export const createRouter = function (store) {
	const router = new Router({
		mode: "history",
		routes: [

			// Catch-all 404 route
			{ path: "*", name: "404", component: NotFound },

			// Settings page
			{ path: "/settings", name: "settings", component: Settings },

			// Settings page
			{ path: "/terms", name: "terms", component: Terms },

			// Shop page
			{
				path: "/rankings",
				name: "rankings",
				alias: "/leaderboards",
				component: Rankings,
				beforeEnter(to, from, next) {
					store.dispatch("rankings/getCurrentSeason")
					store.dispatch("rankings/getGlobalRankings")
					next()
				}
			},

			// Forgot Password
			{
				path: "/forgot-password",
				name: "forgot-password",
				component: ForgotPassword,
				beforeEnter(to, from, next) {
					if (store.getters["user/userType"] === "registered") {
						next("/play")
					} else {
						next()
					}
				}
			}, ,

			// Buy Coins
			{
				path: "/buy-coins",
				name: "buy-coins",
				component: BuyCoins,
				beforeEnter(to, from, next) {
					if (store.getters["user/userType"] === "registered") {
						store.dispatch("shop/getXRPValueFromBruhCoinValueAndExchangeUrl")
						next()
					} else {
						next()
					}
				}
			},

			// Reset Password
			{
				path: "/reset-password",
				name: "reset-password",
				component: ResetPassword,
				beforeEnter(to, from, next) {
					if (store.getters["user/userType"] === "registered") {
						next("/play")
					} else {
						next()
					}
				}
			},

			// Verify Email
			{
				path: "/verify-email",
				name: "verify-email",
				component: VerifyEmail,
				beforeEnter(to, from, next) {
					const { emailAddress, emailVerificationToken } = parse(window.location.search)
					if (some([emailAddress, emailVerificationToken], isUndefined)) {
						next("/play")
					} else {
						store.dispatch("user/submitEmailVefication", { emailAddress, emailVerificationToken })
						next()
					}
				}
			},

			// Unsubscribe
			{
				path: "/unsubscribe",
				name: "unsubscribe",
				component: Unsubscribe,
				beforeEnter(to, from, next) {
					const { emailAddress } = parse(window.location.search)
					if (some([emailAddress], isUndefined)) {
						next("/play")
					} else {
						store.dispatch("user/submitEmailUnsubscribe", { emailAddress })
						next()
					}
				}
			},

			// Users friends page (when logged in only)
			{
				path: "/friends",
				name: "friends",
				component: Friends,
				beforeEnter(to, from, next) {
					if (store.getters["user/userType"] !== "registered") {
						next("/account-required?feature=friends")
					} else {
						store.dispatch("friends/getFriendsList")
						next()
					}
				}
			},

			// Change Username
			{
				path: "/change-username",
				name: "change-username",
				component: ChangeUsername,
				beforeEnter(to, from, next) {
					if (store.getters["user/userType"] !== "registered") {
						next("/play")
					} else {
						next()
					}
				}
			},

			// Change Password
			{
				path: "/change-password",
				name: "change-password",
				component: ChangePassword,
				beforeEnter(to, from, next) {
					if (store.getters["user/userType"] !== "registered") {
						next("/play")
					} else {
						next()
					}
				}
			},

			// Login / Signup
			{
				path: "/login-signup",
				name: "login-signup",
				component: LoginSignup,
				beforeEnter(to, from, next) {
					if (store.getters["user/userType"] === "registered") {
						next("/profile")
					} else {
						next()
					}
				}
			},

			// Big daddy play routes
			{
				path: "/",
				component: Play,
				alias: "/play",
				children: [
					{
						path: "", component: PlayLanding,
						beforeEnter(to, from, next) {
							const partyConnectionAlreadyEstablished = store.state.party.socket && store.state.party.partyId
							const partyIdFoundInLocalStorage = loadFromLocalStorage("latestPartyId")
							if (partyConnectionAlreadyEstablished) {
								next(`/play/party/${store.state.party.partyId}`)
							} else if (partyIdFoundInLocalStorage) {
								next(`/play/party/${partyIdFoundInLocalStorage}`)
							} else {
								next()
							}
						}
					},
					{
						path: "/play/join-or-create",
						component: PlayJoinOrCreate,
						beforeEnter(to, from, next) {
							const partyConnectionAlreadyEstablished = store.state.party.socket && store.state.party.partyId
							const partyIdFoundInLocalStorage = loadFromLocalStorage("latestPartyId")
							if (partyConnectionAlreadyEstablished) {
								next(`/play/party/${store.state.party.partyId}`)
							} else if (partyIdFoundInLocalStorage) {
								next(`/play/party/${partyIdFoundInLocalStorage}`)
							} else {
								next()
							}
						}
					},
					{
						path: "/play/party/*", component: PlayParty, beforeEnter(to, from, next) {
							const queryParams = parse(window.location.search)
							let { feature } = queryParams

							if (feature === "vidad") {
								store.dispatch("vidad/videoAdWatched")
							}

							let partyId = to.params.pathMatch || loadFromLocalStorage("latestPartyId")
							if (partyId === undefined || partyId === "undefined" || partyId === null) { // yes, string undefined
								partyId = store.state.party.partyId
							}

							if (partyId === undefined) {
								next("/play")
							}

							if (store.state.party.socket === undefined) {
								store.dispatch("party/checkIfPartyExists", partyId)
							}

							next()
						}
					}
				]
			},

			// Catch-all "account required" route that shows a different message depending on which feature theyre attempting to access
			{
				path: "/account-required",
				name: "account-required",
				component: AccountRequired,
				beforeEnter(to, from, next) {
					if (store.getters["user/userType"] === "registered") {
						next("/profile")
					} else {
						next()
					}
				}
			},

			// Users profile page (when logged in only)
			{
				path: "/profile",
				name: "profile",
				component: Profile,
				beforeEnter(to, from, next) {
					if (store.getters["user/userType"] !== "registered") {
						next("/account-required?feature=profile")
					} else {
						const queryParams = parse(window.location.search)
						let { feature } = queryParams

						if (feature === "vidad") {
							store.dispatch("vidad/videoAdWatched")
						}

						store.dispatch("rankings/getCurrentSeason")

						store.dispatch("battlepass/getBattlepassProgress")
						next()
					}
				}
			},

			// Users change skin page (when logged in only)
			{
				path: "/change-skin",
				name: "change-skin",
				component: ChangeSkin,
				beforeEnter(to, from, next) {
					if (store.getters["user/userType"] !== "registered") {
						next("/account-required?feature=profile")
					} else {
						store.dispatch("shop/getAvailableSkins")
						next()
					}
				}
			},

			// Shop page
			{
				path: "/shop",
				name: "shop",
				component: Shop,
				beforeEnter(to, from, next) {
					store.dispatch("shop/getAvailableSkins")
					next()
				}
			},

			// Credits page
			{
				path: "/credits",
				name: "credits",
				component: Credits
			},

			// Support page
			{
				path: "/support",
				name: "support",
				component: Support
			},

			// Payment Success
			{
				path: "/payment-success",
				name: "payment-success",
				component: PaymentSuccess
			},

			// Payment error
			{
				path: "/payment-error",
				name: "payment-error",
				component: PaymentError
			},

			// Battlepass
			// {
			// 	path: "/battlepass",
			// 	name: "battlepass",
			// 	component: Battlepass,
			// 	beforeEnter(to, from, next) {
			// 		if (store.getters["user/userType"] !== "registered") {
			// 			next("/account-required?feature=battlepass")
			// 		} else {
			// 			store.dispatch("battlepass/getCurrentSeasonBattlepass")
			// 			store.dispatch("battlepass/getBattlepassProgress")
			// 			next()
			// 		}
			// 	}
			// },

			// Video ad reward page
			// {
			// 	path: "/vidAd",
			// 	name: "vidAd",
			// 	component: VidAd,
			// 	beforeEnter(to, from, next) {
			// 		store.dispatch("vidad/getCurrentSeasonAdRewards")

			// 		// users have to login to get a reward, but we let them watch w/o being logged in
			// 		// and then ask them to login after watching
			// 		if (store.getters["user/userType"] === "registered") {
			// 			store.dispatch("vidad/getAdRewardsProgress")
			// 		}

			// 		const queryParams = parse(window.location.search)
			// 		let { earnedReward } = queryParams

			// 		if (earnedReward) {
			// 			store.dispatch("vidad/earnedReward")
			// 		}

			// 		next()
			// 	}
			// }
		]
	})

	let ga = retrieveGoogleAnalytics(window.ga)
	window.googleanalytics = getValidGoogleAnalyticsObject(ga)

	window.googleanalytics.set('page', router.currentRoute.path)
	window.googleanalytics.send('pageview')

	router.afterEach((to, from) => {
		window.googleanalytics.set('page', to.path)
		window.googleanalytics.send('pageview')
	})

	router.store = store
	store.router = router

	return router
}

function retrieveGoogleAnalytics(ga) {
	if (ga && ga.getAll) {
		ga = ga.getAll()
		if (ga && ga.length) {
			ga = ga[0]
			return ga
		}
	}
}

function getValidGoogleAnalyticsObject(ga) {
	if (ga) {
		let gaset = ga.set
		ga.set = function () {
			// console.log('GA::set', arguments)
			return gaset.apply(ga, arguments)
		}
		let gasend = ga.send
		ga.send = function () {
			// console.log('GA::send', arguments)
			return gasend.apply(ga, arguments)
		}
		return ga
	} else {
		return createGoogleAnalyticsShim()
	}
}

function createGoogleAnalyticsShim() {
	return {
		set: function () {
			// console.log('shim GA::set', arguments)
		},
		send: function () {
			// console.log('shim GA::send', arguments)
		},
	}
}
