import logger from "../utils/logger"
import { getUserBattlepassProgressRequest, getCurrentSeasonBattlepassRequest } from "../api/requests"
import { each } from "lodash"
import { handleError } from "../utils/error"

export const battlepassModule = (initialBattlepass) => {
	// logger.debug("Initializing Battlepass store module...")
	return {
		namespaced: true,
		state: {
			battlepassFetching: false,
			currentSeasonBattlepassFetching: false,
			user: {
				level: 0,
				experience: 0,
				experienceForCurrentLevel: 0,
				experienceForNextLevel: 0,
				relativeExperienceEarnedInCurrentLevel: 0,
				relativeExperienceForNextLevel: 0,
				experiencePercentTilNextLevel: 0
			},
			hasBattlepass: !!initialBattlepass,
			currentSeasonBattlepass: {
				name: "",
				description: "",
				season: 0,
				startDate: "",
				endDate: "",
				timeRemaining: {
					seconds: 0,
					minutes: 0,
					hours: 0,
					days: 27,
				},
				levels: [],
			},
			battlepass: {
				name: "",
				description: "",
				season: 0,
				startDate: "",
				endDate: "",
				timeRemaining: {
					seconds: 0,
					minutes: 0,
					hours: 0,
					days: 27,
				},
				levels: [],
			}
		},
		getters: {
			battlepassIsLoading: state => state.battlepassFetching,
			currentSeasonBattlepassIsLoading: state => state.currentSeasonBattlepassFetching,
			level: state => state.user.level,
			experience: state => state.user.experience,
			totalExperienceForCurrentLevel: state => state.user.experienceForCurrentLevel,
			totalExperienceForNextLevel: state => state.user.experienceForNextLevel,
			relativeExperienceEarnedInCurrentLevel: state => state.user.relativeExperienceEarnedInCurrentLevel,
			relativeExperienceForNextLevel: state => state.user.relativeExperienceForNextLevel,
			experiencePercentTilNextLevel: state => state.user.experiencePercentTilNextLevel,
			battlepassLevels: state => state.battlepass.levels,
			startDate: state => state.battlepass.startDate,
			endDate: state => state.battlepass.endDate,
			timeRemainingString: state => {
				const { hours, days } = state.battlepass.timeRemaining
				let str = ""
				if (days) str += `${days}d `
				if (hours) str += `${hours}h `
				return str.slice(0, -1)
			},
			battlepassName: state => state.battlepass.name,
			user: state => state.user,
			battlepass: state => state.battlepass,
			currentSeasonBattlepass: state => state.currentSeasonBattlepass,
			hasBattlepass: state => state.hasBattlepass,
		},
		mutations: {
			updateBattlepass: (state, battlepassResults) => {
				if (battlepassResults === null) {
					// console.log('user has no battlepass')
					state.hasBattlepass = false
					return
				}
				state.hasBattlepass = true
				const { user, battlepass } = battlepassResults
				const { level, experience, experienceForCurrentLevel, experienceForNextLevel, relativeExperienceEarnedInCurrentLevel, relativeExperienceForNextLevel, experiencePercentTilNextLevel } = user
				state.user = {
					level,
					experience,
					experienceForCurrentLevel,
					experienceForNextLevel,
					relativeExperienceEarnedInCurrentLevel,
					relativeExperienceForNextLevel,
					experiencePercentTilNextLevel,
				}

				const { name, description, season, startDate, endDate, timeRemaining, levels } = battlepass
				state.battlepass = {
					name,
					description,
					season,
					startDate,
					endDate,
					timeRemaining,
					levels: [...levels],
				}

				each(state.battlepass.levels, (l) => {
					if (l.rewardType === 'skin') {
						if (l.metadata.skinType === 'weapon_set') {
							l.metadata.skinType = 'weapon_set_preview'
						}
					}
				})

				// console.log('updating battlepass state', {
				// 	user: state.user,
				// 	battlepass: state.battlepass,
				// })
				state.battlepassFetching = false
			},
			updateCurrentSeasonBattlepass: (state, battlepass) => {
				const { name, description, season, startDate, endDate, timeRemaining, levels } = battlepass
				state.currentSeasonBattlepass = {
					name,
					description,
					season,
					startDate,
					endDate,
					timeRemaining,
					levels: [...levels],
				}

				each(state.currentSeasonBattlepass.levels, (l) => {
					if (l.rewardType === 'skin') {
						if (l.metadata.skinType === 'weapon_set') {
							l.metadata.skinType = 'weapon_set_preview'
						}
					}
				})
				state.currentSeasonBattlepassFetching = false
			},
			battlepassLoading: state => {
				state.battlepassFetching = true
			},
			currentSeasonBattlepassLoading: state => {
				state.currentSeasonBattlepassFetching = true
			},
		},
		actions: {
			async getBattlepassProgress({ commit, rootState }) {
				try {
					commit("battlepassLoading")
					const battlepassResults = await getUserBattlepassProgressRequest(rootState.user.authentication.token)
					commit('updateBattlepass', battlepassResults)
				} catch (e) {
					console.error("Error occurred when attempting to get BP progress", e)
					handleError(e)
				}
			},
			async getCurrentSeasonBattlepass({ commit, rootState }) {
				try {
					commit("currentSeasonBattlepassLoading")
					const battlepassResults = await getCurrentSeasonBattlepassRequest(rootState.user.authentication.token)
					commit('updateCurrentSeasonBattlepass', battlepassResults)
				} catch (e) {
					handleError(e)
				}
			},
		}
	}
}
