<template>
  <nav>
    <router-link
      to="/profile"
      class="nav-link-container animate-scale-grow profile-nav-link"
      v-bind:class="{selected: isOnRoute('account-required') || isOnRoute('profile')}"
      v-on:mouseover.native="playHoverSound($event)"
    >
      <div class="nav-link-image">
        <div class="character-grouping">
          <SkinImage
            class="nav-profile-char-skin"
            :key="selectedCharacterSkin"
            :skin-data="{ skinCode: selectedCharacterSkin }"
            :skin-image-type="'character'"
          ></SkinImage>
          <SkinImage
            class="nav-profile-wep-skin"
            :key="selectedWeaponSkin"
            :skin-data="{ skinCode: `s686_${selectedWeaponSkin}` }"
            :skin-image-type="'sprite'"
          ></SkinImage>
          <SkinImage
            class="nav-profile-hand-skin"
            :skin-data="{ skinCode: 's686_hands' }"
            :skin-image-type="'sprite'"
          ></SkinImage>
        </div>
      </div>
      <div class="nav-link-title">{{ t("navProfileButtonText") }}</div>
    </router-link>
    <router-link
      to="/shop"
      class="nav-link-container animate-scale-grow"
      v-bind:class="{selected: isOnRoute('shop')}"
      v-on:mouseover.native="playHoverSound($event)"
    >
      <div class="nav-link-image">
        <div class="profile-nav-link-image">
          <SkinImage
            :skin-data="{ skinCode: `shop_icon` }"
            :skin-image-type="'sprite'"
          ></SkinImage>
        </div>
      </div>
      <div class="nav-link-title">{{ t("navShopButtonText") }}</div>
    </router-link>
    <router-link
      to="/play"
      class="nav-link-container animate-scale-grow"
      v-bind:class="{selected: isOnRoute(undefined)}"
      v-on:mouseover.native="playHoverSound($event)"
    >
      <div class="nav-link-image">
        <SkinImage
          :skin-data="{ skinCode: `play_icon` }"
          :skin-image-type="'sprite'"
        ></SkinImage>
      </div>
      <div class="nav-link-title">{{ t("navPlayButtonText") }}</div>
    </router-link>
    <!-- <router-link to="/battlepass"
					 class="nav-link-container animate-scale-grow battlepass"
					 v-on:mouseover.native="playHoverSound($event)">
			<div class="nav-link-image">
				<img src="../assets/battlepass.png" />
			</div>
			<div class="nav-link-title">{{ t('navBattlePassButtonText') }}</div>
		</router-link> -->
    <router-link
      to="/rankings"
      class="nav-link-container animate-scale-grow"
      v-bind:class="{selected: isOnRoute('rankings')}"
      v-on:mouseover.native="playHoverSound($event)"
    >
      <div class="nav-link-image">
        <SkinImage
          :skin-data="{ skinCode: `ranking_icon` }"
          :skin-image-type="'sprite'"
        ></SkinImage>
      </div>
      <div class="nav-link-title">{{ t("navRankingsButtonText") }}</div>
    </router-link>

    <!-- <router-link to="/vidAd"
					 class="nav-link-container animate-scale-grow"
					 v-on:mouseover.native="playHoverSound($event)">
			<div class="nav-link-image">
				<img src="../assets/ad_icon.png" />
				<div class="ad-notification-icon" v-if="shouldShowAdNotification">
					<SkinImage :skin-data="{ skinCode: `star` }" :skin-image-type="'sprite'"></SkinImage>
				</div>
			</div>
			<div class="nav-link-title">{{ t('navVideoAd') }}</div>
		</router-link> -->
  </nav>
</template>

<script>
import { mapGetters } from "vuex"
import soundfile from "../assets/button-hover.webm"
import SkinImage from "../reusable-components/SkinImage"
import moment from "moment"

let activeSound = null
let audio = new Audio(soundfile)
audio.volume = 0.2
audio.playbackRate = 3

export default {
  name: "Navigation",
  components: {
    SkinImage,
  },
  data() {
    return {
      currentTime: moment(),
      shouldShowAdNotification: true,
    }
  },
  computed: {
    ...mapGetters("i18n", ["t"]),
    ...mapGetters("party", ["memberCount"]),
    ...mapGetters("user", [
      "selectedCharacterSkin",
      "selectedWeaponSkin",
      "preferences",
      "userType",
    ]),
    ...mapGetters("vidad", [
      "timeToNextAd",
      "hadRewardProgressError",
      "earnedCurrentReward",
      "hasAdReward",
      "numAdsWatched",
      "adsRequired",
    ]),
    currentRouteName: (state) => {
      return state.$route.name
    }
  },
  methods: {
    async playHoverSound(evt) {
      if (!this.preferences.sfxEnabled) {
        return
      }
      try {
        evt.stopPropagation()
        if (activeSound) {
          activeSound.pause()
        }
        activeSound = audio
        audio.volume = (this.preferences.sfxVolume / 100) * 0.2 // Original volume
        await audio.play()
      } catch (e) {
        // play() can fail if the user hasn't interacted with the page yet
        // we can't do much about that, but usually the user will be clicking around
        // https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
      }
    },
    isOnRoute(routeName) {
      return  routeName == this.$route.name
    }
  },
  watch: {
    currentTime: {
      handler(value) {
        setTimeout(() => (this.currentTime = moment()), 1000)

        if (this.userType === "anonymous") {
          this.shouldShowAdNotification = true
          return
        }

        if (this.timeToNextAd == null) {
          if (!this.hadRewardProgressError) {
            this.$store.dispatch("vidad/getAdRewardsProgress")
          }
          this.shouldShowAdNotification = false
          return
        }

        this.shouldShowAdNotification = value.isAfter(this.timeToNextAd)
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
@import "../assets/colors.scss";

nav {
  z-index: 10001;
  width: 150px;
  background-color: white;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.nav-link-container {
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: #7d8085;
  border: 2px solid #6d7075;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-decoration: none;
  color: white;
  font-size: 24px;

  &.battlepass {
    .nav-link-image {
      transform: scale(0.85);
    }

    .nav-link-title {
      position: relative;
      top: -28px;
    }
  }

  .nav-link-image {
    height: 100%;
    transform: scale(3);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    .character-grouping {
      height: 100%;
      position: relative;
      left: -5px;
      top: -2px;

      .nav-profile-char-skin {
        transform: translateX(-50%) translateY(-50%) scale(1);
        position: absolute;
        top: 50%;
        left: 40%;
        height: 37px;
        width: 24px;
      }
      .nav-profile-wep-skin {
        transform: rotate(20deg) scale(0.7);
        position: absolute;
        left: 10px;
        top: 10px;
      }
      .nav-profile-hand-skin {
        transform: rotate(20deg) scale(0.7);
        position: absolute;
        left: 10px;
        top: 10px;
      }
    }
  }

  .nav-link-title {
    position: relative;
    text-transform: uppercase;
    top: -20px;
    pointer-events: none;
    text-align: center;
  }
}

.nav-side-floater {
  height: 100%;

  position: absolute;
  left: 100%; // floating off right side of parent div
  top: 0;

  background-color: $squad-background-color;
  border-top: 4px solid $squad-border-color;
  border-right: 4px solid $squad-border-color;
  border-bottom: 4px solid $squad-border-color;
  border-radius: 0 12px 12px 0;
  box-shadow: inset $squad-background-gradient-color 0 0 0 5px;

  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .label {
    flex: 1;
    font-size: 120%;
    line-height: 120%;
    margin: 0 auto;
    transform: rotate(90deg);

    text-decoration: none;
    text-transform: uppercase;
    color: white;

    .big {
      font-size: 140%;
      line-height: 140%;

      transform: rotate(-90deg);
    }
  }
}

.ad-notification-icon {
  animation: grow-shrink 2s ease-in-out infinite;
  position: absolute;
  top: 35%;
  right: -17%;

  @keyframes grow-shrink {
    from {
      transform: scale(0.8) rotate(0deg);
    }
    50% {
      transform: scale(1.3) rotate(90deg);
    }
    to {
      transform: scale(0.8) rotate(0deg);
    }
  }
}

.animate-scale-grow {
  transition: all 0.13s ease-out;
}

.animate-scale-grow:hover {
  background-color: #ffa800;
  border: 2px solid #580b01;
  z-index: 1;
  transform: scale(1.2);
  transition: all 0.13s ease-in;
  box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.5);
}

.selected {
  background-color: #ffa800;
  border: 2px solid #580b01;
  z-index: 1;
  transform: scale(1.1);
  box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.5);
}
</style>
