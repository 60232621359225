<template>
  <div class="screen" id="friends">
    <div class="add-friend-modal-wrapper" v-if="addFriendModalVisible">
      <EmbeddedDarkBox class="add-friend-modal darker upper">
        <template #dark-box-content>
          <div class="panel-close" v-on:click="() => { toggleAddFriendModal() }">
            <SkinImage
              class="close-icon"
              :skin-data="{ skinCode: 'close_icon' }"
              :skin-image-type="'sprite'"
            ></SkinImage>
          </div>
          <h3>Search by username</h3>
          <div class="search-input">
            <input
              v-model="usernameSearchField"
              v-on:keyup.enter="() => { searchUsersByUsernameSubmit(usernameSearchField) }"
              type="text"
            />
            <Button
              :color="'green'"
              :onclickfn="() => { searchUsersByUsernameSubmit(usernameSearchField) }"
              :text="'Search'"
            ></Button>
          </div>
          <div class="search-results">
            <div class="col-headers" v-if="searchResults.length > 0">
              <div class="username-header">Username</div>
              <div class="actions-header">Actions</div>
              <div class="online-status-header">Online?</div>
            </div>

            <div class="result-rows">
              <p class="error" v-if="searchError">{{searchError}}</p>
              <p
                class="no-results"
                v-if="hasSearched && !friendsCurrentlyLoading && searchResults.length === 0"
              >There are no users that match that username (or you're already friends with them).</p>
              <div class="result-row" v-for="user in searchResults" :key="user.id">
                <div class="result-username">{{user.username }}</div>
                <div class="result-add">
                  <button v-on:click="() => { addFriendSubmit(user.id) }">Add Friend</button>
                </div>
                <div class="result-online-status">
                  <img class="online" v-if="user.online" src="../assets/active_icon.png" />
                  <img class="offline" v-if="!user.online" src="../assets/inactive_icon.png" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </EmbeddedDarkBox>
    </div>
    <Panel class="friends-panel" v-bind:class="{loading: friendsCurrentlyLoading}">
      <template #panel-content>
        <LoadyBro v-if="friendsCurrentlyLoading"></LoadyBro>
        <div v-else>
          <div class="friends-primary">
            <div class="friends-left">
              <h2>Friends</h2>

              <div class="character-image">
                <div class="character-grouping">
                  <SkinImage
                    class="char-skin"
                    :key="selectedCharacterSkin"
                    :skin-data="{ skinCode: selectedCharacterSkin }"
                    :skin-image-type="'character'"
                  ></SkinImage>
                  <SkinImage
                    class="wep-skin"
                    :key="selectedWeaponSkin"
                    :skin-data="{ skinCode: `s686_${selectedWeaponSkin}` }"
                    :skin-image-type="'sprite'"
                  ></SkinImage>
                  <SkinImage
                    class="hand-skin"
                    :skin-data="{ skinCode: 's686_hands' }"
                    :skin-image-type="'sprite'"
                  ></SkinImage>
                </div>
              </div>

              <div class="your-details">
                <div class="your-id">
                  <span>Your ID:</span>
                  {{username}}
                </div>
                <div class="number-of-friends">
                  <span>Friends:</span>
                  {{numberOfFriends}}
                </div>
              </div>
              <div class="friend-actions">
                <Button
                  :color="'green'"
                  :onclickfn="() => { toggleAddFriendModal() }"
                  :text="'Add Friends'"
                ></Button>
              </div>
            </div>
            <div class="friends-right">
              <EmbeddedDarkBox class="darker upper">
                <template #dark-box-content>
                  <div class="tabs">
                    <div
                      v-on:click="() => { setActiveFriendsTab('accepted') }"
                      class="tab"
                      v-bind:class="{ 'active': activeFriendsTab === 'accepted' }"
                    >Friends</div>
                    <div
                      v-on:click="() => { setActiveFriendsTab('pending') }"
                      class="tab second"
                      v-bind:class="{ 'active': activeFriendsTab === 'pending' }"
                    >
                      <span>Requests</span>
                      <span
                        v-if="numberOfTotalPending"
                        class="pending-requests"
                      >{{numberOfTotalPending}}</span>
                    </div>
                  </div>
                  <div class="number-online">
                    <span>Friends Online:</span>
                    {{numberOfFriendsOnline}}
                  </div>
                </template>
              </EmbeddedDarkBox>
              <EmbeddedDarkBox class="lighter lower">
                <template #dark-box-content>
                  <div
                    class="col-headers"
                    v-if="activeFriendsTab === 'accepted' && acceptedFriends.length > 0"
                  >
                    <div class="username-header">Username</div>
                    <div class="online-status-header">Online?</div>
                  </div>
                  <div class="friend-rows">
                    <div class="accepted-friends" v-if="activeFriendsTab === 'accepted'">
                      <div class="friend-row" v-for="friend in acceptedFriends" :key="friend.id">
                        <div class="friends-username">{{friend.username }}</div>
                        <div class="friends-online-status">
                          <div class="friends-remove">
                            <button
                              v-on:click="() => { cancelOutgoingFriend(friend.id) }"
                            >Remove</button>
                          </div>
                          <img class="online" v-if="friend.online" src="../assets/active_icon.png" />
                          <img
                            class="offline"
                            v-if="!friend.online"
                            src="../assets/inactive_icon.png"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="no-friends"
                      v-if="activeFriendsTab === 'accepted' && acceptedFriends.length === 0"
                    >You have no friends. Why not add some?</div>

                    <div
                      class="no-friends"
                      v-if="activeFriendsTab === 'pending' && pendingIncoming.length === 0 && pendingOutgoing.length === 0"
                    >You have no pending friend requests.</div>

                    <div
                      class="pending-incoming-friends"
                      v-if="activeFriendsTab === 'pending' && pendingIncoming.length > 0"
                    >
                      <div class="incoming-requests">
                        <p>Incoming requests...</p>
                      </div>
                      <div class="col-headers">
                        <div class="username-header">Username</div>
                        <div class="actions-header">Actions</div>
                        <div class="online-status-header">Online?</div>
                      </div>
                      <div class="friend-row" v-for="friend in pendingIncoming" :key="friend.id">
                        <div class="friends-username">{{friend.username }}</div>
                        <div class="friends-online-status">
                          <div class="friends-accept-reject">
                            <button
                              class="accept"
                              v-on:click="() => { setActiveFriendsTab('accepted'); acceptFriend(friend.id); }"
                            >Accept</button>
                            <button v-on:click="() => { rejectIncomingFriend(friend.id) }">Reject</button>
                          </div>
                          <img class="online" v-if="friend.online" src="../assets/active_icon.png" />
                          <img
                            class="offline"
                            v-if="!friend.online"
                            src="../assets/inactive_icon.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="pending-outgoing-friends"
                      v-if="activeFriendsTab === 'pending' && pendingOutgoing.length > 0"
                    >
                      <div class="outgoing-requests">
                        <p>Outgoing requests...</p>
                      </div>
                      <div class="col-headers">
                        <div class="username-header">Username</div>
                        <div class="actions-header">Actions</div>
                        <div class="online-status-header">Online?</div>
                      </div>
                      <div class="friend-row" v-for="friend in pendingOutgoing" :key="friend.id">
                        <div class="friends-username">{{ friend.username }}</div>
                        <div class="friends-online-status">
                          <div class="friends-cancel">
                            <button v-on:click="() => { cancelOutgoingFriend(friend.id) }">Cancel</button>
                          </div>
                          <img class="online" v-if="friend.online" src="../assets/active_icon.png" />
                          <img
                            class="offline"
                            v-if="!friend.online"
                            src="../assets/inactive_icon.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </EmbeddedDarkBox>
            </div>
          </div>
        </div>
      </template>
    </Panel>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import { some, values } from "lodash"
import Panel from "../reusable-components/Panel"
import EmbeddedDarkBox from "../reusable-components/EmbeddedDarkBox"
import Checkbox from "../reusable-components/Checkbox"
import Button from "../reusable-components/Button"
import LoadyBro from "../reusable-components/LoadyBro"
import SkinImage from "../reusable-components/SkinImage"

export default {
	name: "Friends",
	components: {
		Panel,
		EmbeddedDarkBox,
		Checkbox,
		Button,
		LoadyBro,
		SkinImage
	},
	data() {
		return {
			addFriendModalVisible: false,
			activeFriendsTab: "accepted",
			usernameSearchField: "",
			searchError: false,
			hasSearched: false
		}
	},
	methods: {
		...mapMutations("friends", ["clearSearchResults"]),
		...mapActions("friends", [
			"cancelOutgoingFriend",
			"rejectIncomingFriend",
			"acceptFriend",
			"addFriend",
			"searchUsersByUsername"
		]),
		setActiveFriendsTab(newTab) {
			this.activeFriendsTab = newTab
		},
		toggleAddFriendModal() {
			this.addFriendModalVisible = !this.addFriendModalVisible
		},
		addFriendSubmit(userId) {
			this.setActiveFriendsTab("pending")
			this.toggleAddFriendModal()
			this.addFriend(userId)
			this.usernameSearchField = ""
			this.hasSearched = false
		},
		searchUsersByUsernameSubmit(usernameString) {
			this.searchError = false
			if (this.friendsCurrentlyLoading) {
				return
			}
			if (usernameString.length < 3 || usernameString.length > 14) {
				this.searchError = "Search must be between 3 and 14 characters."
				this.clearSearchResults()
				this.hasSearched = false
				return
			}

			this.hasSearched = true

			this.searchUsersByUsername(usernameString)
		}
	},
	computed: {
		...mapGetters("friends", [
			"friendsCurrentlyLoading",
			"numberOfFriends",
			"acceptedFriends",
			"pendingOutgoing",
			"pendingIncoming",
			"allPending",
			"numberOfFriendsOnline",
			"searchResults",
			"numberOfTotalPending"
		]),
		...mapGetters("user", [
			"selectedWeaponSkin",
			"selectedCharacterSkin",
			"username"
		])
	}
}
</script>


<style lang="scss">
@keyframes glowing {
	0% {
		box-shadow: 0 0 -10px lighten(#488f4b, 20%);
	}
	40% {
		box-shadow: 0 0 20px lighten(#488f4b, 20%);
	}
	60% {
		box-shadow: 0 0 20px lighten(#488f4b, 20%);
	}
	100% {
		box-shadow: 0 0 -10px lighten(#488f4b, 20%);
	}
}

@media only screen and (max-width: 800px) {
	#friends {
		.friends-panel {
			transform: scale(0.75);
		}
	}
}

@media only screen and (max-width: 700px) {
	#friends {
		.friends-panel {
			transform: scale(0.50);
		}
	}
}

#friends {
	position: relative;

	.add-friend-modal-wrapper {
		z-index: 999;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.8);
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		.add-friend-modal {
			height: 300px;
			width: 350px;
			position: relative;
			display: flex;
			flex-direction: column;

			.error {
				color: red;

				padding: 20px;
			}

			.no-results {
				color: white;
				padding: 20px;
			}

			.col-headers {
				display: flex;
				align-items: flex-end;
				margin-bottom: 5px;
				margin-top: 10px;

				.username-header,
				.online-status-header,
				.actions-header {
					font-size: 12px;
					color: white;
				}

				.username-header {
					padding-left: 20px;
					flex: 3;
				}

				.online-status-header,
				.actions-header {
					flex: 1;
					text-align: right;
				}

				.online-status-header {
					padding-right: 30px;
				}
			}
			h3 {
				color: white;
				font-size: 22px;
				padding: 0 10px 5px 10px;
			}

			.search-input {
				display: flex;
				flex-direction: row;

				button {
					margin-left: 5px;
					flex: 25;
				}

				input {
					flex: 75;
					border-radius: 2px;
					background-color: darken(#262628, 30%);
					border: none;
					height: 36px;
					border: 2px solid lighten(#262628, 20%);
					font-size: 14px;
					color: #fff;
					padding: 0 5px;
				}
			}

			.search-results {
				height: 100%;
				margin-top: 5px;
				background-color: lighten(#000, 5%);

				.result-rows {
					overflow-y: auto;
					overflow-x: hidden;
					display: flex;
					flex-direction: column;
					max-height: 167px;

					&::-webkit-scrollbar {
						width: 8px;
						border-radius: 5px;
						background-color: #382f34;
					}

					&::-webkit-scrollbar-thumb {
						background-color: #9a9a9a;
					}

					.result-row {
						background-color: #505051;
						border: 2px solid rgba(0, 0, 0, 0);
						display: flex;
						flex-direction: row;
						margin: 5px 15px;
						padding: 5px;
						border-radius: 3px;

						&:hover {
							transform: scale(1.05);
							background-color: #ffa800;
							border: 2px solid #ffcb3f;
						}

						.result-username {
							white-space: nowrap;
							text-align: left;
							padding-left: 10px;
							font-size: 15px;
							flex: 3;
							color: white;
						}

						.result-add {
							flex: 1;
							display: flex;
							position: relative;
							top: 1px;
							justify-content: flex-end;
							button {
								white-space: nowrap;
								text-transform: uppercase;
								position: relative;
								top: -1px;
								padding: 0 10px;
								color: white;
								border-radius: 3px;
								border: 1px solid lighten(#498e4b, 20%);
								background-color: #498e4b;
								height: 20px;
								cursor: pointer;

								&:hover {
									opacity: 0.8;
								}
							}
						}

						.result-online-status {
							flex: 1;
							display: flex;
							justify-content: flex-end;
							img {
								position: relative;
								top: 2px;
								margin-right: 18px;
								height: 15px;
							}
						}
					}
				}
			}

			.panel-close {
				position: absolute;
				cursor: pointer;
				top: -22px;
				right: -22px;

				.close-icon {
					opacity: 1;
					transform: scale(0.8);

					&:hover {
						opacity: 0.8;
					}
				}
			}
		}
	}

	.friends-panel {
		padding: 20px 20px 20px 5px;
		position: relative;
		min-width: 645px;

		&.loading {
			width: 840px;
			height: 559px;
		}

		.loady-bro {
			position: absolute;
			top: 65%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		.friends-primary {
			height: 100%;
			width: 100%;
			display: flex;
			flex-direction: row;

			.friends-left,
			.friends-right {
				height: 300px;
			}

			.friends-left {
				width: 200px;
				text-align: center;
				position: relative;

				h2 {
					font-size: 32px;
					text-transform: uppercase;
				}

				.character-image {
					height: 100%;
					transform: scale(3) translateX(-50%);
					position: absolute;
					display: flex;
					justify-content: center;
					align-items: center;
					pointer-events: none;
					top: -33px;
					left: 53%;

					.character-grouping {
						height: 100%;
						position: relative;
						left: -5px;
						top: -2px;

						.char-skin {
							transform: translateX(-50%) translateY(-50%) scale(1);
							position: absolute;
							top: 50%;
							left: 40%;
							height: 37px;
							width: 24px;
						}
						.wep-skin {
							transform: rotate(20deg) scale(0.7);
							position: absolute;
							left: 10px;
							top: 10px;
						}
						.hand-skin {
							transform: rotate(20deg) scale(0.7);
							position: absolute;
							left: 10px;
							top: 10px;
						}
					}
				}

				.your-details {
					margin: 170px 0 10px 0;
					.your-id,
					.number-of-friends {
						margin-top: 5px;
						font-size: 13px;

						span {
							color: #d9d9d9;
						}
					}
				}

				.friend-actions {
					button {
					}
				}
			}

			.friends-right {
				width: 400px;
				display: flex;
				flex-direction: column;
				.upper {
					.tabs {
						text-align: center;
						margin-bottom: 10px;
						.tab {
							position: relative;
							text-transform: uppercase;
							min-width: 100px;
							padding: 5px 5px;
							background-color: #7a7f85;
							border: 2px solid #9b9ea5;
							display: inline-block;
							margin: 0;
							border-radius: 5px;
							cursor: pointer;

							&:hover {
								opacity: 0.8;
							}

							&.second {
								text-align: center;
								position: relative;
								left: -4px;
								padding: 5px 10px;
							}

							&.active {
								cursor: auto;
								background-color: #ee9000;
								border: 2px solid #ffa443;

								&:hover {
									opacity: 1;
								}
							}

							.pending-requests {
								position: absolute;
								font-size: 12px;
								color: white;
								right: -8px;
								top: -5px;
								background-color: red;
								padding: 2px 5px;
								border-radius: 5px;
							}
						}
					}

					.number-online {
						span {
							color: #d9d9d9;
						}
						text-align: center;
					}
				}
				.lower {
					overflow: hidden;
					flex: 1;

					.no-friends {
						margin-top: 20px;
						text-align: center;
					}

					.col-headers {
						display: flex;
						align-items: flex-end;
						margin-bottom: 5px;

						.username-header,
						.online-status-header,
						.actions-header {
							font-size: 12px;
						}

						.username-header {
							padding-left: 20px;
							flex: 3;
						}

						.online-status-header,
						.actions-header {
							flex: 1;
							text-align: right;
						}

						.online-status-header {
							padding-right: 30px;
						}
					}

					.accepted-friends {
						img {
							margin-right: 25px !important;

							&.online {
								animation: glowing 2500ms infinite;
							}
						}
					}

					.pending-outgoing-friends {
						position: relative;
						top: -5px;

						.friends-cancel {
							margin-right: 15px;
						}
					}

					.pending-incoming-friends {
						.actions-header {
							padding-right: 30px;
						}
					}

					.pending-outgoing-friends {
						.actions-header {
							padding-right: 7px;
						}
					}

					.pending-incoming-friends,
					.pending-outgoing-friends {
						.friend-row .friends-online-status img {
							margin-right: 25px !important;
						}
					}

					.incoming-requests,
					.outgoing-requests {
						text-align: center;
						font-size: 14px;
						color: #d9d9d9;
						margin: 10px;
					}

					.friend-rows {
						overflow-y: auto;
						overflow-x: hidden;
						display: flex;
						flex-direction: column;
						height: 179px;

						&::-webkit-scrollbar {
							width: 8px;
							border-radius: 5px;
							background-color: #382f34;
						}

						&::-webkit-scrollbar-thumb {
							background-color: #9a9a9a;
						}

						.friend-row {
							background-color: #505051;
							border: 2px solid rgba(0, 0, 0, 0);
							display: flex;
							flex-direction: row;
							margin: 5px 15px;
							padding: 5px;
							border-radius: 3px;

							&:hover {
								transform: scale(1.05);
								background-color: #ffa800;
								border: 2px solid #ffcb3f;
							}

							.friends-username {
								white-space: nowrap;
								text-align: left;
								padding-left: 10px;
								font-size: 15px;
								flex: 1;
							}

							.friends-online-status {
								flex: 1;
								display: flex;
								justify-content: flex-end;
								img {
									position: relative;
									top: 2px;
									margin-right: 10px;
									height: 15px;
								}

								.friends-cancel,
								.friends-accept-reject,
								.friends-remove {
									flex: 1;
									display: flex;
									position: relative;
									top: 1px;
									justify-content: flex-end;
									button {
										margin-right: 10px;
										text-transform: uppercase;
										position: relative;
										top: -1px;
										padding: 0 10px;
										color: white;
										border-radius: 3px;
										border: 1px solid lighten(#852111, 20%);
										background-color: #852111;
										height: 20px;
										cursor: pointer;

										&.accept {
											border: 1px solid lighten(#498e4b, 20%);
											background-color: #498e4b;
										}

										&:hover {
											opacity: 0.8;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
</style>

