import logger from "../utils/logger"
import { getAvailableSkinsRequest, buySkinWithCoins, getExchangeUrl, getXRPValueFromBruhCoinAmountRequest } from "../api/requests"
// eslint-disable-next-line no-undef
const stripe = Stripe(process.env.STRIPE_API_KEY)
import { handleError } from "../utils/error"

export const shopModule = (ownedSkins, forteSystemDown) => {
	// logger.debug("Initializing Shop store module with initial state:")
	// logger.debug(JSON.stringify(ownedSkins))

	return {
		namespaced: true,
		state: {
			forteSystemDown,
			shopFetching: false,
			ownedSkins,
			availableSkins: []
		},
		mutations: {
			shopActionFetching(state) {
				state.shopFetching = true
			},
			shopActionDone(state) {
				state.shopFetching = false
			},
			shopActionFailed(state) {
				state.shopFetching = false
			},
			receivedAvailableSkins(state, availableSkins) {
				state.shopFetching = false
				state.availableSkins = availableSkins
			},
			receivedUpdatedSkinOwnership(state, updatedSkinList) {
				state.ownedSkins = updatedSkinList
				state.shopFetching = false
			},
			forteDown(state) {
				state.forteSystemDown = true
			},
			gotXRPValueAndExchangeUrl(state, payload) {
				const { updatedXRPValue, urlToOpen } = payload
				state.xrpValue = updatedXRPValue
				state.exchangeUrl = urlToOpen
				state.shopFetching = false
			}
		},
		getters: {
			forteSystemDown: state => state.forteSystemDown,
			ownedSkins: state => state.ownedSkins,
			availableSkins: state => state.availableSkins,
			shopFetching: state => state.shopFetching,
			xrpValue: state => state.xrpValue,
			exchangeUrl: state => state.exchangeUrl
		},
		actions: {
			async getXRPValueFromBruhCoinValueAndExchangeUrl({ commit, state, rootState }) {
				try {
					commit("shopActionFetching")
					const authToken = rootState.user.authentication.token
					const coinAmount = rootState.user.wallet.balance * 33333
					const exchangeResults = await getExchangeUrl(authToken)
					const updatedXRPValue = await getXRPValueFromBruhCoinAmountRequest(coinAmount)
					commit("gotXRPValueAndExchangeUrl", { updatedXRPValue, urlToOpen: exchangeResults.urlToOpen })
					console.log("???")
					commit("shopActionDone")
				} catch (e) {
					commit("shopActionFailed")
					console.error("Error occurred when attempting to get available skins", e)
					handleError(e)
				}
			},
			async getAvailableSkins({ commit }) {
				try {
					commit("shopActionFetching")
					const results = await getAvailableSkinsRequest()
					results.filter(skin => {
						return skin.skinPurchaseType === 'forte' || skin.skinPurchaseType === 'stripe'
					})
					commit("receivedAvailableSkins", results)
				} catch (e) {
					commit("shopActionFailed")
					console.error("Error occurred when attempting to get available skins", e)
					handleError(e)
				}
			},
			async purchaseStripeSkin({ commit, rootState }, skin) {
				try {
					if(rootState.shop.purchaseDisabled)
						return
					window.googleanalytics.send('event', 'purchase-paid-mtx', skin.skinFriendlyName)
					commit("shopActionFetching")
					stripe
					.redirectToCheckout({
						customerEmail: rootState.user.emailAddress,
						items: [{ sku: skin.skinPurchaseMetadata.stripeSku, quantity: 1 }],
						successUrl: `${process.env.GAME_WEBSITE_URL}/payment-success`,
						cancelUrl: `${process.env.GAME_WEBSITE_URL}/payment-error`
					})
					.then(function (result) {
						console.log("result:", result)
						console.log(result.error)
					})

				} catch (e) {
					commit("shopActionFailed")
					console.error("Error occurred when attempting to purchase stripe mtx", e)
					handleError(e)
				}
			},
			async purchaseForteSkin({ commit, rootState }, params) {
				let { skinCode, redirect } = params
				try {
					window.googleanalytics.send('event', 'purchase-coin-mtx', skinCode)
					commit("shopActionFetching")
					const authToken = rootState.user.authentication.token
					const purchaseResults = await buySkinWithCoins(authToken, skinCode)
					commit("user/updatedWalletBalance", purchaseResults.balance, {
						root: true
					})
					commit("receivedUpdatedSkinOwnership", purchaseResults.items)
					// console.log(`redirecting to ${redirect}`)
					this.router.push(redirect)
				} catch (e) {
					commit("shopActionFailed")
					console.error("Error occurred when attempting to purchase mtx", e)
					handleError(e)
				}
			}
		}
	}
}
